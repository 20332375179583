import { useEffect, useState } from 'react';
import { get } from "../../utility/fetchExpense";
import { numberWithCommas } from "../../utility/general";
import ViewDetailsPopup from "../layouts/ViewDetailsPopup"
import { Pagination, Spin, Table, Tooltip } from "antd";
import parser from "html-react-parser";




const InitiateCapitalVouchers = ({ history }) => {
    const [pendingRequests, setPendingRequests] = useState([])
    const [paginationLength, setPaginationLength] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [tables, setTables] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
   

    const getCapitalVouchers = async () => {
        let res = await get("/PendingVoucherInitiationCapital")
        if (res.code === 1) {
            setPendingRequests(res.data)
        }
    }


    // const viewExpenseDocument = async (data) => {
    //     console.log(data)
    //     setExpenseDetails(data?.all)
    //     let res = await get(`/api/ExpenseApprovalDetails/selectedexpense/${data?.all?.id}`)
    //     if (res) {
    //         setExpenseDoc(res);
    //         setViewExpenseModal(true);
    //     }

    //     console.log(res);
    // }
    
    // const closeModal = () => {
    //     setViewExpenseModal(false);
    // }

    const initiateVoucher = (record) => {
        localStorage.setItem("requisitionNumber", record?.requisitionNumber)
        localStorage.setItem("approvedAmount", record?.all?.approvedAmount)
        localStorage.setItem("totalAmount", record?.all?.totalAmount)
        localStorage.setItem("expenseType", record?.expenseType)
        localStorage.setItem("expenseId", record?.all?.id.toString())
        history.push("/treasuryops/treasury_request");
    }




    const columns = [
        {
            title: 'EXPENSE INITIATOR',
            dataIndex: 'staffName',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'TITLE',
            dataIndex: 'title',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'EXPENSE TYPE',
            dataIndex: 'expenseType',
            //   width: '55%',
            editable: false,
        },
        {
            title: 'DATE OF CREATION',
            dataIndex: 'createdAt',
            // width: '55%',
            editable: false,
        },
        {
            title: 'NAME OF VENDOR/ORGANIZATION',
            dataIndex: 'requisitionVendor',
            // width: '55%',
            editable: false,
        },

        {
            title: 'APPROVED AMOUNT',
            dataIndex: 'approvedAmount',
        },
        {
            title: 'REQUESTED AMOUNT',
            dataIndex: 'totalAmount',
        },
        {
            title: 'REFERENCE NUMBER',
            dataIndex: 'requisitionNumber',
            width: '10%',

        },

        {
            title: 'DETAILS',
            dataIndex: "details"
        },
        
        // {
        //   title: 'VIEW ATTACHED DOCUMENTS',
        //   render: (text, record) => (
        //     <a onClick={() => { showDocumentModal("docs", record) }}>View Attached Documents</a>
        //   )
        // },
        // {
        //     title: 'UPLOADED DOCUMENT',
        //     render: (text, record) => (
        //         <a>View Approved Memo</a>
        //     )
        // },
        // {
        //   title: 'STATUS',
        //   render: (text, record) => (
        //     <div >
        //       <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${record.all?.voucher?.statusDetails === 'Pending' ? 'bg-amber' : record.all?.voucher?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : record.all?.voucher?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
        //       <div className='float-right'>{record.all?.voucher?.statusDetails === 'Pending' ? `Pending With ${getDeptName(record.all?.voucher?.pendingOffice)}` : record.all?.voucher?.statusDetails === 'ApprovalCompleted' ? 'Paid' : record.all?.voucher?.statusDetails === 'Rejected' ? 'Declined' : record.all?.voucher?.statusDetails}</div>
        //     </div>)
        // },
        // {
        //   title: 'APPROVALS AND COMMENTS',
        //   render: (text, record) => (
        //     <a onClick={() => { showDocumentModal("comment", record.all) }}>View Approvals and Comments</a>
        //   )
        // },
        {
            title: 'ACTION',
            render: (text, record) => (
                <div>
                    {
                        <div>
                            <button onClick={() => initiateVoucher(record)} className="btn"> Initiate </button>
                        </div>}
                </div>
            )
        },
    ]


    const paginationChangeHandler = (current, pageSize) => {
        setPageNumber(current);
        setPageSize(pageSize);
    };

    //   useEffect(() => {

    //   }, [pageSize, pageNumber, searchParam])


    useEffect(() => {
        setTables(
            pendingRequests?.map((entry) => (
                {
                    key: entry?.voucher?.id,
                    title: entry?.title,
                    staffName: entry?.staffName,
                    createdAt: new Date(entry?.createdAt).toUTCString(),
                    mda: entry?.voucher?.mda,
                    expenseType: entry?.expenseType,
                    requisitionVendor: entry?.requisitionVendor,
                    requisitionNumber: entry?.referenceNumber,
                    approvedAmount: `₦${numberWithCommas(entry?.approvedAmount)}`,
                    totalAmount: `₦${numberWithCommas(entry?.totalAmount)}`,
                    details: parser(entry?.details),

                    //   amount: `₦${numberWithCommas(Number(parseFloat(entry?.voucher?.amount) + entry?.voucher?.subVouchers?.reduce(
                    //     (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                    //   )).toFixed(2))}`,
                    all: entry
                }
            ))
        )

    }, [pendingRequests])

    useEffect(() => {
        getCapitalVouchers();
        localStorage.removeItem("requisitionNumber")
        localStorage.removeItem("approvedAmount")
        localStorage.removeItem("totalAmount")
        localStorage.removeItem("expenseType")
    }, [])

    return (
        <div>
            <h2>Capital Vouchers</h2>

            <div className="p-10 m-t-40">
                {<Table
                    className='w-100 m-t-20'
                    bordered
                    dataSource={tables && tables}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    scroll={{ x: 400 }}
                    loading={{
                        indicator: <div><Spin /></div>, spinning: loading
                    }}
                />}
                {paginationLength > 0 && (
                    <div style={{ marginTop: "20px" }}>
                        <Pagination
                            showSizeChanger
                            pageSize={pageSize}
                            onShowSizeChange={paginationChangeHandler}
                            current={pageNumber}
                            total={paginationLength}
                            // showTotal={true}
                            onChange={paginationChangeHandler}
                            // pageSizeOptions={pageSizeOptions}
                            responsive
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default InitiateCapitalVouchers;