import React, { useEffect, useMemo, useState } from 'react';
import { Button, Badge } from 'antd';
import notification from '../../../utility/notification';
import { Link } from 'react-router-dom';
import { get, patch } from '../../../utility/fetch';
import useMdaData from '../../../utility/useMda';
import { exportTableData } from '../../../utility/exportToExcel';
import useFilteredData from '../../../hooks/useFilteredData';
import useWorkflowData from '../../../hooks/useWorkflowData';
import SelectInput from '../../inputs/SelectInput';
import SearchInput from '../../inputs/SearchInput';
import moment from 'moment';
import { Pagination } from 'antd';
import useSequenceData from '../../../hooks/useSequenceData';
import TreasuryRequestDetails from '../TreasuryRequestDetails';

let onboardedStaffMemo;

const SEQUENCE_NUMBER = 4
const FilterBadge = ({ isActive, badgeCount, text, onClick }) => {
  return (
    <div className={`m-l-10 pointer  ${isActive ? 'filter_badge_active': 'filter_badge_inactive'}`}>
      <Badge color="red" count={badgeCount}>
        <div
          onClick={() => onClick()}
          className={`flex-h-center filter_badge_text flex-v-center`}
        >
          {text}
        </div>
      </Badge>
    </div>
  );
};

const tableHeaders = [
  'Request Type',
  'Request Initiator',
  'Request Agency/MDA',
  'Request Details',
  'Last Updated',
  'Status',
  'Action'
];

const statusIndexes = {
  1: 'Draft',
  2: 'Pending',
  3: 'Approved',
  4: 'Rejected'
};

let TEMP_FILTERED_DATA = [];

const ManageRequest = ({ history }) => {
  const [title, setTitle] = useState('');
  const [activeFilter, setActiveFilter] = useState('Pending');
  let mdas = useMdaData();
  mdas = useFilteredData(mdas, 'name');
  const [limit, setLimit] = useState(20);
  const [current, setCurrent] = useState(1);
  const [reviewerComment, setReviewerComment] = useState("")
  const [managementRequestData, setManagementRequest] = useState([]);
  const sequenceData = useSequenceData();
  const workflowData = useWorkflowData();
  const [managementRequestDataFixed, setManagementFixedRequest] = useState([]);
  const [showRequestDetailsModal, setShowRequestDetailsModal] = useState(false);
  const [id, setId] = useState();

  const paginationChangeHandler = (current, pageSize) => {
    setLimit(pageSize);
    setCurrent(current);
  };

  const toggleRequestDetailsModal = () => {
    setShowRequestDetailsModal(!showRequestDetailsModal);
  };

  useEffect(() => {
    const statusIndex = Object.entries(statusIndexes).find(
      ([key, value]) => value === activeFilter
    )?.[0];
    const filteredRequest = managementRequestDataFixed.filter(
      (req) => req.treasuryRequestStatus === parseInt(statusIndex, 10)
    );
    TEMP_FILTERED_DATA = filteredRequest;
    setManagementRequest(filteredRequest);
  }, [managementRequestDataFixed, activeFilter]);

  const fetchRequestData = async () => {
    try {
      const { status, data } = await get(
        '/GetTreasuryRequestFiltered',
        {
          PageSize: limit,
          PageNumber: current,
          TreasurySequenceStateId: SEQUENCE_NUMBER
        },
        true
      );
      if (status === 'success') {
        const reqData = data.map((res) => {
          const { vwTreasuryRequestWorkFlow } = res;
          const currentWorkflow = vwTreasuryRequestWorkFlow.find(
            ({ sequenceNumber }) => sequenceNumber === SEQUENCE_NUMBER
          );
          let { requestStatus, treasuryRequestWorkflowStatus } =
            currentWorkflow;
          requestStatus = requestStatus?.toLowerCase();
          const updatedResponse = {
            ...res,
            requestStatus: requestStatus || 'pending',
            treasuryRequestStatus: treasuryRequestWorkflowStatus || 2,
            isUnAssigned: requestStatus === 'unassigned',
            isPending: requestStatus === 'pending' || !requestStatus,
            isApprovalCompleted: requestStatus === 'approvalcompleted',
            isRejected: requestStatus === 'rejected'
          };
          return updatedResponse;
        });
        setManagementFixedRequest(reqData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateWorkFlowData = async (requestId, statusId) => {
    // const sequence = sequenceData.find((d) => d.sequenceNo === 2);
    const { data } = await get(
      `/GetTreasuryRequestWorkflow/Details/${requestId}`
    );
    const workflow = data.vwTreasuryRequestWorkFlow.find(
      (x) => x.sequenceNumber === SEQUENCE_NUMBER
    );
    try {
      const data = await patch(
        '/UpdateTreasuryRequstWorkflowStatus',
        {
          treasuryRequestWorkflowId: workflow?.id,
          comment: reviewerComment,
          treasuryRequestWorkflowStatus: statusId
        },
        true
      );

      if (data.status !== "success") {
        notification({
          type: 'error',
          message: "You can't action on this request"
        });
      } else {
        notification({
          type: 'success',
          message: "Workflow Status Updated"
        });
        fetchRequestData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateRequestData = async (requestId, statusId) => {
    try {
      updateWorkFlowData(requestId, statusId);

      // const { status, data } = await patch(
      //   '/UpdateTreasuryRequstStatus',
      //   {
      //     treasuryRequestId: requestId,
      //     treasuryRequestStatus: statusId,
      //     statusChangeLog: 'string'
      //   },
      //   true
      // );
      // if (status === 'success') {
      //   fetchRequestData();
      //   if (statusId === 3) {
      //   }
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Manage Request';
    setTitle('Manage Request');
  };
  useEffect(() => {
    performPageMetaActions();
  }, []);

  useEffect(() => {
    if (sequenceData?.length) {
      fetchRequestData();
    }
  }, [current, limit, sequenceData]);

  const onInputChange = async (value) => {
    try {
      const statusIndex = Object.entries(statusIndexes).find(
        ([key, value]) => value === activeFilter
      )?.[0];
      if (value === 'All') {
        const mdaFilteredData = managementRequestDataFixed.filter(
          (d) => d.treasuryRequestStatus === parseInt(statusIndex, 10)
        );
        return setManagementRequest(mdaFilteredData);
      }
      const mda = mdas.filter((m) => {
        return m.id === value;
      })[0];

      const mdaFilteredData = managementRequestDataFixed.filter(
        (d) =>
          d.mdaId === mda.id &&
          d.treasuryRequestStatus === parseInt(statusIndex, 10)
      );
      setManagementRequest(mdaFilteredData);
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = (e) => {
    const { value } = e.target;
    const currData = [...TEMP_FILTERED_DATA];
    if (value === '') {
      console.log('in here');
      setManagementRequest(currData);
      return;
    }
    const result = currData.filter((item) =>
      Object.values(item).some((itemValue) =>
        itemValue?.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setManagementRequest(result);
  };

  const filterList = useMemo(() => {
    return [
      {
        text: 'Pending',
        badgeCount: managementRequestDataFixed.filter(
          (d) => d.treasuryRequestStatus === 2
        ).length,
        isActive: activeFilter === 'Pending',
        onClick: () => setActiveFilter('Pending')
      },
      {
        text: 'Approved',
        badgeCount: managementRequestDataFixed.filter(
          (d) => d.treasuryRequestStatus === 3
        ).length,
        isActive: activeFilter === 'Approved',
        onClick: () => setActiveFilter('Approved')
      },
      {
        text: 'Suspended',
        badgeCount: managementRequestDataFixed.filter(
          (d) => d.treasuryRequestStatus === 1
        ).length,
        isActive: activeFilter === 'Draft',
        onClick: () => setActiveFilter('Draft')
      }
    ];
  }, [activeFilter,managementRequestDataFixed]);

  const resolveStatus = (pending, approved, rejected) => {
    if (approved) return 'status_active';
    if (rejected) return 'status_rejected';
    if (pending) return 'status_pending';
    else return 'status_rejected';
  };

  const resolveStatusName = (pending, approved, rejected) => {
    if (approved) return 'Approved';
    if (rejected) return 'Rejected';
    if (pending) return 'Pending';
    else return 'Suspended';
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">{title}</div>
      <div className="w-100 m-t-10 flex  flex-v-center flex-h-end">
        <SelectInput
          onChange={onInputChange}
          placeholder="Filter Items By"
          options={['All', ...mdas]}
        />
        <SearchInput
          className="m-l-10 h-35"
          onChange={onSearch}
          placeholder="Search transaction, invoice"
        />
      </div>
      <div className="w-100 m-t-20 p-x-10 p-y-10 bg_green  space-between flex   flex-v-center flex-h-end">
        <div className="bold-text">Request Log</div>
        <div className=" flex   flex-v-center flex-h-end">
          {filterList.map((filter) => (
            <FilterBadge {...filter} />
          ))}
        </div>
      </div>
      <div className="table-view">
        <table className="table-view">
          <thead className="">
            <tr className="tr-bordered">
              {tableHeaders.map((th, index) => (
                <th key={index} className="th th-bordered">
                  <p>{th}</p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-body">
            {managementRequestData.map((item) => (
              <tr role="presentation">
                <td className="td p-r td-bordered">
                  <Link
                    onClick={() => {
                      setId(item.id);
                      toggleRequestDetailsModal();
                    }}
                    to={`#`}
                  >
                    <span style={{ cursor: 'pointer' }} className="underline">
                      {item.requestTitle}
                    </span>
                  </Link>
                </td>
                {/* <td className="td p-r td-bordered">{item.requestTitle}</td> */}
                <td className="td p-r td-bordered">{item.requestInitiator}</td>
                <td className="td p-r td-bordered">{item.mda}</td>
                <td className="td p-r td-bordered">{item.requestDetails}</td>
                <td className="td p-r td-bordered">
                  {item.createdDate &&
                    moment(item.createdDate).format('MMM Do YY')}
                </td>
                <td className="td p-r td-bordered">
                  <div className="flex flex-v-center">
                    <div
                      className={`status_badge ${resolveStatus(
                        item.isPending,
                        item.isApprovalCompleted,
                        item.isRejected
                      )}`}
                    ></div>{' '}
                    {resolveStatusName(
                      item.isPending,
                      item.isApprovalCompleted,
                      item.isRejected
                    )}
                  </div>
                </td>
                <td className="td p-r td-bordered">
                  <SelectInput
                    placeholder="Select Status"
                    onSearch={false}
                    onChange={(value) => updateRequestData(item.id, value)}
                    options={[
                      { name: 'Draft', id: 1 },
                      { name: 'Suspend', id: 1 },
                      { name: 'Pending', id: 2 },
                      { name: 'Approved', id: 3 },
                      { name: 'Declined', id: 4 }
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Pagination
          showSizeChanger
          pageSize={limit}
          onShowSizeChange={paginationChangeHandler}
          current={current}
          total={50}
          // showTotal={true}
          onChange={paginationChangeHandler}
          // pageSizeOptions={pageSizeOptions}
          responsive
        />
      </div>
      {showRequestDetailsModal && (
        <TreasuryRequestDetails
        sequenceNumber={SEQUENCE_NUMBER}
        updateReviewerComment={value => setReviewerComment(value)}
          closeModal={toggleRequestDetailsModal}
          id={id}
        />
      )}
    </div>
  );
};

export default ManageRequest;
