import { useEffect, useState } from "react";
import { get } from "../../utility/fetch";
import { Pagination } from "antd";
import { toBePartiallyChecked } from "@testing-library/jest-dom/matchers";
import { numberWithCommas } from "../../utility/general";
import { IoMdClose } from "react-icons/io";



const VoteBook = ({ economicCodes, closeModal, overheadEconomicCodes, capitalEconomicCodes }) => {

    const [voteBookData, setVoteBookData] = useState([])
    const [VBpageNumber, setVBPageNumber] = useState(1);
    const [VBpageSize, setVBPageSize] = useState(1);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(100)
    const [uploadProgress, setUploadProgress] = useState();


    let economicCodeData = overheadEconomicCodes?.find((item) => {
        return item?.economicCode.includes(economicCodes[VBpageNumber - 1])
    })

 


    const paginationChangeHandler = (current, pageSize) => {
        setVBPageNumber(current);
        setVBPageSize(pageSize);
        getPageCount(current, pageSize, current);
    };




    const getPageCount = async (pageNumber, pageSize, vbPage) => {
        let res = await get(`/treasury/paginated/economicCode?PageNumber=${pageNumber}&PageSize=${pageSize}&EconomicCode=${economicCodes[vbPage - 1]}`);
        if (res.status === "success") {
            getPageData(res?.data?.totalItemCount, vbPage);
        }
    }



    const getEconomicCodeData = async (pageNumber, pageSize, vbPage) => {
        let res = await get(`/treasury/paginated/economicCode?PageNumber=${pageNumber}&PageSize=${pageSize}&EconomicCode=${economicCodes[vbPage - 1]}`);
        if (res.status === "success") {
            setCount(res?.totalItemCount)
            return res?.data?.items
        }
        return;
    }

    // let getData = async (pageNumber, pageSize, value) => {
    //     let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${value}`)
    //     if (res.status === "success") {
    //         return res?.data?.items
    //     }
    //     return;
    // }

    const getPageData = async (pageCount, vbPage) => {
        setLoading(true)
        let requests = [];
        let pageNumber = 1;
        let pageSize = 30;
        let tracker = pageCount;
        // let total = overheadEconomicCodes?.find((item)=>{
        //     return item?.economicCode === economicCodes[VBpageNumber - 1]
        // })?.proposedBudget;

        let total = 5000000;


        while (tracker > 0) {
            let entry = await getEconomicCodeData(pageNumber, pageSize, vbPage);
            let currentEntry = entry?.map((item) => {
                let newVoucher = { ...item?.voucher, computedTotal: total }
                let newEntry = {
                    ...item, voucher: newVoucher
                }
                total = total - item?.voucher?.amount
                return newEntry;
            })
            requests.push(currentEntry);
            tracker = tracker - pageSize;
            pageNumber++;
            setUploadProgress(((pageSize / tracker) * 100).toFixed(2))
        }

        Promise.all(requests)
            .then((res) => {
                setVoteBookData(res.flat()?.filter((item) => item?.voucher?.statusDetails === "ApprovalCompleted"))
                setLoading(false)
            })

    }



    useEffect(() => {
        getPageCount(1, 10, 1);
    }, [])

    return (
        <div
            className="overlay"
            style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "40px",
                boxShadow: "0px 2px 6px #0000000A",
            }}
            
        >
            <div>
                <IoMdClose className="close-btn pointer" onClick={closeModal} />
                <div  className="modal-box w-80 pd-10 flex flex-h-center form-bg-shadow">
                    <div  className="w-100">
                        <div className="white-bg bg-white ">
                            <div className="flex space-between p-r-20 p-l-20">
                                <p className=" flex flex-basis-30"><p className="no-wrap">HEAD No </p><div style={{ width: "200px" }} className="dotted-bottom m-l-10 center-text">{voteBookData[0]?.voucher?.head}</div></p>
                                <p className="flex flex-basis-30"><p>Sub-head No</p> <div style={{ width: "200px" }}  className="dotted-bottom m-l-10 center-text">{economicCodes[VBpageNumber - 1]}</div></p>
                                <h3 className=""> <div>TREASURY BOOK  46 (R)</div></h3>
                            </div>
                            <div className="flex p-r-20 p-l-20 m-t-10">
                                <p className="flex flex-basis-30">
                                    <p>Service</p> <div style={{ width: "200px" }}  className="dotted-bottom m-l-10 center-text"></div>
                                </p>
                            </div>
                            <div className="right-text p-r-20 p-l-20 ">
                                <h2 className="m-r-40">EXPENDITURE</h2>
                            </div>

                            <hr />

                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th>
                                            <p>Line No.</p>
                                            <p>(1)</p>
                                        </th>
                                        <th>
                                            <p>Date</p>
                                            <p>(2)</p>
                                        </th>
                                        <th>
                                            <p>Voucher No.</p>
                                            <p>(3)</p>
                                        </th>
                                        <th>
                                            <p>Particulars</p>
                                            <p>(4)</p>
                                        </th>
                                        <th>
                                            <p>Payments</p>
                                            <p>(5)</p>
                                        </th>
                                        <th>
                                            <p>Total</p>
                                            <p>(6)</p>
                                        </th>
                                        <th>
                                            <p>Balance</p>
                                            <p>(7)</p>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {voteBookData?.map((item, idx) => (
                                        <tr key={item?.voucher?.id}>
                                            <td className="p-10">{idx + 1}</td>
                                            <td className="p-10">{new Date(item?.voucher?.dateCreated).toUTCString()}</td>
                                            <td className="p-10">{item?.voucher?.pvNo}</td>
                                            <td className="p-10">{item?.voucher?.purpose}</td>
                                            <td className="p-10">{numberWithCommas(item?.voucher?.amount.toFixed(2))}</td>
                                            <td className="p-10">{numberWithCommas(item?.voucher?.computedTotal.toFixed(2))}</td>
                                            <td className="p-10">{numberWithCommas((item?.voucher?.computedTotal - item?.voucher?.amount).toFixed(2))}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div style={{ marginTop: '20px' }}>
                            <Pagination
                                showSizeChanger
                                pageSize={VBpageSize}
                                // onShowSizeChange={paginationChangeHandler}
                                current={VBpageNumber}
                                total={economicCodes?.length}
                                // showTotal={true}
                                onChange={paginationChangeHandler}
                                // pageSizeOptions={pageSizeOptions}
                                responsive
                            />
                        </div>

                    </div>
                </div>


            </div>
        </div>
    )
}

export default VoteBook;