import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
import { get, patch } from '../../utility/fetch';
import useMdaData from '../../utility/useMda';
import useFilteredData from '../../hooks/useFilteredData';
import SelectInput2 from '../inputs/SelectInput2';
import FilterBox from '../layouts/FilterBox';
import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
import notification from '../../utility/notification';
import Loader from '../../assets/svg/loading.svg';
import BudgetInput from '../inputs/BudgetInput';
import { BsTrash } from 'react-icons/bs';
import { GoPlus } from 'react-icons/go';
import { BiMinus } from 'react-icons/bi';
import { uploadDocument } from '../../utility/otherApis';
import PictureModal from '../layouts/PictureModal';

const itemTableHeaders = ['Item Name', 'Description', 'Amount', 'Quantity'];

const supportingDocumentTableHeaders = ['Item Name', 'Description'];
const commentsTableHeaders = [
  'Assigned Officer',
  'In-Tray',
  'Out-Tray',
  'Comment'
];

const previousTransactionsTableHeaders = [
  // 'Payment ID#',
  'Amount',
  'Request Initiator',
  'Approval Date',
  'Payment Date'
];

let onboardedStaffMemo;

const TreasuryRequestDetails = ({
  closeModal,
  id,
  updateReviewerComment,
  sequenceNumber
}) => {
  const { goBack } = useHistory();
  const { id: reqId } = useParams();
  if (!id) id = reqId;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  if (!sequenceNumber) {
    const param = queryParams.get('sequence_number');
    sequenceNumber = typeof param === 'number' ? param : parseInt(param);
  }

  console.log(sequenceNumber, typeof sequenceNumber);
  const [title, setTitle] = useState('');
  let mdas = useMdaData();
  const [onBoardingStaffData, setonBoardingStaffData] = useState([]);

  const [showPictureModal, setShowPictureModal] = React.useState(false);
  const [mdaId, setMdaId] = useState('');
  const [categoryData, setCategoryData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [requestData, setRequestData] = useState({
    requestTitle: '',
    requestDetails: '',
    treasuryCategorySetupId: ''
  });
  const [itemData, setItemData] = useState({
    name: '',
    description: '',
    amount: '',
    quantity: ''
  });
  const [itemDataError, setItemDataError] = useState('');
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [documentData, setDocumentData] = useState({
    name: '',
    description: '',
    files: []
  });
  const [documentDataError, setDocumentDataError] = useState('');
  const [documents, setDocuments] = useState([]);
  const [url, setUrl] = useState(0);
  const [limit, setLimit] = useState(20);
  const [current, setCurrent] = useState(1);
  const [previousTransactions, setPrevioustRansaction] = useState([]);
  const [requestId, setRequestId] = useState();
  const [newRequest, setNewRequest] = useState('Yes');
  const [treasuryRequest, setTreasuryRequest] = useState({});
  const [comment, setComment] = useState('');
  const [commentHistory, setCommentHistory] = useState([]);

  const updateWorkFlowData = async () => {
    // const sequence = sequenceData.find((d) => d.sequenceNo === 2);
    if (comment != '') {
      setIsSubmitting(true);
      const user = JSON.parse(localStorage.getItem('USER_INFO'));
      const { data } = await get(`/GetTreasuryRequestWorkflow/Details/${id}`);
      const workflow = data.vwTreasuryRequestWorkFlow.find(
        (x) => x.sequenceNumber === sequenceNumber
      );
      try {
        const { status, data, code, message } = await patch(
          '/UpdateTreasuryRequstWorkflowStatus',
          {
            treasuryRequestWorkflowId: workflow?.id,
            comment: comment,
            treasuryRequestWorkflowStatus:
              workflow.treasuryRequestWorkflowStatus
          },
          true
        );
        if (code === 1) {
          notification({
            title: `Successful Request Creation`,
            message: 'Request Successfully Updated',
            type: 'success'
          });
        } else {
          notification({
            title: status,
            message: message,
            type: 'warning'
          });
        }

        closeModal ? closeModal() : goBack();
      } catch (error) {}
      setIsSubmitting(false);
    }
  };

  const getTreasuryRequest = async () => {
    try {
      const { status, data } = await get(
        '/GetTreasuryRequestFiltered',
        {
          PageSize: limit,
          PageNumber: current,
          TreasuryRequestId: id
        },
        true
      );
      if (status === 'success') {
        if (data.length > 0) setRequestData(data[0]);
      }
    } catch (error) {}
  };

  const getPaymentItems = async () => {
    try {
      const { status, data } = await get(
        '/GetTreasuryPaymentFiltered',
        {
          PageSize: limit,
          PageNumber: current,
          TreasuryRequestId: id
        },
        true
      );
      if (status === 'success') {
        setItems(data?.items);
        let totalItemVal = 0;
        data?.items.forEach((i) => {
          const val = i.amount * i.quantity;
          totalItemVal = totalItemVal + val;
        });
        setTotal(totalItemVal);
        // setonBoardingStaffData(data);
      }
    } catch (error) {}
  };

  const getWorkflow = async () => {
    try {
      const { status, data } = await get(
        `/GetTreasuryRequestWorkflow/Details/${id}`
      );
      if (status === 'success') {
        setRequestData((prev) => ({
          ...prev,
          requestDetails: data.details,
          requestTitle: data.title,
          treasuryCategorySetupId: data.treasuryCategorySetupId
        }));
        setPrevioustRansaction(data.paymentResponse);
        setDocuments(data.uploadResponse);
        setCommentHistory(data.vwTreasuryRequestWorkFlow);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getTreasuryRequest();
    getWorkflow();
    getPaymentItems();
  }, [id]);

  const openPicture = (link) => {
    setUrl(link);
    setShowPictureModal(true);
  };

  const handleFile = (url) => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const fileExtension = url.substr(url.lastIndexOf('.')).toLowerCase();
    if (imageExtensions.includes(fileExtension)) {
     return openPicture(url);
    }

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const contentType = blob.type;
        if (!contentType.startsWith('image/')) {
          const fileName = url.substring(url.lastIndexOf('/') + 1);
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = fileName;
          downloadLink.style.display = 'none';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          openPicture(url);
        }
      })
      .catch((error) => {
        console.log(error);
        window.open(url, '_blank');
        console.error('Error downloading file:', error);
      });
  };

  const closePictureModal = () => {
    setShowPictureModal(false);
  };

  const onSearch = async (value) => {
    try {
      setRequestId(value);
      const { status, data } = await get(
        `/GetTreasuryPaymentFiltered`,
        { TreasuryRequestId: value },
        true
      );
      if (status === 'success') {
        setPrevioustRansaction(data?.items);
      }
    } catch (error) {}
  };

  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Treasury Request';
    setTitle('Treasury Request');
  };
  useEffect(() => {
    performPageMetaActions();
    // fetchSequenceCategory();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequestData((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeNewRequest = ({ target: { value } }) => {
    setNewRequest(value);
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal || goBack} />
      <div style={{ width: '80%', margin: '0 auto' }} className="flex">
        <div className="flex flex-direction-column col-3 no-padding">
          <div
            className="m-t-10 p-t-10"
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              backgroundColor: 'white'
              // opacity: detail.status === 3 ? '0.5' : '1'
            }}
          >
            <SelectInput2
              className="w-100 m-b-10"
              value=""
              label="Expense Type"
              options={[]}
              onChange={(e) => setMdaId(e.target.value)}
            />

            <SelectInput2
              className="w-100 m-b-10"
              value=""
              label="Budget Item"
              options={[]}
            />

            <FilterBox
              className={'filter_box_border_b'}
              title={'New Request'}
              type="radio"
              value={newRequest}
              onChange={onChangeNewRequest}
              options={['Yes', 'No']}
              placeholder="enter search text"
            />

            <FilterBox
              className={'filter_box_border_b'}
              title={'Request #ID'}
              type="search"
              onSearch={onSearch}
              disabled={newRequest == 'Yes'}
            />
          </div>

          <div
            className="m-t-10 p-t-10"
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              backgroundColor: 'white'
              // opacity: detail.status === 3 ? '0.5' : '1'
            }}
          >
            <BudgetInput
              className="w-100 m-b-10"
              value="N347,080,123.89"
              label="Budget Item Allocation (Annual)"
            />

            <BudgetInput
              className="w-100 m-b-10"
              value="N247,080,123.89"
              label="Balance Budget Item Allocation (Annual)"
            />
          </div>

          <div
            className="m-t-10 p-t-10"
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              backgroundColor: 'white'
              // opacity: detail.status === 3 ? '0.5' : '1'
            }}
          >
            <BudgetInput
              className="w-100 m-b-10"
              value="N347,080,123.89"
              label="Budget Item Allocation For Month"
            />

            <BudgetInput
              className="w-100 m-b-10"
              value="N247,080,123.89"
              label="Balance Budget Item Allocation For Month"
            />
          </div>
        </div>
        <div className="col-9 pd-r-0">
          <div
            style={{
              boxShadow: '0px 2px 6px #0000000A'
              // backgroundColor: 'white'
            }}
          >
            <div>
              <div
                onClick={() => setShowDetails(!showDetails)}
                className="w-100 m-t-20  p-x-10 p-y-10 bg_green  space-between flex   flex-v-center flex-h-end pointer"
              >
                <div className="bold-text">
                  Previous Transactions on this Request
                </div>
                <div className=" flex   flex-v-center flex-h-end">
                  {showDetails ? (
                    <BiMinus style={{ fontSize: '25px', color: '#1c811c' }} />
                  ) : (
                    <GoPlus style={{ fontSize: '25px', color: '#1c811c;' }} />
                  )}
                </div>
              </div>
              {showDetails && (
                <div
                  style={{
                    boxShadow: '0px 2px 6px #0000000A',
                    backgroundColor: 'white'
                    // padding: '20px'
                  }}
                >
                  <div className="w-100  p-x-10  bg_green  space-between flex   flex-v-center flex-h-end accordion">
                    <div className="bold-text">Approval #ID: {requestId}</div>
                  </div>
                  <table className="table-view">
                    <thead className="">
                      <tr className="tr-bordered">
                        {previousTransactionsTableHeaders.map((th, index) => (
                          <th key={index} className="th th-bordered">
                            <p>{th}</p>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {previousTransactions.map((item) => {
                        console.log('item nigga ===', item, requestData);
                        return (
                          <tr role="presentation">
                            {/* <td className="td p-r td-bordered">
                              {item.paymentId}
                            </td> */}
                            <td className="td p-r td-bordered">
                              {item.amount}
                            </td>
                            <td className="td p-r td-bordered">
                              {requestData.mda}
                            </td>
                            <td className="td p-r td-bordered">
                              {item.dateModified &&
                                moment(item.dateModified).format('MMM Do YY')}
                            </td>
                            <td className="td p-r td-bordered">
                              {item.paymentDate &&
                                moment(item.paymentDate).format('MMM Do YY')}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              <div className="w-100 m-t-20 p-x-10 p-y-10 bg_green  space-between flex   flex-v-center flex-h-end">
                <div className="bold-text">Expense Type</div>
              </div>

              <div
                style={{
                  boxShadow: '0px 2px 6px #0000000A',
                  backgroundColor: 'white',
                  padding: '20px'
                }}
              >
                <TextInput
                  className="w-100 m-b-10"
                  name="requestTitle"
                  label="Request Title"
                  disabled
                  value={requestData.requestTitle}
                  onChange={handleChange}
                />

                <TextareaInput
                  label="Request Details"
                  name="requestDetails"
                  disabled
                  value={requestData.requestDetails}
                  rows="10"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="">
            <div className="no-padding">
              <div className="no-padding">
                <div
                  style={{
                    boxShadow: '0px 2px 6px #0000000A',
                    backgroundColor: 'white'
                  }}
                >
                  <div
                    style={{
                      borderBottom: '1px solid rgba(108,108,108,.4)',
                      borderTop: '1px solid rgba(108,108,108,.4)'
                    }}
                  >
                    <h3
                      className=""
                      style={{
                        padding: '.8rem',
                        fontWeight: 'bolder',
                        backgroundColor: '#f5f7f9',
                        borderTop: '2px solid rgba(108,108,108,.4)',
                        borderBottom: '2px solid rgba(167,202,167)'
                      }}
                    >
                      Add Item Required
                    </h3>
                  </div>
                </div>
              </div>
              <div
                style={{
                  boxShadow: '0px 2px 6px #0000000A',
                  backgroundColor: 'white',
                  padding: '0'
                }}
              >
                <div className="flex">
                  <div className="w-100 pd-r-0">
                    <div
                      className="flex-between flex-align w-100 m-b-10"
                      style={{
                        boxShadow: '0px 2px 6px #0000000A'
                        // backgroundColor: 'white'
                      }}
                    >
                      <table className="table-view">
                        <thead className="">
                          <tr
                            className="tr-bordered"
                            style={{
                              padding: '.8rem',
                              borderBottom: '1px solid rgba(108,108,108,.4)'
                            }}
                          >
                            {itemTableHeaders.map((th, index) => (
                              <th
                                key={index}
                                className="th th"
                                style={{
                                  padding: '.8rem',
                                  borderBottom:
                                    '1px solid rgba(108,108,108,.4)',
                                  background: '#43425d',
                                  color: '#fff'
                                }}
                              >
                                <p>{th}</p>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="table-body">
                          {items.map((item, index) => (
                            <tr
                              role="presentation"
                              style={{
                                padding: '.8rem',
                                borderBottom: '1px solid rgba(108,108,108,.4)'
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  padding: '.8rem'
                                }}
                                className="td p-r td"
                              >
                                {item.name}
                              </td>
                              <td
                                style={{
                                  padding: '.8rem'
                                }}
                                className="td p-r td"
                              >
                                {item.description}
                              </td>
                              <td
                                style={{
                                  padding: '.8rem',
                                  textAlign: 'center'
                                }}
                                className="td p-r td"
                              >
                                {item.amount}
                              </td>
                              <td
                                style={{
                                  padding: '.8rem',
                                  textAlign: 'center'
                                }}
                                className="td p-r td"
                              >
                                {item.quantity}
                              </td>
                            </tr>
                          ))}
                          <tr
                            role="presentation"
                            style={{
                              padding: '.8rem',
                              borderBottom: '2px solid rgba(108,108,108,.4)',
                              background: '#efefef'
                            }}
                          >
                            <td
                              className="td p-r td"
                              style={{
                                padding: '.8rem',
                                textAlign: 'center',
                                fontWeight: 'bolder'
                              }}
                            >
                              Total
                            </td>
                            <td className="td p-r td"></td>
                            <td
                              className="td p-r td"
                              style={{
                                padding: '.8rem',
                                textAlign: 'center',
                                fontWeight: 'bolder'
                              }}
                            >
                              {total.toLocaleString('en-US')}
                            </td>
                            <td className="td p-r td"></td>
                            <td className="td p-r td"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="no-padding">
              <div className="no-padding">
                <div
                  style={{
                    boxShadow: '0px 2px 6px #0000000A',
                    backgroundColor: 'white'
                  }}
                >
                  <div
                    style={{
                      borderBottom: '1px solid rgba(108,108,108,.4)',
                      borderTop: '1px solid rgba(108,108,108,.4)'
                    }}
                  >
                    <h3
                      className=""
                      style={{
                        padding: '.8rem',
                        fontWeight: 'bolder',
                        backgroundColor: '#f5f7f9',
                        borderTop: '2px solid rgba(108,108,108,.4)',
                        borderBottom: '2px solid rgba(167,202,167)'
                      }}
                    >
                      Supporting Document
                    </h3>
                  </div>
                </div>
              </div>
              <div
                style={{
                  boxShadow: '0px 2px 6px #0000000A',
                  backgroundColor: 'white',
                  padding: '0'
                }}
              >
                <div className="flex">
                  <div className="w-100 pd-r-0">
                    <div
                      className="flex-between flex-align w-100 m-b-10"
                      style={{
                        boxShadow: '0px 2px 6px #0000000A'
                        // backgroundColor: 'white'
                      }}
                    >
                      <table className="table-view">
                        <thead className="">
                          <tr
                            className="tr-bordered"
                            style={{
                              padding: '.8rem',
                              borderBottom: '1px solid rgba(108,108,108,.4)'
                            }}
                          >
                            {supportingDocumentTableHeaders.map((th, index) => (
                              <th
                                key={index}
                                className="th th"
                                style={{
                                  padding: '.8rem',
                                  borderBottom:
                                    '1px solid rgba(108,108,108,.4)',
                                  background: '#43425d',
                                  color: '#fff'
                                }}
                              >
                                <p>{th}</p>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="table-body">
                          {documents.map((item, index) => (
                            <tr
                              role="presentation"
                              style={{
                                padding: '.8rem',
                                borderBottom: '1px solid rgba(108,108,108,.4)'
                              }}
                              key={index}
                            >
                              <td
                                style={{
                                  padding: '.8rem'
                                }}
                                className="td p-r td"
                              >
                                <u
                                  onClick={() => handleFile(item.path)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {item.name}
                                </u>
                              </td>
                              <td
                                style={{
                                  padding: '.8rem'
                                }}
                                className="td p-r td"
                              >
                                {item.description}
                              </td>
                            </tr>
                          ))}
                          <tr
                            role="presentation"
                            style={{
                              padding: '.8rem',
                              borderBottom: '2px solid rgba(108,108,108,.4)',
                              background: '#efefef'
                            }}
                          ></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={() => setShowComments(!showComments)}
            className="w-100 m-t-20  p-x-10 p-y-10 bg_green  space-between flex   flex-v-center flex-h-end pointer"
          >
            <div className="bold-text">Approval Journey</div>
            <div className=" flex   flex-v-center flex-h-end">
              <p
                style={{
                  fontSize: '10px',
                  background: '#efd872',
                  padding: '5px 10px'
                }}
              >
                View Queries and Responses
              </p>
            </div>
          </div>
          {showComments && (
            <div
              style={{
                boxShadow: '0px 2px 6px #0000000A',
                backgroundColor: 'white',
                padding: '0'
              }}
            >
              <div className="flex">
                <div className="w-100 pd-r-0">
                  <div
                    className="flex-between flex-align w-100 m-b-10"
                    style={{
                      boxShadow: '0px 2px 6px #0000000A'
                      // backgroundColor: 'white'
                    }}
                  >
                    <table className="table-view">
                      <thead className="">
                        <tr
                          className="tr-bordered"
                          style={{
                            padding: '.8rem',
                            borderBottom: '1px solid rgba(108,108,108,.4)'
                          }}
                        >
                          {commentsTableHeaders.map((th, index) => (
                            <th
                              key={index}
                              className="th th"
                              style={{
                                padding: '.8rem',
                                borderBottom: '1px solid rgba(108,108,108,.4)',
                                background: '#f4f4f4',
                                color: '#1b1616'
                              }}
                            >
                              <p>{th}</p>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {commentHistory
                          .filter((f) => f.isInitiator != 1)
                          .map((item, index) => (
                            <tr
                              role="presentation"
                              style={{
                                padding: '.8rem',
                                borderBottom: '1px solid rgba(108,108,108,.4)'
                              }}
                              key={index}
                            >
                              <td>
                                <div
                                  className="flex"
                                  style={{ margin: '0 auto', padding: '5px' }}
                                >
                                  <img
                                    style={{
                                      width: '50px',
                                      height: '50px',
                                      borderRadius: '50%'
                                    }}
                                    src="https://static.thenounproject.com/png/1995071-200.png"
                                    alt=""
                                  />
                                  <div
                                    style={{
                                      marginLeft: '5px',
                                      marginTop: '10px',
                                      fontSize: '12px'
                                    }}
                                  >
                                    <p style={{ fontWeight: 'bolder' }}>
                                      {item.assignedOfficer}
                                    </p>
                                    <span style={{ fontSize: '10px' }}>
                                      {item.categoryTitle}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    marginLeft: '5px',
                                    fontSize: '12px'
                                  }}
                                >
                                  <p style={{ fontWeight: 'bolder' }}>
                                    {item.inTray
                                      ? moment(item.inTray).format(
                                          'MMMM Do YYYY, h:mm:ss a'
                                        )
                                      : ''}
                                  </p>
                                  <span style={{ fontSize: '10px' }}>
                                    {item.inTray
                                      ? moment(
                                          item.inTray,
                                          'YYYYMMDD'
                                        ).fromNow()
                                      : ''}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    marginLeft: '5px',
                                    fontSize: '12px'
                                  }}
                                >
                                  <p style={{ fontWeight: 'bolder' }}>
                                    {item.outTray
                                      ? moment(item.outTray).format(
                                          'MMMM Do YYYY, h:mm:ss a'
                                        )
                                      : ''}
                                  </p>
                                  <span style={{ fontSize: '10px' }}>
                                    {item.outTray
                                      ? moment(
                                          item.outTray,
                                          'YYYYMMDD'
                                        ).fromNow()
                                      : ''}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    marginLeft: '5px',
                                    fontSize: '12px'
                                    // width: '25%'
                                  }}
                                >
                                  <p style={{ fontWeight: 'bolder' }}>
                                    {item.comment}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="w-100 m-t-20  p-x-10 p-y-10 bg_green  space-between flex flex-v-center flex-h-end pointer"
            style={{ borderBottom: '1px solid #41821e' }}
          >
            <div className="bold-text">Reviewer's Note</div>
          </div>
          <div
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              backgroundColor: 'white',
              padding: '20px'
            }}
          >
            <TextareaInput
              name="comment"
              value={comment}
              rows="5"
              onChange={(e) => {
                setComment(e.target.value);
                updateReviewerComment && updateReviewerComment(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              backgroundColor: 'white',
              padding: '0 20px'
            }}
          >
            <div className="flex w-100 space-between">
              <div className="">
                {/* <p
                  style={{
                    fontSize: '10px',
                    background: '#efd872',
                    padding: '5px 10px'
                  }}
                >
                  Upload supporting document(s)
                </p> */}
              </div>
              <div
                // className="w-100"
                style={{
                  color: 'rgba(120,120,120,.9)',
                  textAlign: 'left',

                  paddingBottom: '.5rem',
                  display: 'flex',
                  justifyContent: 'end'
                }}
              >
                <button
                  onClick={updateWorkFlowData}
                  className="btn btn-primary m-r-5"
                >
                  Submit
                </button>
                <button
                  onClick={() => setComment('')}
                  className="btn btn-gray m-r-5"
                >
                  Clear Entry
                </button>
                <button
                  onClick={closeModal ? closeModal : goBack}
                  className="btn btn-light"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPictureModal && (
        <PictureModal closeModal={closePictureModal} picture={url} />
      )}
    </div>
  );
};

export default TreasuryRequestDetails;
