import { IoMdClose } from "react-icons/io";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useState } from "react";
import { useEffect } from "react";
import parser from 'html-react-parser';

const Liability = ({ closeModal, paymentVoucher, month, mda, voucherType, office }) => {

    const [ledgerList, setLedgerList] = useState([]);

    const numberWithCommas = (x) => {

        if (String(x).includes(',')) {
            return String(x).replace(/,/g, '')?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        else {
            return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        }

    }

    const matchVoucherTypes = (type) => {
        if (voucherType?.toLowerCase()?.includes("Capital Voucher"?.toLowerCase())) {
            return type?.toLowerCase()?.includes(voucherType?.toLowerCase())
        }
        else if (voucherType?.toLowerCase()?.includes("Recurrent Voucher"?.toLowerCase())) {
            return type?.toLowerCase()?.includes("Salary Voucher"?.toLowerCase()) || type?.toLowerCase()?.includes("Recurrent Voucher"?.toLowerCase()) || type?.toLowerCase()?.includes("Overhead Voucher"?.toLowerCase())
        }

        return false;
    }

    useEffect(() => {

        const temp = paymentVoucher?.filter((record) => {


            return matchVoucherTypes(record?.voucher?.voucherType) && record?.voucher?.statusDetails === "Pending" && record?.voucher?.mda === mda && record?.voucher?.monthOfPayment === month
        })
        setLedgerList(temp);
    }, [])


    return (

        <div className="overlay ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '100%', margin: '0 auto', backgroundColor: "white", height: "auto" }} >
                <div className=" no-padding ">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large w-100"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <div className="w-100 ">

                            <table id="table-to-xls" className="w-100">
                                <thead>
                                    <tr>
                                        <th className="p-10" colSpan="9">{mda}</th>
                                    </tr>
                                    <tr>
                                        <th className="p-10 bold-text" >DATE</th>
                                        <th className="p-10" >{matchVoucherTypes("Recurrent Voucher") ? "NAME OF MDA" : "HEAD"}</th>
                                        <th className="p-10" >ECONOMIC CODE</th>
                                        <th className="p-10" >SCH NO</th>
                                        <th className="p-10" >PV NO</th>
                                        <th className="p-10" >PAYEE</th>
                                        <th className="p-10" colSpan="2" >PURPOSE OF PAYMENT</th>
                                        <th className="p-10" >AMOUNT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        ledgerList?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="p-10">{new Date(item?.voucher?.dateModified).toLocaleDateString()}</td>
                                                    <td className="p-10">{matchVoucherTypes("Recurrent Voucher") ? item?.voucher?.mda : item?.voucher?.head}</td>
                                                    <td className="p-10">{item?.voucher?.subhead}</td>
                                                    <td className="p-10">{item?.schedule?.scheduleNO}</td>
                                                    <td className="p-10">{item?.voucher?.pvNo}</td>
                                                    <td className="p-10">{item?.voucher?.drTo}</td>
                                                    <td className="p-10" align="right" colSpan="2">{parser(item?.voucher?.description?.toUpperCase()?.replaceAll("<td>", "<td className='border-table'>")?.replace(/\s*&nbsp;\s*/ig, " "))}</td>
                                                    <td className="p-10" align="right">{`${numberWithCommas(Number(parseFloat(item?.voucher?.amount) + item?.voucher?.subVouchers?.reduce(
                                                        (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                                                    )).toFixed(2))}`}</td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>

                        </div>

                    </div>
                    {/* <button className="btn btn-large w-100 m-t-20"> To Excel</button> */}
                    <ReactHTMLTableToExcel
                        // id="test-table-xls-button"
                        className="btn btn-large w-100"
                        // table="table-to-xls"
                        filename={`${voucherType} Ledger for ${mda}`}
                        sheet="tablexls"
                        buttonText="Download as XLS" />
                    {/* <button className="btn btn-large w-100">Download as XLS</button> */}
                </div>
            </div>
        </div >

    )
}

export default Liability;