import React, { useState } from 'react';
import userAvatar from "../../assets/images/user-avater.png"
import { timeAgo } from '../../utility/general';


const tableHeaders = [
    'Approvers',
    "Date In",
    'Date out',
    'Comments'
];


const approvers = [
    {
        approverProfilePicture: ""
    }
]

const ChequeModal = ({
    paymentVoucher,
    schedule,
    type
}) => {
    const [showPictureModal, setShowPictureModal] = React.useState(false);
    const [modalData, setModalData] = useState({});

    const closeModal = () => {
        setShowPictureModal(false);
    };

    const displayComments = () => {
        if (paymentVoucher.voucherType === "Internal") {
            return <tbody className="table-body">
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.dfaPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.dfa}</strong></p>
                                <h3>{"DFA"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.dfaDateIn ? new Date(paymentVoucher?.dfaDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.dfaDateOut ? timeAgo(new Date(paymentVoucher?.dfaDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.dfaDateOut ? new Date(paymentVoucher?.dfaDateOut)?.toUTCString() : "-"}</p>
                        <p>{paymentVoucher.dfaDateOut ? timeAgo(new Date(paymentVoucher?.dfaDateOut)) : ""}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.dfaComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.auditorPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.auditor}</strong></p>
                                <h3>{"Internal Auditor"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.auditorDateIn ? new Date(paymentVoucher?.auditorDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.auditorDateIn ? timeAgo(new Date(paymentVoucher?.auditorDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.auditorDateOut ? new Date(paymentVoucher?.auditorDateOut).toUTCString() : "-"}</p>
                        <p>{paymentVoucher.auditorDateOut ? timeAgo(new Date(paymentVoucher?.auditorDateOut)) : ""}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.auditComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.psPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.ps}</strong></p>
                                <h3>{"PS"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.psDateIn ? new Date(paymentVoucher?.psDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.psDateIn ? timeAgo(new Date(paymentVoucher?.psDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.psDateOut ? new Date(paymentVoucher?.psDateOut).toUTCString() : "-"}</p>
                        <p>{paymentVoucher.psDateOut ? timeAgo(new Date(paymentVoucher?.psDateOut)) : ""}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.psComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>




            </tbody>

        }
        else if (paymentVoucher.voucherType === "Salary Voucher") {
            return <tbody className="table-body">
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.dfaPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.dfa}</strong></p>
                                <h3>{"DFA"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.dfaDateIn ? new Date(paymentVoucher?.dfaDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.dfaDateOut ? timeAgo(new Date(paymentVoucher?.dfaDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.dfaDateOut ? new Date(paymentVoucher?.dfaDateOut)?.toUTCString() : "-"}</p>
                        <p>{paymentVoucher.dfaDateOut ? timeAgo(new Date(paymentVoucher?.dfaDateOut)) : ""}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.dfaComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.auditorPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.auditor}</strong></p>
                                <h3>{"Internal Auditor"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.auditorDateIn ? new Date(paymentVoucher?.auditorDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.auditorDateIn ? timeAgo(new Date(paymentVoucher?.auditorDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.auditorDateOut ? new Date(paymentVoucher?.auditorDateOut).toUTCString() : "-"}</p>
                        <p>{paymentVoucher.auditorDateOut ? timeAgo(new Date(paymentVoucher?.auditorDateOut)) : ""}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.auditComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.finalAcctPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.finalAcct}</strong></p>
                                <h3>{"Final Account"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.finalAcctDateIn ? new Date(paymentVoucher?.finalAcctDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.finalAcctDateIn ? timeAgo(new Date(paymentVoucher?.finalAcctDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.finalAcctDateOut ? new Date(paymentVoucher?.finalAcctDateOut).toUTCString() : "-"}</p>
                        <p>{paymentVoucher.finalAcctDateOut ? timeAgo(new Date(paymentVoucher?.finalAcctDateOut)) : ""}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.finalAcctComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.inspectoratePic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.inspectorate}</strong></p>
                                <h3>{"Final Account"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.inspectorateDateIn ? new Date(paymentVoucher?.inspectorateDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.inspectorateDateIn ? timeAgo(new Date(paymentVoucher?.inspectorateDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.inspectorateDateOut ? new Date(paymentVoucher?.inspectorateDateOut).toUTCString() : "-"}</p>
                        <p>{paymentVoucher.inspectorateDateOut ? timeAgo(new Date(paymentVoucher?.inspectorateDateOut)) : ""}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.inspectorateComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.expControlPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.expControl}</strong></p>
                                <h3>{"Expenditure Control"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.expCtrlDateIn ? new Date(paymentVoucher?.expCtrlDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.expCtrlDateIn ? timeAgo(new Date(paymentVoucher?.expCtrlDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.expCtrlDateOut ? new Date(paymentVoucher?.expCtrlDateOut).toUTCString() : "-"}</p>
                        <p>{paymentVoucher.expCtrlDateOut ? timeAgo(new Date(paymentVoucher?.expCtrlDateOut)) : ""}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.expControlComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.agPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.ag}</strong></p>
                                <h3>{"Accountant General"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{new Date(paymentVoucher?.agDateIn).toUTCString()}</p>
                        <p>{timeAgo(new Date(paymentVoucher?.agDateIn))}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.agDateOut ? new Date(paymentVoucher?.agDateOut).toUTCString() : "Awaiting"}</p>
                        <p>{paymentVoucher.agDateOut ? timeAgo(new Date(paymentVoucher?.agDateOut)) : ""}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.agComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.mgtAcctPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.mgtAcct}</strong></p>
                                <h3>{"Management Account"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{new Date(paymentVoucher?.mgtAcctDateIn).toUTCString()}</p>
                        <p>{timeAgo(new Date(paymentVoucher?.mgtAcctDateIn))}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.mgtAcctDateOut ? new Date(paymentVoucher?.mgtAcctDateOut).toUTCString() : "Awaiting"}</p>
                        <p>{paymentVoucher.mgtAcctDateOut ? timeAgo(new Date(paymentVoucher?.mgtAcctDateOut)) : ""}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.mgtAcctComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.tcaPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.tca}</strong></p>
                                <h3>{"Treasury Cash Account"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{new Date(paymentVoucher?.tcaDateIn).toUTCString()}</p>
                        <p>{timeAgo(new Date(paymentVoucher?.tcaDateIn))}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.tcaDateOut ? new Date(paymentVoucher?.tcaDateOut).toUTCString() : "Awaiting"}</p>
                        <p>{paymentVoucher.tcaDateOut ? timeAgo(new Date(paymentVoucher?.tcaDateOut)) : ""}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.tcaComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
            </tbody>

        }
        else {
            return <tbody className="table-body">
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.dfaPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.dfa}</strong></p>
                                <h3>{"DFA"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10' >
                        <p className="m-b-10">{paymentVoucher?.dfaDateIn ? new Date(paymentVoucher?.dfaDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.dfaDateOut ? timeAgo(new Date(paymentVoucher?.dfaDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.dfaDateOut ? new Date(paymentVoucher?.dfaDateOut)?.toUTCString() : "-"}</p>
                        <p>{paymentVoucher.dfaDateOut ? timeAgo(new Date(paymentVoucher?.dfaDateOut)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.dfaComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.auditorPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.auditor}</strong></p>
                                <h3>{"Internal Auditor"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.auditorDateIn ? new Date(paymentVoucher?.auditorDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.auditorDateIn ? timeAgo(new Date(paymentVoucher?.auditorDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.auditorDateOut ? new Date(paymentVoucher?.auditorDateOut).toUTCString() : "-"}</p>
                        <p>{paymentVoucher.auditorDateOut ? timeAgo(new Date(paymentVoucher?.auditorDateOut)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.auditComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.finalAcctPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.finalAcct}</strong></p>
                                <h3>{"Final Account"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.finalAcctDateIn ? new Date(paymentVoucher?.finalAcctDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.finalAcctDateIn ? timeAgo(new Date(paymentVoucher?.finalAcctDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.finalAcctDateOut ? new Date(paymentVoucher?.finalAcctDateOut).toUTCString() : "-"}</p>
                        <p>{paymentVoucher.finalAcctDateOut ? timeAgo(new Date(paymentVoucher?.finalAcctDateOut)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.finalAcctComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.expControlPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.expControl}</strong></p>
                                <h3>{"Expenditure Control"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.expCtrlDateIn ? new Date(paymentVoucher?.expCtrlDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.expCtrlDateIn ? timeAgo(new Date(paymentVoucher?.expCtrlDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.expCtrlDateOut ? new Date(paymentVoucher?.expCtrlDateOut).toUTCString() : "-"}</p>
                        <p>{paymentVoucher.expCtrlDateOut ? timeAgo(new Date(paymentVoucher?.expCtrlDateOut)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.expControlComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.agPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.ag}</strong></p>
                                <h3>{"Accountant General"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.agDateIn ? new Date(paymentVoucher?.agDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.agDateIn ? timeAgo(new Date(paymentVoucher?.agDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.agDateOut ? new Date(paymentVoucher?.agDateOut).toUTCString() : "-"}</p>
                        <p>{paymentVoucher.agDateOut ? timeAgo(new Date(paymentVoucher?.agDateOut)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.agComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.govPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.governor}</strong></p>
                                <h3>{"Governor"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.govDateIn ? new Date(paymentVoucher?.govDateIn)?.toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.govDateIn ? timeAgo(new Date(paymentVoucher?.govDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.govDateOut ? new Date(paymentVoucher?.govDateOut)?.toUTCString() : "-"}</p>
                        <p>{paymentVoucher.govDateOut ? timeAgo(new Date(paymentVoucher?.govDateOut)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.govComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.mgtAcctPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.mgtAcct}</strong></p>
                                <h3>{"Management Account"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{ paymentVoucher?.mgtAcctDateIn ? new Date(paymentVoucher?.mgtAcctDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.mgtAcctDateIn ? timeAgo(new Date(paymentVoucher?.mgtAcctDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.mgtAcctDateOut ? new Date(paymentVoucher?.mgtAcctDateOut).toUTCString() : "-"}</p>
                        <p>{paymentVoucher.mgtAcctDateOut ? timeAgo(new Date(paymentVoucher?.mgtAcctDateOut)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.mgtAcctComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
                <tr>
                    <td className='p-l-10 p-r-10'>
                        <div className="w-100 flex flex-v-center p-10">
                            <img className="img-avatar" src={paymentVoucher?.tcaPic || userAvatar} alt="" />

                            <div className="m-l-5">
                                <p className="m-b-10"><strong>{paymentVoucher?.tca}</strong></p>
                                <h3>{"Treasury Cash Account"}</h3>
                            </div>
                        </div>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.tcaDateIn ? new Date(paymentVoucher?.tcaDateIn).toUTCString() : "-"}</p>
                        <p>{paymentVoucher?.tcaDateIn ? timeAgo(new Date(paymentVoucher?.tcaDateIn)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10'>
                        <p className="m-b-10">{paymentVoucher?.tcaDateOut ? new Date(paymentVoucher?.tcaDateOut).toUTCString() : "-"}</p>
                        <p>{paymentVoucher.tcaDateOut ? timeAgo(new Date(paymentVoucher?.tcaDateOut)) : "-"}</p>
                    </td>
                    <td className='p-l-10 p-r-10 p-20' >
                        <p className="m-b-10">
                            {paymentVoucher?.tcaComment}
                        </p>
                        {/* <button className="rounded-btn">Respond to comment</button> */}
                    </td>

                </tr>
            </tbody>
        }
    }

    return (
        <div className="w-100">
            {
                type === 'comment' ?
                    <div className="w-100 space-between flex-v-center">
                        <div className="w-100 flex bg_green p-x-10 p-y-10">Department Comments</div>
                        <div className="table-view">
                            <table className="table-view">
                                <thead className="">
                                    <tr className="tr-bordered">
                                        {tableHeaders.map((th, index) => (
                                            <th key={index} className="th th-bordered">
                                                <p>{th}</p>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                {displayComments()}

                            </table>
                        </div>

                    </div>

                    :
                    <button className='btn btn-primary m-t-20 flex-v-center' onClick={() => setShowPictureModal(!showPictureModal)}>
                        Show Cheque
                    </button>}



            {
                showPictureModal && <div>
                    <img src={paymentVoucher?.chequeUrl} alt="cheque" style={{ width: "21.7cm" }} />
                    <p className='m-t-20'>
                        Not an image?
                    </p>
                    <a href={paymentVoucher?.chequeUrl} target="_blank" rel="noopener noreferrer">Download here</a>
                </div>


            }

        </div>
    )
}
export default ChequeModal;