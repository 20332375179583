import CashBudgetForm from "../layouts/CashBudgetForm";
import { post, get, put, del } from "../../utility/fetch";
import { getDeptName, getMonth, numberWithCommas, years, months } from "../../utility/general";
import { useCallback, useEffect, useState } from 'react';
import notification from '../../utility/notification';
import { Pagination } from 'antd';
import { AiOutlineDelete, AiOutlineSend } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import CashBudgetPreview from "../layouts/CashBudgetPreview";
import Select from 'react-select';

const tableHeaders = [
    "Initiator",
    "MDA",
    // 'Economic Code',
    // 'Economic Code Description',
    "Initiation Date",
    "Month",
    'Year',
    'View Cash Budget Form',
    "Action"
]

const CashBudgetCopy = () => {
    const [payload, setPayload] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [status, setStatus] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [paginationLength, setPaginationLength] = useState(0);
    const [showCashBudget, setShowCashBudget] = useState(false);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [cashData, setCashData] = useState([]);
    const [formData, setFormData] = useState({
        mdaId: Number(localStorage.getItem("mdaId")),
        economicCode: "",
        refNumber: "",
        economicCodeDescription: "",
        counterSigningOfficer: 0,
        amount: "",
        month: "",
        year: ""
    });
    const [counterSigningOfficers, setCounterSigningOfficers] = useState([]);
    const [approverId, setApproverId] = useState(0);
    const [approvers, setApprovers] = useState([]);
    const [employeeName, setemployeeName] = useState([]);
    const [mdaList, setMdaList] = useState([]);
    const [counterSigningOfficerName, setCounterSigningOfficerName] = useState()

    const clearFormData = () => {
        setFormData({
            ...formData,
            economicCode: "",
            refNumber: "",
            economicCodeDescription: "",
            amount: "",
            mdaId: Number(localStorage.getItem("mdaId"))
        })

    }

    const getCounterSigningOfficer = async (e) => {
        const res = await get(`/ModuleUsers/DFAs/${e}`)
        setCounterSigningOfficers(res?.data)
    };

    const handleShow = (data) => {
        setCashData(data)
        setShowCashBudget(true);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleMonth = (value) => {
        setFormData({
            ...formData,
            month: value
        })
    }

    const handleYear = (value) => {
        setFormData({
            ...formData,
            year: value,
        })
    }



    useEffect(() => {
        getCashBudget();
        getApproversMDAs();
    }, [pageSize, pageNumber])


    const handleCounterSigningOfficerId = async (e) => {
        const res = await get(`/staffById/${e.value}`)

        let updatedPayload = {
            ...formData,
            counterSigningOfficer: res?.data?.id,
        };

        setFormData(updatedPayload);
    };


    const paginationChangeHandler = (current, pageSize) => {
        setPageNumber(current);
        setPageSize(pageSize);
    };

    const getCashBudget = useCallback(async (value = 1) => {
        let res = await get(`/CashBudget/Paginated?PageNumber=1&PageSize=100`)
        if (res?.status === "success") {
            setPaginationLength(res?.data?.totalItemCount)
            setPendingRequests(
                res?.data?.items?.reduce((acc, item) => {

                    acc[item.month] = acc[item.month] || [];
                    acc[item.month].push(item);
                    return acc;
                }, [])
            )
        }
    }, [])


    const getApproversMDAs = async () => {
        const res = await get(`/mdas`)
        if (res.status === 'success') {
            // this.setState({
            const roleList = res?.data.map((item) => ({
                name: `${item.name}`,
                value: item.id,
            }));
            setMdaList(roleList);

        };
    };

    const saveCashBudget = async () => {
        let res;
        if (status === "edit") {
            res = await put("/CashBudget", payload);
        }
        else {
            res = await post("/CashBudget", payload);
        }

        if (res?.status === "success") {
            notification({
                title: "Saved Successfully",
                message: "Cash Budget for the month saved successfully",
                type: "success"
            })
        }
        else {
            notification({
                title: "Error",
                message: res?.message,
                type: "danger"
            })
        }
        setPayload([]);
        getCashBudget();
    }

    const monthValue = (month) => {
        let date = months.find(item => item.value === month)
        return date;

    }

    const yearValue = (year) => {
        let date = years.find(item => item.value === year)
        return date;
    }

    const addItem = (e) => {
        e.preventDefault();
        let updatedFormData = {
            ...formData,
            economicCode: formData?.economicCode,
            amount: Number(formData?.amount)
        }

        if (formData?.year === "" || formData?.month === "") {
            notification({
                title: "Validation Warning",
                message: "Please ensure you fill in the year and month before saving",
                type: "warning",

            })
            return;
        }

        if (formData?.counterSigningOfficer === 0 || !formData.counterSigningOfficer) {
            notification({
                title: "Validation Warning",
                message: "Please ensure you choose your Counter-Signing Officer before saving",
                type: "warning",

            })
            return;
        }

        if (
            formData?.amount === 0
            || formData?.economicCode === ""
            || formData?.economicCodeDescription === ""
            || formData?.mdaId === 0
        ) {
            notification({
                title: "Validation Warning",
                message: "Please ensure you fill all fields before addition to form",
                type: "warning",

            })
            return;
        }
        else if (formData?.month === 0 || formData?.year === null) {
            notification({
                title: "Validation Warning",
                message: "Please ensure you fill all fields before addition to form",
                type: "warning",

            })
            return;
        }
        setPayload((prev) => ([
            ...prev,
            updatedFormData
        ]))

        clearFormData();
    }

    const submitFromSaved = async (data) => {
        setIsSubmitting(false);

        let payloadData = {
            month: data?.month,
            year: data?.year
        }

      

        try {
            const res = await put('/CashBudget/Submit', payloadData);
            setIsSubmitting(false);

            if (res && res.status === 'success') {
                if (res.code === 1) {
                    notification({
                        title: `Successful Request Submission`,
                        message: res.message,
                        type: 'success'
                    });
                    getCashBudget();
                } else {
                    notification({
                        title: `Request Submission Error`,
                        message: res.message,
                        type: 'error'
                    });
                }
            } else {
                notification({
                    title: 'Network Error',
                    message: `Something went wrong while creating an Request. Please, try again later.`,
                    type: 'error'
                });
            }
        } catch (error) {
            notification({
                title: 'Network Error',
                message: `Something went wrong while creating an Request. Please, try again later.`,
                type: 'error'
            });
            setIsSubmitting(false);
        }
        setIsSubmitting(false);
    }

    const editSaved = (data) => {
        setStatus("edit");
        window.scrollTo(0, 0);
        setFormData({
            ...formData,
            month: data[0].month,
            year: data[0].year
        })

        let tempPayload = data?.map((item) => (
            {
                mdaId: item?.mdaId,
                month: item?.month,
                counterSigningOfficer: item?.counterSigningOfficer,
                year: item?.year,
                economicCode: item?.economicCode,
                refNumber: item?.refNumber,
                economicCodeDescription: item?.economicCodeDescription,
                amount: item?.amount,
                id: item?.id
            }
        ))
        setPayload(tempPayload);

    }

    const deleteSaved = async (data) => {
        setIsSubmitting(true);
        let cashBudgetData = data?.map((item) => (
            item?.id
        ))

        try {
            const res = await del('/CashBudget', cashBudgetData);
            setIsSubmitting(false);

            if (res && res.status === 'success') {
                if (res.code === 1) {
                    notification({
                        title: `Successful Request Deletion`,
                        message: res.message,
                        type: 'success'
                    });
                    getCashBudget();

                } else {
                    notification({
                        title: `Request Deletion Error`,
                        message: res.message,
                        type: 'error'
                    });
                    setStatus("");
                }
            } else {
                notification({
                    title: 'Network Error',
                    message: `Something went wrong while deleting this Request. Please, try again later.`,
                    type: 'error'
                });
            }
        } catch (error) {
            notification({
                title: 'Network Error',
                message: `Something went wrong while deleting this Request. Please, try again later.`,
                type: 'error'
            });
            setIsSubmitting(false);
        }
        setIsSubmitting(false)

    }




    return (
        <div className="">
            <form action="" className="">
                <div className="w-75 bg-white p-40 form-bg-shadow m-b-20">
                    <div className="flex w-100 m-b-20">

                        <div className=" w-30 m-r-20">
                            <label htmlFor="economicCode">Economic Code:</label>
                            <input onChange={handleChange} name="economicCode" value={formData?.economicCode} type="number" className="m-r-10  cashInput block" />
                        </div>
                        <div className=" w-30 m-r-20">
                            <label htmlFor="economicCodeDescription"> Economic Code Description:</label>
                            <input onChange={handleChange} name="economicCodeDescription" value={formData?.economicCodeDescription} type="text" className="m-r-10 block cashInput" />
                        </div>
                        <div className=" w-30">
                            <label htmlFor="amount">Amount:</label>
                            <input onChange={handleChange} name="amount" type="number" value={formData?.amount} className="m-r-10 block cashInput" />
                        </div>
                    </div>

                    <button onClick={addItem} className="btn w-100">Add</button>

                    <div className="m-t-40">
                        <div className='m-b-10 w-100 form-group '>
                            <label>Countersigning Officer's MDA</label>
                            <Select
                                placeholder="Countersigning Officer's MDA..."
                                color='rgba(120,120,120,0.8)'
                                className='m-b-10 w-w00'
                                isDisabled={payload?.length > 0}
                                onChange={(value) => {
                                    setCounterSigningOfficerName(value);
                                    getCounterSigningOfficer(value?.value);
                                    // setApprovers(value);
                                }}
                                value={counterSigningOfficerName}
                                options={mdaList.map((m) => ({
                                    label: m.name,
                                    value: m.value,
                                }))}
                            />

                        </div>

                        <div className='m-b-10 w-100 form-group '>
                            <label>Countersigning Officer</label>
                            <Select
                                placeholder='Select Countersigning Officer'
                                color='rgba(120,120,120,0.8)'
                                className='m-b-10 w-w00'
                                isDisabled={payload?.length > 0}
                                onChange={(value) => {

                                    setemployeeName(value);
                                    handleCounterSigningOfficerId(value);
                                    setApprovers(value);
                                }}
                                value={employeeName || approvers}
                                options={counterSigningOfficers?.map((m) => ({
                                    label: m.user,
                                    value: m.userId,
                                }))}
                            />
                        </div>
                    </div>

                    <div className="m-b-20 m-t-40">
                        <p>Select Month:</p>
                        <Select isDisabled={payload?.length > 0} value={monthValue(payload[0]?.month)} onChange={(value) => handleMonth(value?.value)} options={months} className="w-48 m-b-20" />
                        <p>Select Year:</p>
                        <Select isDisabled={payload?.length > 0} value={yearValue(payload[0]?.year)} onChange={(value) => handleYear(value?.value)} options={years} className="w-48" />
                        {/* Please add Cash Budget Items for {new Date()?.toLocaleString('default', { month: 'long' })} {new Date().getFullYear()} */}
                    </div>
                </div>

            </form>

            {Array?.isArray(payload) && payload?.length > 0 && <CashBudgetForm data={payload} setPayload={setPayload} />}

            <button onClick={saveCashBudget} className="btn w-75 m-t-20 ">{status === "edit" ? "Update" : "Save"}</button>




            <div className="overflow-table m-t-80">
                <table className="table-view" >
                    <thead className="">
                        <tr className="tr-bordered">
                            {tableHeaders.map((th, index) => (
                                <th key={index} className="th th-bordered">
                                    <p>{th}</p>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    {pendingRequests?.length > 0 && <tbody className="table-body">
                        {pendingRequests?.filter(item => item).map((item) => (
                            <tr role="presentation">
                                <td className="td p-r td-bordered">
                                    {item[0]?.createdByName}
                                </td>
                                <td className="td p-r td-bordered">
                                    {item[0]?.mda}
                                </td>
                                {/* <td className="td p-r td-bordered">
                                    {item[0]?.economicCode}
                                </td>
                                <td className="td p-r td-bordered">
                                    {item[0]?.economicCodeDescription}
                                </td> */}
                                <td className="td p-r td-bordered">
                                    {new Date(item[0]?.dateCreated).toUTCString()}
                                </td>
                                <td className="td p-r td-bordered">
                                    {getMonth(item[0]?.month)}
                                </td>
                                <td className="td p-r td-bordered">
                                    {item[0]?.year}
                                </td>
                                <td className="td p-r td-bordered">
                                    <button onClick={() => handleShow(item)} className="btn w-100">View Form</button>
                                </td>
                                <td className="td p-r td-bordered" width={"17%"}>
                                    <div className='flex space-between'>
                                        <div className={`status_ball m-r-10 ${item[0]?.statusDetails === 'Pending' ? 'bg-amber' : item[0]?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : item[0]?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`}>&nbsp;</div>
                                        <div>
                                            {
                                                item[0]?.statusDetails === 'Pending' ? `Pending With ${getDeptName(item[0]?.journeyStateId)}`
                                                    :
                                                    item[0]?.statusDetails === 'ApprovalCompleted' ? 'Approved'
                                                        :
                                                        item[0]?.statusDetails === 'Rejected' ? 'Declined'
                                                            :
                                                            <div>
                                                                <AiOutlineSend style={{ marginRight: "16px" }} size={30} color="green" className="pointer" onClick={() => { submitFromSaved(item[0]) }} />
                                                                <MdEdit style={{ marginRight: "16px" }} size={30} color="gray" className="pointer" onClick={() => { editSaved(item) }} />
                                                                <AiOutlineDelete style={{ marginRight: "16px" }} size={30} color="red" className="pointer" onClick={() => { deleteSaved(item) }} />
                                                            </div>
                                            }
                                        </div>
                                    </div>
                                </td>

                            </tr>
                        ))
                        }
                    </tbody>}
                </table>
            </div>

            <div style={{ marginTop: '20px' }}>
                <Pagination
                    showSizeChanger
                    pageSize={pageSize}
                    onShowSizeChange={paginationChangeHandler}
                    current={pageNumber}
                    total={paginationLength}
                    // showTotal={true}
                    onChange={paginationChangeHandler}
                    // pageSizeOptions={pageSizeOptions}
                    responsive
                />
            </div>
            {showCashBudget && <CashBudgetPreview data={cashData} closeModal={() => setShowCashBudget(false)} />}
        </div>
    )
}

export default CashBudgetCopy;