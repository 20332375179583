import { IoMdClose } from "react-icons/io";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useState, useEffect } from "react";

const CashBook = ({ closeModal, isDFA, paymentVoucher, month, year, bank, office }) => {

    const [cashBookList, setCashBookList] = useState([]);

    const numberWithCommas = (x) => {

        if (String(x).includes(',')) {
            return String(x).replace(/,/g, '')?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        else {
            return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        }

    }

    useEffect(() => {
        const temp = paymentVoucher?.filter((record) => {
            return record?.voucher?.account?.bank === bank && record?.voucher?.yearOfPayment === Number(year) && record?.voucher?.monthOfPayment === month
        })

        const otherTemp = paymentVoucher?.filter((record) => {
            return record?.voucher?.yearOfPayment === Number(year) && record?.voucher?.monthOfPayment === month
        })

        if (!isDFA ? setCashBookList(temp) : setCashBookList(otherTemp))
            setCashBookList(temp);
    }, [])


    return (
        <div className="overlay ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '100%', margin: '0 auto', backgroundColor: "white", height: "auto" }} >
                <div className=" no-padding ">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large w-100"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <div className="w-100 ">
                            {isDFA ?
                                <p className="center-text">
                                    OFFICE OF THE {office?.toUpperCase()}
                                </p>
                                :
                                <p className="center-text">
                                    OFFICE OF THE ACCOUNTANT GENERAL
                                </p>}

                            {
                                isDFA ?
                                    <p className="center-text">
                                        {localStorage?.getItem("mda")}
                                    </p>
                                    :
                                    <p className="center-text">
                                        {office === "MGT" ? "MANAGEMENT ACCOUNTS AND REPORTING DEPARTMENT" : "TREASURY CASH ACCOUNTS DEPARTMENT"}
                                    </p>
                            }
                            <h1 className="center-text">
                                TREASURY CASH BOOK FOR THE MONTH OF {month?.toUpperCase()} {year}
                            </h1>
                            <h4 className="right-text bolder-text">
                                A/CS GEN.18 (REVISED)
                            </h4>
                            <p className="center-text">
                                {bank}
                            </p>
                            <p className="center-text">
                                CAPITAL EXPENDITURE ACCOUNT
                            </p>


                            <table id="table-to-xls" className="w-100">
                                <thead>
                                    <tr>
                                        <th className="p-10" rowSpan="2">Date</th>
                                        <th className="p-10" rowSpan="2">CB S/N</th>
                                        <th className="p-10" rowSpan="2">Department Number</th>
                                        <th className="p-10" rowSpan="2">Acount Name</th>
                                        <th className="p-10" colSpan="2">CLASSIFICATION</th>
                                        <th className="p-10" rowSpan="2">Cheque No.</th>
                                        <th className="p-10" colSpan="2">Cr</th>
                                    </tr>
                                    <tr>
                                        <th className="p-10" >Purpose</th>
                                        <th className="p-10" >Admin/ECO</th>
                                        <th >Amount &nbsp; &nbsp;  N &nbsp; &nbsp; &nbsp; K &nbsp; &nbsp; &nbsp;</th>
                                        <th >Bank &nbsp; &nbsp; N &nbsp; &nbsp; &nbsp;  K &nbsp; &nbsp; &nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cashBookList?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="p-10">{new Date(item?.voucher?.dateModified).toLocaleDateString()}</td>
                                                    <td className="p-10" align="center">{index + 1}</td>
                                                    <td className="p-10">{item?.voucher?.departmentNo}</td>
                                                    <td className="p-10">{item?.voucher?.account?.beneficiary}</td>
                                                    <td className="p-10">{item?.voucher?.purpose}</td>
                                                    <td className="p-10">{item?.voucher?.head}</td>
                                                    <td className="p-10">E-Pay</td>
                                                    <td className="p-10" align="right">{`${numberWithCommas(Number(parseFloat(item?.voucher?.amount) + item?.voucher?.subVouchers?.reduce(
                                                        (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                                                    )).toFixed(2))}`}</td>
                                                    <td className="p-10" align="right">{`${numberWithCommas(parseFloat(item?.voucher?.amount) + item?.voucher?.subVouchers?.reduce(
                                                        (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                                                    ))}`}</td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>

                        </div>

                    </div>
                    {/* <button className="btn btn-large w-100 m-t-20"> To Excel</button> */}
                    <ReactHTMLTableToExcel
                        // id="test-table-xls-button"
                        className="btn btn-large w-100"
                        // table="table-to-xls"
                        filename={`Cashbook for the month of ${month}`}
                        sheet="tablexls"
                        buttonText="Download as XLS" />

                    {/* <button className="btn btn-large w-100">Download as XLS</button> */}
                </div>
            </div>
        </div >

    )
}

export default CashBook;