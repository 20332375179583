/* eslint-disable react/destructuring-assignment */
import React from 'react';

const TextInput = (props) => {

  React.useEffect(() => {

    if (props.inputmode === "numeric" && props.commafy) {

      const inputElement = document.getElementById(props.id || props.name);
      console.log(inputElement)
      const handleInputChange = (event) => {
        console.log("in here input here")
        if (!inputElement) return
        const value = event.target.value.replace(/,/g, ''); // Remove existing commas
        const formattedValue = (parseInt(value) || 0).toLocaleString('en-US');
        inputElement.value = formattedValue;
      };

      inputElement.addEventListener('keyup', handleInputChange);

      return () => {
        inputElement.removeEventListener('keyup', handleInputChange);
      };
    }
  }, []);


  return (
    <div className={`${props.className} form-group`}>
      <label htmlFor={props.name}>
        {props.label}&nbsp;
        {props.required && <span style={{ color: '#ff6242' }}>*</span>}
      </label>
      <input
        ref={props.ref}
        type={props.type || 'text'}
        name={props.name}
        id={props.name}
        value={props.value}
        onChange={props.onChange}
        readOnly={props.readOnly}
        onBlur={props.onBlur}
        disabled={props.disabled}
        onKeyPress={props.onKeyPress}
        onKeyDown={props.onKeyDown}
        min={props.minDate}
        max={props.max}
        {...props}
      />
    </div>
  )
};

export default TextInput;
