import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Button, Badge } from 'antd';
import notification from '../../utility/notification';
import { get, patch, put } from '../../utility/fetch';
import useMdaData from '../../utility/useMda';
import { columnsToSearch } from '../../utility/filterColumns'
import searchByColumns from '../../utility/searchByColumns'
import useFilteredData from '../../hooks/useFilteredData';
import SelectInput from '../inputs/SelectInput';
import SearchInput from '../inputs/SearchInput';
import { Pagination } from 'antd';
import TreasuryRequestDetails from './TreasuryRequestDetails';
import { UserInfoContext } from '../../contexts';
import { GiCancel } from 'react-icons/gi';
import { IoIosCheckmark } from 'react-icons/io';
import DocumentModal from '../layouts/DocumentModal';
import ReviewForm from '../inputs/ReviewForm';
import { GrStatusGoodSmall } from 'react-icons/gr';
import { Tooltip } from 'antd';
import { IoIosReturnLeft } from 'react-icons/io';
import PrintModal from '../layouts/PrintModal';
import { getDeptName, timeAgo } from '../../utility/general';



const SEQUENCE_NUMBER = 1
const FilterBadge = ({ isActive, badgeCount, text, onClick }) => {
  return (
    <div className={`m-l-10 pointer  ${isActive ? 'filter_badge_active' : 'filter_badge_inactive'}`}>
      <Badge color="red" count={isActive && badgeCount}>
        <div
          onClick={() => onClick()}
          className={`flex-h-center filter_badge_text flex-v-center`}
        >
          {text}
        </div>
      </Badge>
    </div>
  );
};

const tableHeaders = [
  'TAT',
  'PV Initiator',
  'Initiator MDA',
  "Voucher Type",
  'Date of Creation',
  'Name of Vendor/Organization',
  'Amount',
  'PV Number',
  'Reference Number',
  'Payment Voucher',
  'Schedule Form',
  'Uploaded Document',
  'Payment Cheque',
  'Status',
  'Approvals and Comments',
  'action'
];

const numberWithCommas = (x) => {

  if (String(x).includes(',')) {
    return String(x).replace(/,/g, '')?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  else {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

const statusIndexes = {
  1: 'Draft',
  2: 'Pending',
  3: 'Approved',
  4: 'Rejected'
};

let TEMP_FILTERED_DATA = [];

const ManageAGRequest = ({ history }) => {
  const { currentUser } = useContext(UserInfoContext);
  const [title, setTitle] = useState('');
  const [showPictureModal, setShowPictureModal] = React.useState(false);
  const [activeFilter, setActiveFilter] = useState('Draft');
  let mdas = useMdaData();
  mdas = useFilteredData(mdas, 'name');
  const [modalData, setModalData] = useState({});
  const [documentType, setDocumentType] = useState("");
  const [limit, setLimit] = useState(20);
  const [reviewerComment, setReviewerComment] = useState("");
  const [current, setCurrent] = useState(1);
  const [filteredRequest, setFilteredRequest] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([])
  const [managementRequestData, setManagementRequest] = useState([]);
  const [managementRequestDataFixed, setManagementFixedRequest] = useState([]);
  const [showRequestDetailsModal, setShowRequestDetailsModal] = useState(false);
  const [id, setId] = useState();
  const [showReviewForm, setShowReviewForm] = useState(false);
  // const [filteredRequests, setFilteredRequests] = useState([])
  // const [pageSize, setPageSize] = useState(10)
  const [details, setDetails] = useState({})
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false)
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [paginationLength, setPaginationLength] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [pageSize, setPageSize] = useState(10);



  const paginationChangeHandler = (current, pageSize) => {
    setLimit(pageSize);
    setPageNumber(current);
    setPageSize(pageSize);
  };

  // const paginationChangeHandler = (current, pageSize) => {
  //   setLimit(pageSize);
  //   setCurrent(current);
  // };


  const toggleRequestDetailsModal = () => {
    setShowRequestDetailsModal(!showRequestDetailsModal);
  };

  useEffect(() => {
    if (searchParam === "") {
      getPendingRequests();
    }
    else if (searchParam !== "") {
      querySearch();
    }

  }, [pageSize, pageNumber, searchParam])

  function searchByColumns(query, columns) {
    // return pendingRequests?.filter(item =>
    //   columns.some(column => {
    //     const value = item?.voucher[column];
    //     return value && value.toString().toLowerCase().includes(query.toLowerCase());
    //   })
    // );
  }

  useEffect(() => {
    const statusIndex = Object.entries(statusIndexes).find(
      ([key, value]) => value === activeFilter
    )?.[0];
    const filteredRequest = managementRequestDataFixed.filter(
      (req) => req.treasuryRequestStatus === parseInt(statusIndex, 10)
    );

    setManagementRequest(filteredRequest);
    TEMP_FILTERED_DATA = filteredRequest;
  }, [managementRequestDataFixed, activeFilter]);

  const handleSearch = (e) => {

    setSearchParam(e.target.value);

  }


  const togglePrintModal = () => {
    setShowPrintModal((prev) => !prev)
  }

  const approve = async (data, comment) => {
    setLoading(true);
    const { voucher, schedule } = comment;

    const payload = {
      voucher: {
        id: data?.voucher?.id,
        ...voucher
      },
      schedule: {
        id: data?.schedule?.id,
        ...schedule
      }
    }

    try {
      const res = await put(
        '/Treasury/Form/Approve',
        payload,
        true
      );
      if (res.status === 'success') {
        getPendingRequests();
        notification({
          type: 'success',
          message: "Transaction Aproved Successfully"
        });
        setLoading(false);
      }
      else {
        setLoading(false);
        notification({
          type: 'error',
          message: res?.message
        });
      }
    } catch (error) {
      setLoading(false);
      notification({
        type: 'error',
        message: error?.message
      });
      getPendingRequests();
    }
    setLoading(false);
  };

  const decline = async (data, comment) => {
    const { voucher, schedule } = comment;
    setLoading(true);
    const payload = {
      voucher: {
        id: data?.voucher?.id,
        comment: voucher.comment,
        chequeUrl: []
      },
      schedule: {
        id: data?.schedule?.id,
        comment: schedule.comment
      }
    }


    try {
      const res = await put(
        '/Treasury/Form/Reject',
        payload,
        true
      );
      if (res.status === 'success') {
        notification({
          type: 'success',
          message: "Transaction Declined Successfully"
        });
        getPendingRequests();
        setLoading(false);
      }
      else {
        notification({
          type: 'error',
          message: res.message
        });
        setLoading(false);
      }
    } catch (error) {
      notification({
        type: 'error',
        message: error.message
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const review = async (data, comment) => {
    const { voucher, schedule } = comment;
    setLoading(true);
    const payload = {
      voucher: {
        id: data?.voucher?.id,
        comment: voucher.comment,
        chequeUrl: []
      },
      schedule: {
        id: data?.schedule?.id,
        comment: schedule.comment
      }
    }


    try {
      const res = await put(
        '/Treasury/Form/Review',
        payload,
        true
      );
      if (res.status === 'success') {
        notification({
          type: 'success',
          message: "Voucher Sent Back For Review Successfully"
        });
        getPendingRequests();
        setLoading(false);
      }
      else {
        notification({
          type: 'error',
          message: res.message
        });
        setLoading(false);
      }
    } catch (error) {
      notification({
        type: 'error',
        message: error.message
      });
      setLoading(false);
    }
    setLoading(false);
  };

  // const handlePageClick = (current = 1, pageSize = 10) => {
  //   const startIndex = (current - 1) * pageSize;
  //   const endIndex = startIndex + pageSize;
  //   setFilteredRequest(pendingRequests?.slice(startIndex, endIndex))
  //   console.log(pendingRequests?.slice(startIndex, endIndex))
  //   console.log(startIndex)
  //   console.log(endIndex)
  //   setPageSize(pageSize);
  //   setCurrent(current);
  // };


  const showDocumentModal = (type, data) => {
    setModalData(data);
    setDocumentType(type);
    setShowPictureModal(true);
  }

  const toggleReviewModal = () => {
    setShowReviewForm(!showReviewForm);
  }

  const querySearch = async () => {
    let res = await get(`/Treasury/Form/AG/Paginated?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);
    if (res?.status === "success") {
      setPaginationLength(res?.data?.totalItemCount)
      setPendingRequests(res.data.items)
      setFilteredRequest(res.data.items)
    }
  }

  const getPendingRequests = async () => {
    try {
      const res = await get(`/Treasury/Form/AG/Paginated?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);
      if (res.status === 'success') {
        setPendingRequests(res?.data?.items);
        setFilteredRequest(res?.data?.items);
        setPaginationLength(res?.data?.totalItemCount);
        setActiveFilter('Pending');
      }
    } catch (error) {
      console.log(error);
    }
    // let res = await get(`/GetTreasuryFormFiltered?PageNumber=${8}&PageSize=${10}&query=`);
    //   if(res?.status === "success"){
    //     // setPaginationLength(res?.data?.totalItemCount);
    //     setPendingRequests(res.data.items);
    //     setFilteredRequest(res.data.items);
    //   }
  };




  const getTreatedRequests = async () => {
    try {
      const res = await get(`/Treasury/Form/MyApproved`);
      // const res1 = await get(`/Treasury/Form/Rejected`);
      if (res.status === 'success') {
        setPendingRequests(res?.data);
        setActiveFilter('Approved');
      }
    } catch (error) {
      console.log(error);
    }
  };


  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Manage AG Request';
    setTitle('Manage AG Request');
  };
  useEffect(() => {
    performPageMetaActions();
    getPendingRequests();
  }, []);

  useEffect(() => {
  }, [current, limit]);

  const onInputChange = async (value) => {
    try {
      const statusIndex = Object.entries(statusIndexes).find(
        ([key, value]) => value === activeFilter
      )?.[0];
      if (value === 'All') {
        const mdaFilteredData = managementRequestDataFixed.filter(
          (d) => d.treasuryRequestStatus === parseInt(statusIndex, 10)
        );
        return setManagementRequest(mdaFilteredData);
      }
      const mda = mdas.filter((m) => {
        return m.id === value;
      })[0];

      const mdaFilteredData = managementRequestDataFixed.filter(
        (d) =>
          d.mdaId === mda.id &&
          d.treasuryRequestStatus === parseInt(statusIndex, 10)
      );
      setManagementRequest(mdaFilteredData);
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = (e) => {
    const { value } = e.target;
    const currData = [...TEMP_FILTERED_DATA];
    if (value === '') {
      setManagementRequest(currData);
      return;
    }
    const result = currData.filter((item) =>
      Object.values(item).some((itemValue) =>
        itemValue?.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setManagementRequest(result);
  };

  const closeModal = () => {
    setShowPictureModal(false);
  };

  const filterList = () => {
    return [
      {
        text: 'Pending',
        // badgeCount: pendingRequests?.length,
        isActive: activeFilter === 'Pending',
        onClick: () => getPendingRequests()
      },
      {
        text: 'Treated',
        // badgeCount: pendingRequests?.length,
        isActive: activeFilter === 'Approved',
        onClick: () => getTreatedRequests()
      }
    ];
  };

  const renderData = (header, item) => {
    const specialColumns = ['action', 'status'];
    if (!specialColumns.includes(header.toLowerCase()))
      return <div className="td left-text">{item[header]}</div>;
    if (header.toLowerCase() === 'action') {
      return <button className="table_button">Select</button>;
    }
    if (header.toLowerCase() === 'status') {
      const status = (() => {
        return {
          pending: 'status_pending',
          inactive: 'status_inactive',
          rejected: 'status_rejected'
        }[item[header]];
      })();
      return (
        <div className="td">
          <div className="flex flex-v-center">
            <div className={`status_badge ${status}`}></div> {item[header]}
          </div>
        </div>
      );
    }

    <div className="td left-text">
      {header.toLowerCase() !== 'action' ? (
        item[header]
      ) : (
        <button className="table_button">Select</button>
      )}
    </div>;
  };

  const resolveStatus = (pending, approved, rejected) => {
    if (approved) return 'status_active';
    if (rejected) return 'status_rejected';
    if (pending) return 'status_pending';
    else return 'status_draft';
  };

  const resolveStatusName = (pending, approved, rejected, unassisgned) => {
    if (approved) return 'Approved';
    if (rejected) return 'Rejected';
    if (pending) return 'Pending';
    if (unassisgned) return 'Draft';
    else return 'Draft';
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">{title}</div>
      <div className="w-100 m-t-10 flex  flex-v-center flex-h-end ">
        {/* <SelectInput
          onChange={onInputChange}
          placeholder="Filter Items By"
          options={['All', ...mdas]}
        /> */}



        <SearchInput
          onChange={(e) => { handleSearch(e) }}
          className="w-30"
          placeholder="Search vouchers"
        />
      </div>
      <div className="w-100 m-t-20 p-x-10 p-y-10 bg_green  space-between flex   flex-v-center flex-h-end">
        <div className="bold-text">Request Log</div>
        <div className=" flex   flex-v-center flex-h-end">
          {filterList().map((filter) => (
            <FilterBadge {...filter} />
          ))}
        </div>
      </div>
      <div className="overflow-table">
        <table className="table-view">
          <thead className="">
            <tr className="tr-bordered">
              {tableHeaders.map((th, index) => (
                <th  key={index} className="th th-bordered">
                  <p>{th}</p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-body">
            {filteredRequest?.map((item) => (
              <tr role="presentation" key={item?.voucher?.id} className={item?.voucher?.isApproved === true ? 'tr-green' : ''}>
                <td  className="td p-r td-bordered">
                  <div className='w-100 flex flex-col flex-h-center flex-v-center'>
                    <div style={{ width: "16px", height: "16px" }} className={`  ${item?.voucher?.over24Hrs === false ? 'bg-bg-green' : (item?.voucher?.over24Hrs === true && item?.voucher?.over48Hrs === false) ? 'bg-bg-amber' : (item?.voucher?.over24Hrs === true && item?.voucher?.over48Hrs === true) ? 'bg-bg-red' : 'bg-gray'}`}></div>
                    <div className='no-wrap m-t-10'>{timeAgo(new Date(item?.voucher?.dateModified))}</div>
                  </div>
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.createdByName}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.mda}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.voucherType}
                </td>
                <td className="td p-r td-bordered">
                  {new Date(item?.voucher?.dateCreated).toUTCString()}
                </td>

                <td className="td p-r td-bordered">
                  {item?.voucher?.drTo}
                </td>
                <td className="td p-r td-bordered">
                  {`₦${numberWithCommas(Number(parseFloat(item?.voucher?.amount) + item?.voucher?.subVouchers?.reduce(
                    (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                  )).toFixed(2))}`}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.pvNo}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.referenceNumber}
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("voucher", item) }}>View Payment Voucher</a>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("schedule", item) }}>View Schedule Form</a>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("memo", item) }}>View Approved Memo</a>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("paymentCheque", item) }}>View Payment Cheque</a>
                </td>
                <td className="td p-r td-bordered" width={"17%"}>
                  <div >
                    <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${item?.voucher?.statusDetails === 'Pending' ? 'bg-amber' : item?.voucher?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : item?.voucher?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
                    <div className='float-right'>{item?.voucher?.statusDetails === 'Pending' ? `Pending With ${getDeptName(item?.voucher?.journeyStateId)}` : item?.voucher?.statusDetails === 'ApprovalCompleted' ? 'Paid' : item?.voucher?.statusDetails === 'Rejected' ? 'Declined' : item?.voucher?.statusDetails}</div>
                  </div>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("comment", item) }}>View Approvals and Comments</a>
                </td>
                {activeFilter !== 'Approved' ? <td className="td p-r td-bordered">
                  {<div classname="flex">
                    <Tooltip title="Approve"><IoIosCheckmark size={35} color="green" className="pointer" onClick={() => { setDetails(item); setShowReviewForm(true); setType("approve"); setIsApproved(item?.voucher?.isApproved) }} /></Tooltip>
                    {item?.voucher?.isApproved !== true && <Tooltip title="Send Back for Review"><IoIosReturnLeft size={30} color="grey" className="pointer" onClick={() => { setDetails(item); setShowReviewForm(true); setType("review"); setIsApproved(item?.voucher?.isApproved) }} /></Tooltip>}
                  </div>}
                </td>
                  :
                  <td className="td p-r td-bordered">
                    <p >Treated</p>
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Pagination
          showSizeChanger
          pageSize={pageSize}
          onShowSizeChange={paginationChangeHandler}
          current={pageNumber}
          total={paginationLength}
          // showTotal={true}
          onChange={paginationChangeHandler}
          // pageSizeOptions={pageSizeOptions}
          responsive
        />
      </div>

      {showRequestDetailsModal && (
        <TreasuryRequestDetails
          sequenceNumber={SEQUENCE_NUMBER}
          updateReviewerComment={value => setReviewerComment(value)}
          closeModal={toggleRequestDetailsModal}
          id={id}
        />
      )}

      {
        showPictureModal && <DocumentModal closeModal={closeModal} paymentVoucher={modalData.voucher} schedule={modalData.schedule} type={documentType} />
      }

      {showReviewForm && <ReviewForm closeModal={toggleReviewModal} approve={approve} review={review} decline={decline} details={details} type={type} loading={loading} ag={'ag'} isApproved={isApproved} />}

      {
        showPrintModal && <PrintModal closeModal={togglePrintModal} />
      }
    </div>
  );
};

export default ManageAGRequest;
