import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Button, Badge } from 'antd';
import notification from '../../utility/notification';
import { get, patch, put } from '../../utility/fetch';
import useMdaData from '../../utility/useMda';
import useFilteredData from '../../hooks/useFilteredData';
import SelectInput from '../inputs/SelectInput';
import SearchInput from '../inputs/SearchInput';
import { columnsToSearch } from '../../utility/filterColumns'
import searchByColumns from '../../utility/searchByColumns'
import { Pagination } from 'antd';
import TreasuryRequestDetails from './TreasuryRequestDetails';
import { UserInfoContext } from '../../contexts';
import { GiCancel } from 'react-icons/gi';
import { IoIosCheckmark } from 'react-icons/io';
import DocumentModal from '../layouts/DocumentModal';
import ReviewForm from '../inputs/ReviewForm';
import { GrStatusGoodSmall } from 'react-icons/gr';
import CashBookModal from '../layouts/CashBookModal';
import IspoModal from '../layouts/IspoModal';
import { Tooltip } from 'antd';
import { IoIosReturnLeft } from 'react-icons/io';
import { getDeptName, timeAgo } from '../../utility/general';




const SEQUENCE_NUMBER = 1
const FilterBadge = ({ isActive, badgeCount, text, onClick }) => {
  return (
    <div className={`m-l-10 pointer  ${isActive ? 'filter_badge_active' : 'filter_badge_inactive'}`}>
      <Badge color="red" count={badgeCount}>
        <div
          onClick={() => onClick()}
          className={`flex-h-center filter_badge_text flex-v-center`}
        >
          {text}
        </div>
      </Badge>
    </div>
  );
};



const numberWithCommas = (x) => {

  if (String(x).includes(',')) {
    return String(x).replace(/,/g, '')?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  else {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  }

}




const tableHeaders = [
  'TAT',
  'PV Initiator',
  'Initiator MDA',
  'Date of Creation',
  'Name of Vendor/Organization',
  'Amount',
  'PV Number',
  'Reference Number',
  'Payment Voucher',
  'Schedule Form',
  'Uploaded Document',
  'Payment Cheque',
  'Status',
  'Approvals and Comments',
  'action'
];


const ispoHeaders = [
  'TAT',
  'PV Initiator',
  'Initiator MDA',
  'Date of Creation',
  'Name of Vendor/Organization',
  'Amount',
  'PV Number',
  'Reference Number',
];

const statusIndexes = {
  1: 'Draft',
  2: 'Pending',
  3: 'Approved',
  4: 'Rejected'
};

let TEMP_FILTERED_DATA = [];

const ManageManagementRequest = ({ history }) => {
  const { currentUser } = useContext(UserInfoContext);
  const [title, setTitle] = useState('');
  const [showPictureModal, setShowPictureModal] = React.useState(false);
  const [activeFilter, setActiveFilter] = useState('Draft');
  let mdas = useMdaData();
  mdas = useFilteredData(mdas, 'name');
  const [modalData, setModalData] = useState({});
  const [documentType, setDocumentType] = useState("");
  const [limit, setLimit] = useState(20);
  const [reviewerComment, setReviewerComment] = useState("");
  const [current, setCurrent] = useState(1);
  const [pendingRequests, setPendingRequests] = useState([])
  const [filteredRequest, setFilteredRequest] = useState([]);
  const [managementRequestData, setManagementRequest] = useState([]);
  const [managementRequestDataFixed, setManagementFixedRequest] = useState([]);
  const [showRequestDetailsModal, setShowRequestDetailsModal] = useState(false);
  const [id, setId] = useState();
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [details, setDetails] = useState({})
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCashBookModal, setShowCashBookModal] = useState(false);
  const [filteredRequests, setFilteredRequests] = useState([])
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchParam, setSearchParam] = useState("");
  const [paginationLength, setPaginationLength] = useState(0)
  const [activeISPO, setActiveISPO] = useState(false);
  const [ispo, setIspo] = useState([])
  const [showISPO, setShowISPO] = useState(false);
  const [ispoData, setIspoData] = useState(false);


  const paginationChangeHandler = (current, pageSize) => {
    setLimit(pageSize);
    setPageNumber(current);
    setPageSize(pageSize);
  };

  const toggleRequestDetailsModal = () => {
    setShowRequestDetailsModal(!showRequestDetailsModal);
  };


  useEffect(() => {
    const statusIndex = Object.entries(statusIndexes).find(
      ([key, value]) => value === activeFilter
    )?.[0];
    const filteredRequest = managementRequestDataFixed.filter(
      (req) => req.treasuryRequestStatus === parseInt(statusIndex, 10)
    );

    setManagementRequest(filteredRequest);
    TEMP_FILTERED_DATA = filteredRequest;
  }, [managementRequestDataFixed, activeFilter]);

  const toggleReviewModal = () => {
    setShowReviewForm(!showReviewForm);
  }

  const approve = async (data, comment) => {
    setLoading(true);
    const { voucher, schedule } = comment;

    const payload = {
      voucher: {
        id: data?.voucher?.id,
        ...voucher
      },
      schedule: {
        id: data?.schedule?.id,
        ...schedule
      }
    }

    try {
      const res = await put(
        '/Treasury/Form/Approve',
        payload,
        true
      );
      if (res.status === 'success') {
        getPendingRequests();
        notification({
          type: 'success',
          message: "Transaction Approved Successfully"
        });
        setLoading(false);
      }
      else {
        notification({
          type: 'error',
          message: res.message
        });
        getPendingRequests()
        setLoading(false);
      }
    } catch (error) {
      getPendingRequests();
      setLoading(false);
    }
    setLoading(false);
  };

  function searchByColumns(query, columns) {
    return pendingRequests?.filter(item =>
      columns.some(column => {
        const value = item?.voucher[column];
        return value && value.toString().toLowerCase().includes(query.toLowerCase());
      })
    );
  }

  const decline = async (data, comment) => {
    const { voucher, schedule } = comment;
    setLoading(true);
    const payload = {
      voucher: {
        id: data?.voucher?.id,
        comment: voucher.comment,
        chequeUrl: voucher?.chequeUrl
      },
      schedule: {
        id: data?.schedule?.id,
        comment: schedule.comment
      }
    }


    try {
      const res = await put(
        '/Treasury/Form/Reject',
        payload,
        true
      );
      if (res.status === 'success') {
        notification({
          type: 'success',
          message: "Transaction Declined Successfully"
        });
        getPendingRequests();
        setLoading(false);
      }
      else {
        notification({
          type: 'error',
          message: res.message
        });
        getPendingRequests()
        setLoading(false);
      }
    } catch (error) {
      notification({
        type: 'error',
        message: error.message
      });
      getPendingRequests()
      setLoading(false);
    }
    setLoading(false);
  };

  const closeModal = () => {
    setShowPictureModal(false);
  };


  const getISPO = async () => {
    let res = await get(`/Treasury/Form/ISPO/Paginated?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);

    if (res?.status === "success") {
      setIspo(res?.data?.items);
    }
  }

  const handleISPO = () => {
    setActiveISPO(!activeISPO);
  }

  const handleShowISPO = (data) => {
    setIspoData(data);
    setShowISPO(true)
  }

  const closeIspoModal = () =>{
    setShowISPO(false);
  }




  const showDocumentModal = (type, data) => {
    setModalData(data);
    setDocumentType(type);
    setShowPictureModal(true);
  }


  const handleSearch = (e) => {

    setSearchParam(e.target.value);

  }



  const getPendingRequests = async () => {
    try {
      const res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);
      if (res.status === 'success') {
        setPendingRequests(res?.data?.items)
        setFilteredRequest(res?.data?.items);
        setActiveFilter('Pending');
        setPaginationLength(res?.data?.totalItemCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTreatedRequests = async () => {
    try {
      const res = await get(`/Treasury/Form/MyApproved`);
      if (res.status === 'success') {
        setPendingRequests(res)
        setFilteredRequest(res?.data)
        setActiveFilter('Approved');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchParam === "") {
      getPendingRequests();
      getISPO();
    }
    else if (searchParam !== "") {
      querySearch();
    }

  }, [pageSize, pageNumber, searchParam])

  const querySearch = async () => {
    let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);
    if (res?.status === "success") {
      setPaginationLength(res?.data?.totalItemCount)
      setPendingRequests(res.data.items)
      setFilteredRequest(res.data.items)
    }
  }

  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Manage MAC Request';
    setTitle('Manage MAC Request');
  };

  useEffect(() => {
    performPageMetaActions();
    getPendingRequests();
    getISPO();
  }, []);

  useEffect(() => {
  }, [current, limit]);




  const filterList = useMemo(() => {
    return [
      // {
      //   text: 'Draft',
      //   badgeCount: managementRequestDataFixed.filter(
      //     (d) => d.treasuryRequestStatus === 1
      //   ).length,
      //   isActive: activeFilter === 'Draft',
      //   onClick: () => setActiveFilter('Draft')
      // },
      {
        text: 'Pending',
        badgeCount: managementRequestDataFixed.filter(
          (d) => d.treasuryRequestStatus === 2
        ).length,
        isActive: activeFilter === 'Pending',
        onClick: () => getPendingRequests()
      },
      {
        text: 'Treated',
        badgeCount: managementRequestDataFixed.filter(
          (d) => d.treasuryRequestStatus === 3
        ).length,
        isActive: activeFilter === 'Approved',
        onClick: () => getTreatedRequests()
      }
    ];
  }, [activeFilter, managementRequestDataFixed]);




  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">{title}</div>
      <div className="w-100 m-t-10 flex  flex-v-center flex-h-end">

        <SearchInput
          onChange={(e) => { handleSearch(e) }}
          className="w-30"
          placeholder="Search vouchers"
        />
      </div>
      <div className="w-100 m-t-20 p-x-10 p-y-10 bg_green  space-between flex   flex-v-center flex-h-end">
        <div className="bold-text">Request Log</div>
       <Badge count={ispo?.length > 10 ? "10+" : ispo?.length}><div onClick={handleISPO} className={`rounded-sm pd-7 ${activeISPO ? "rb-active" : ""}`}>ISPO Vouchers</div></Badge> 

        <div className=" flex  flex-v-center flex-h-end">
          {filterList.map((filter) => (
            <FilterBadge {...filter} />
          ))}
        </div>
      </div>
      <div className="overflow-table">
        <table className="table-view">
          <thead className="">
            <tr className="tr-bordered">
              {!activeISPO ? tableHeaders.map((th, index) => (
                <th key={index} className="th th-bordered">
                  <p>{th}</p>
                </th>
              )) : ispoHeaders.map((th, index) => (
                <th key={index} className="th th-bordered">
                  <p>{th}</p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-body">
            {!activeISPO ?
              filteredRequest?.map((item) => (
                <tr role="presentation">
                  <td className="td p-r td-bordered">
                    <div className='w-100 flex flex-col flex-h-center flex-v-center'>
                      <div style={{ width: "16px", height: "16px" }} className={`m-r-10 float-left ${item?.voucher?.over24Hrs === false ? 'bg-bg-green' : (item?.voucher?.over24Hrs === true && item?.voucher?.over48Hrs === false) ? 'bg-bg-amber' : (item?.voucher?.over24Hrs === true && item?.voucher?.over48Hrs === true) ? 'bg-bg-red' : 'bg-gray'}`}></div>
                      <div className='no-wrap m-t-10'>{timeAgo(new Date(item?.voucher?.dateModified))}</div>
                    </div>
                  </td>
                  <td className="td p-r td-bordered">
                    {item?.voucher?.createdByName}
                  </td>
                  <td className="td p-r td-bordered">
                    {item?.voucher?.mda}
                  </td>
                  <td className="td p-r td-bordered">
                    {new Date(item?.voucher?.dateCreated).toUTCString()}
                  </td>
                  <td className="td p-r td-bordered">
                    {item?.voucher?.drTo}
                  </td>
                  <td className="td p-r td-bordered">
                    {`₦${numberWithCommas(Number(parseFloat(item?.voucher?.amount) + item?.voucher?.subVouchers?.reduce(
                      (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                    )).toFixed(2))}`}
                  </td>
                  <td className="td p-r td-bordered">
                    {item?.voucher?.pvNo}
                  </td>
                  <td className="td p-r td-bordered">
                    {item?.voucher?.referenceNumber}
                  </td>
                  <td className="td p-r td-bordered">
                    <a onClick={() => { showDocumentModal("voucher", item) }}>View Payment Voucher</a>
                  </td>
                  <td className="td p-r td-bordered">
                    <a onClick={() => { showDocumentModal("schedule", item) }}>View Schedule Form</a>
                  </td>
                  <td className="td p-r td-bordered">
                    <a onClick={() => { showDocumentModal("memo", item) }}>View Approved Memo </a>
                  </td>
                  <td className="td p-r td-bordered">
                    <a onClick={() => { showDocumentModal("paymentCheque", item) }}>View Payment Cheque</a>
                  </td>
                  <td className="td p-r td-bordered" width={"17%"}>
                    <div >
                      <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${item?.voucher?.statusDetails === 'Pending' ? 'bg-amber' : item?.voucher?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : item?.voucher?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
                      <div className='float-right'>{item?.voucher?.statusDetails === 'Pending' ? `Pending With ${getDeptName(item?.voucher?.journeyStateId)}` : item?.voucher?.statusDetails === 'ApprovalCompleted' ? 'Paid' : item?.voucher?.statusDetails === 'Rejected' ? 'Declined' : item?.voucher?.statusDetails}</div>
                    </div>
                  </td>
                  <td className="td p-r td-bordered">
                    <a onClick={() => { showDocumentModal("comment", item) }}>View Approvals and Comments</a>
                  </td>
                  {activeFilter !== 'Approved' ? <td className="td p-r td-bordered">
                    <div classname="flex">
                      {/* <GiCancel size={30} color="red" className="m-r-10 pointer" onClick={() => { setDetails(item); setShowReviewForm(true); setType("decline") }} /> */}
                      <IoIosCheckmark size={30} color="green" className="pointer" onClick={() => { setDetails(item); setShowReviewForm(true); setType("MGT") }} />
                    </div>
                  </td>
                    :
                    <td className="td p-r td-bordered">
                      <p>Treated</p>
                    </td>

                  }
                </tr>
              ))
              :
              ispo?.map((item) => (
                <tr onClick={()=>handleShowISPO(item?.vouchers)} className='row-hover pointer' role="presentation">
                  <td className="td p-r td-bordered">
                    <div className='w-100 flex flex-col flex-h-center flex-v-center'>
                      <div style={{ width: "16px", height: "16px" }} className={`m-r-10 float-left ${item?.over24Hrs === false ? 'bg-bg-green' : (item?.over24Hrs === true && item?.over48Hrs === false) ? 'bg-bg-amber' : (item?.over24Hrs === true && item?.over48Hrs === true) ? 'bg-bg-red' : 'bg-gray'}`}></div>
                      <div className='no-wrap m-t-10'>{timeAgo(new Date(item?.dateModified))}</div>
                    </div>
                  </td>
                  <td className="td p-r td-bordered">
                    {item?.createdByName}
                  </td>
                  <td className="td p-r td-bordered">
                    {item?.mda}
                  </td>
                  <td className="td p-r td-bordered">
                    {new Date(item?.dateCreated).toUTCString()}
                  </td>
                  <td className="td p-r td-bordered">
                    {item?.drTo}
                  </td>
                  <td className="td p-r td-bordered">
                    {!activeISPO ? `₦${numberWithCommas(Number(parseFloat(item?.amount) + item?.subVouchers?.reduce(
                      (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                    )).toFixed(2))}` : `₦${numberWithCommas(Number(parseFloat(item?.amount)))}`}
                  </td>
                  <td className="td p-r td-bordered">
                    {item?.pvNo}
                  </td>
                  <td className="td p-r td-bordered">
                    {item?.referenceNumber}
                  </td>

                  {/* <td className="td p-r td-bordered" width={"17%"}>
                    <div >
                      <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${item?.statusDetails === 'Pending' ? 'bg-amber' : item?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : item?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
                      <div className='float-right'>{item?.statusDetails === 'Pending' ? `Pending With ${getDeptName(item?.journeyStateId)}` : item?.statusDetails === 'ApprovalCompleted' ? 'Paid' : item?.statusDetails === 'Rejected' ? 'Declined' : item?.statusDetails}</div>
                    </div>
                  </td> */}

                </tr>
              ))
            }
          </tbody>
        </table>
        {activeFilter === 'Approved' && <button className="btn btn-large w-100 m-t-20" onClick={() => setShowCashBookModal(!showCashBookModal)}>Generate Cashbook</button>}
      </div>
      <div style={{ marginTop: '20px' }}>
        <Pagination
          showSizeChanger
          pageSize={pageSize}
          onShowSizeChange={paginationChangeHandler}
          current={pageNumber}
          total={paginationLength}
          // showTotal={true}
          onChange={paginationChangeHandler}
          // pageSizeOptions={pageSizeOptions}
          responsive
        />
      </div>

      {showRequestDetailsModal && (
        <TreasuryRequestDetails
          sequenceNumber={SEQUENCE_NUMBER}
          updateReviewerComment={value => setReviewerComment(value)}
          closeModal={toggleRequestDetailsModal}
          id={id}
        />
      )}

      {
        showCashBookModal && <CashBookModal paymentVoucher={pendingRequests?.data} closeModal={() => setShowCashBookModal(!showCashBookModal)} office="MAC" />
      }

      {
        showPictureModal && <DocumentModal closeModal={closeModal} paymentVoucher={modalData.voucher} schedule={modalData.schedule} type={documentType} />
      }

      {showReviewForm && <ReviewForm closeModal={toggleReviewModal} approve={approve} decline={decline} details={details} type={type} loading={loading} />}

      {
        showISPO && <IspoModal closeModal={closeIspoModal} data = {ispoData} />
      }

    </div>
  );
};

export default ManageManagementRequest;
