import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Button } from 'antd';
import { Pagination } from 'antd';
import SelectInput from '../../inputs/SelectInput';
import { IoMdClose } from 'react-icons/io';
import TextareaInput from '../../inputs/TextareaInput';
import TextInput from '../../inputs/TextInput';
import DatePicker from '../../inputs/DatePicker';
import { Link } from 'react-router-dom';
import SearchInput from '../../inputs/SearchInput';
import { get, patch } from '../../../utility/fetch';
import useMdaData from '../../../utility/useMda';
import notification from '../../../utility/notification';
import PageCards from '../../layouts/PageCards';
import FilterBox from '../../layouts/FilterBox';
import moment from 'moment';
import Modal from '../../layouts/modal';

const ColumnHeaders = [
  'Memo/Payment Title',
  'Approved Date',
  'Amount',
  'Owner',
  'Remark',
  'Action'
];

let onboardedStaffMemo;

const previousTransactionsTableHeaders = [
  'Payment ID#',
  'Amount',
  'Request Initiator',
  'Approval Date',
  'Payment Date'
];

const SEQUENCE_NUMBER = 4

const FilterBadge = ({ isActive, badgeCount, text, onClick }) => {
  return (
    <div className={`m-l-10 pointer  ${isActive ? 'filter_badge_active': 'filter_badge_inactive'}`}>
      <Badge color="red" count={badgeCount}>
        <div
          onClick={() => onClick()}
          className={`flex-h-center filter_badge_text flex-v-center`}
        >
          {text}
        </div>
      </Badge>
    </div>
  );
};

const Mda = ({ history }) => {
  const [title, setTitle] = useState('');
  const [requestLoading, setRequestLoading] = useState(false);
  const [previousTransactions, setPrevioustRansaction] = useState([]);
  const [requestId, setRequestId] = useState();
  const [paymentAdviceInformation, setPaymentAdviceInformation] = useState({
    id: 0,
    bank: '',
    accountNo: '',
    expectedPaymentDate: '',
    agComment: '',
    commisionersComment: ''
  });
  const [workflowPerItem, setWorkFlowPerItem] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showItem, setShowItem] = useState(null);
  const [paymentPools, setPaymentPool] = useState([]);
  const [limit, setLimit] = useState(20);
  const [current, setCurrent] = useState(1);
  const [workflowData, setWorkflowData] = useState([]);
  const [activeFilter, setActiveFilter] = useState('Pending');
  let mdas = useMdaData();
  const [requestPaymentItem, setrequestPaymentItem] = useState([]);
  const [requestFixed, setFixedRequest] = useState([]);


  useEffect(() => {
    const filteredRequest = requestFixed.filter((req) =>
      activeFilter === 'Pending' ? !req.approverId : req.approverId
    );
    setrequestPaymentItem(filteredRequest);
  }, [requestFixed, activeFilter]);

  const getWorkflow = async (id) => {
    try {
      const { status, data } = await get(
        `/GetTreasuryRequestWorkflow/Details/${id}`
      );
      if (status === 'success') {
        setWorkflowData(data);
        setPrevioustRansaction(data.paymentResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postPaymentAdvice = async () => {
    try {
      const { id } = paymentAdviceInformation;
      const paymentPoolId = paymentPools.filter(
        (pool) => pool.paymentId === id
      )[0]?.id;
      setRequestLoading(true);

      if (!paymentPoolId) {
        notification({
          title: `This item has no payment pool`,
          message: 'This Item has no payment pool',
          type: 'error'
        });
        return;
      }
      const { status, data } = await patch('/CreateTreasuryPaymentAdvice', {
        ...paymentAdviceInformation,
        id: paymentPoolId
      });
      if (status === 'success') {
        setShowModal(false)
        setShowItem(null)
        getrequestPaymentPool()
        notification({
          title: `Payment Advice Created`,
          message: 'Payment Advice Created Successfully',
          type: 'success'
        });
      }
    } catch (error) {
      notification({
        title: `Failed to Create Payment Advice`,
        message: "Couldn't Create Payment Advice",
        type: 'error'
      });
    } finally {
      setRequestLoading(false);
    }
  };

  const getPaymentDetails = async (id) => {
    try {
      const { status, data } = await get(
        `/GetTreasuryPaymentPool/Payment/${id}`
      );
      if (status === 'success') {
        setWorkFlowPerItem(data.requestWorkflowResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getrequestPaymentItem = async () => {
    try {
      const { status, data } = await get(
        '/GetTreasuryPaymentFiltered',
        {
          PageSize: limit,
          PageNumber: current,
          TreasurySequenceStateId: SEQUENCE_NUMBER,
          addedToPaymentPool: 1
        },
        true
      );
      if (status === 'success') {
        setFixedRequest(data?.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getrequestPaymentPool = async () => {
    try {
      const { status, data } = await get(
        '/GetTreasuryPaymentPoolFiltered',
        {
          PageSize: limit,
          PageNumber: current,
          TreasurySequenceStateId: SEQUENCE_NUMBER
        },
        true
      );
      if (status === 'success') {
        setPaymentPool(data.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setLimit(pageSize);
    setCurrent(current);
  };

  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    getrequestPaymentItem();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getrequestPaymentItem();
  }, [current, limit]);

  useEffect(() => {
    getrequestPaymentPool();
    performPageMetaActions();
  }, []);

  const updateRequestData = async (requestId, statusId) => {
    try {
      const { status, data } = await patch(
        '/UpdateTreasuryRequstStatus',
        {
          treasuryRequestId: requestId,
          treasuryRequestStatus: statusId,
          statusChangeLog: 'string'
        },
        true
      );
      if (status === 'success') {
        // fetchRequestData();
        // updateWorkFlowData(requestId, statusId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onInputChange = async (value) => {
    try {
      if (value === 'All') return setrequestPaymentItem(requestFixed);
      const mda = mdas.filter((m) => {
        return m.id === value;
      })[0];
      const mdaFilteredData = requestFixed.filter(
        (d) => d?.treasuryRequestObject?.mdaId === mda.id
      );
      setrequestPaymentItem(mdaFilteredData);
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = (e) => {
    const { value } = e.target;
    const currData = [...requestFixed];
    if (value === '') {
      setrequestPaymentItem(currData);
      return;
    }
    const result = currData.filter((item) =>
      Object.values(item).some((itemValue) =>
        itemValue?.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setrequestPaymentItem(result);
  };

  const filterList = useMemo(() => {
    return [
      {
        text: 'Pending',
        badgeCount: requestFixed?.filter((item) => !item.approverId)
          .length,
        isActive: activeFilter === 'Pending',
        onClick: () => setActiveFilter('Pending')
      },
      {
        text: 'Approved',
        badgeCount: requestFixed?.filter((item) => item.approverId)
          .length,
        isActive: activeFilter === 'Approved',
        onClick: () => setActiveFilter('Approved')
      }
    ];
  }, [activeFilter, requestFixed]);

  const renderList = () => {
    return (
      <div className="col-9 flex flex-col">
        <div className="w-100 m-t-10 flex  flex-v-center flex-h-end">
          <SelectInput
            onChange={onInputChange}
            placeholder="Filter Items By"
            options={['All', ...mdas]}
          />
          <SearchInput
            className="m-l-10 h-35"
            onChange={onSearch}
            placeholder="Search transaction, invoice"
          />
        </div>
        <div className="w-100 m-t-20 p-x-10 p-y-10 bg_green  space-between flex   flex-v-center flex-h-end">
          <div className="bold-text">Payment Manager</div>
          <div className=" flex   flex-v-center flex-h-end">
            {filterList.map((filter) => (
              <FilterBadge {...filter} />
            ))}
          </div>
        </div>
        <div className="table-view">
          <div className="table-header">
            <div className="table-row bg-grey">
              {ColumnHeaders.map((header) => (
                <div className="th th-bordered ">
                  <p className="">{header}</p>
                </div>
              ))}
            </div>
          </div>
          <tbody className="table-body">
            {requestPaymentItem.map((item) => (
              <tr role="presentation">
                <td className="td p-r td-bordered">
                  <Link
                    to={`/treasuryops/treasury_request/${item.treasuryRequestId}?sequence_number=${SEQUENCE_NUMBER}`}
                  >
                    <span style={{ cursor: 'pointer' }} className="underline">
                      {item.name}
                    </span>
                  </Link>
                </td>
                <td className="td p-r td-bordered">
                  {item.treasuryRequestObject
                    ? moment(item?.treasuryRequestObject?.lastUpdated).format(
                        'MMM Do YY'
                      )
                    : '-'}
                </td>
                <td className="td p-r td-bordered">
                  <div
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => {
                      setShowModal(true);
                      getWorkflow(item.treasuryRequestId);
                    }}
                  >
                    {item.amount
                      ? (item.amount * item.quantity)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : '-'}{' '}
                  </div>
                </td>
                <td className="td p-r td-bordered">
                  {item?.treasuryRequestObject?.mda || '-'}
                </td>
                <td className="td p-r td-bordered">
                  {item?.treasuryRequestObject?.requestDetails || '-'}
                </td>
                {/* <td className="td p-r td-bordered">{item.remarks || '-'}</td> */}
                <td className="td flex p-r td-bordered">
                  <button
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setShowItem(item);
                      let pool = paymentPools.filter(
                        (pool) => pool.paymentId === item.id
                      )[0];
                      if (!pool) pool = {};
                      setPaymentAdviceInformation(() => ({
                        bank: '',
                        accountNo: '',
                        expectedPaymentDate: '',
                        agComment: '',
                        ...pool,
                        id: item.id
                      }));
                      getPaymentDetails(item.id);
                      getWorkflow(item.treasuryRequestId);
                    }}
                    className="table_button m-r-10"
                  >
                    Advice Payment
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          <div style={{ marginTop: '20px' }}>
            <Pagination
              showSizeChanger
              pageSize={limit}
              onShowSizeChange={paginationChangeHandler}
              current={current}
              total={330}
              // showTotal={true}
              onChange={paginationChangeHandler}
              // pageSizeOptions={pageSizeOptions}
              responsive
            />
          </div>
        </div>
      </div>
    );
  };

  const getMdaName = (id) => {
    if (!id) return;
    const d = mdas.find((mda) => mda.id === id);
    if (d) return d?.name;
  };

  return (
    <div>
      <div className="w-100">
        <div className="w-100 flex space-between flex-v-center">{title}</div>

        <div className="w-100 flex">
          <div className="flex-col col-3 m-r-10 m-t-20">
            <div className="flex ">
              <PageCards
                cardTitle=" Payment Items"
                count={requestFixed.length}
                // onClick={() => }
                isActive={false}
              />
            </div>
            <FilterBox
              className="m-t-10"
              title={'Search for a payment'}
              type="search"
              placeholder="enter search text"
            />
            <FilterBox
              className={'filter_box_border_b'}
              title={'Budget Item'}
              type="select"
              options={['Option One']}
              placeholder="enter search text"
            />
          </div>
          {renderList()}
        </div>
      </div>
      <Modal onClose={() => setShowModal(false)} visible={showModal}>
        <div className="w-100 flex flex-col">
          <IoMdClose
            style={{ marginTop: '50px' }}
            className="close-btn pointer"
            onClick={() => setShowModal(false)}
          />
          <div
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              backgroundColor: 'white'
              // padding: '20px'
            }}
          >
            <div className="w-100  p-x-10  bg_green  space-between flex   flex-v-center flex-h-end accordion">
              <div className="bold-text">Approval #ID: {requestId}</div>
            </div>
            <table className="table-view">
              <thead className="">
                <tr className="tr-bordered">
                  {previousTransactionsTableHeaders.map((th, index) => (
                    <th key={index} className="th th-bordered">
                      <p>{th}</p>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="table-body">
                {previousTransactions.map((item) => (
                  <tr role="presentation">
                    <td className="td p-r td-bordered">{item.paymentId}</td>
                    <td className="td p-r td-bordered">{item.amount}</td>
                    <td className="td p-r td-bordered">
                      {item.beneficiary}
                    </td>
                    <td className="td p-r td-bordered">
                      {item.dateModified &&
                        moment(item.dateModified).format('MMM Do YY')}
                    </td>
                    <td className="td p-r td-bordered">
                      {item.paymentDate &&
                        moment(item.paymentDate).format('MMM Do YY')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      <Modal onClose={() => setShowItem(false)} visible={showItem}>
        <div className="w-100 flex flex-col">
          <IoMdClose
            style={{ marginTop: '50px' }}
            className="close-btn pointer"
            onClick={() => setShowItem(false)}
          />
          <div
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              backgroundColor: 'white'
              // padding: '20px'
            }}
          >
            <div className="w-100 bg_green  space-between flex   flex-v-center flex-h-end accordion">
              <div className="bold-text">Approval #ID: {requestId}</div>
            </div>
            <div className="w-100 m-t-10 flex">
              <TextInput
                className="w-100  m-b-10"
                name="requestTitle"
                label="Item Name"
                value={showItem?.name}
                // onChange={handleChange}
              />
              <TextInput
                className="w-100 m-b-10"
                name="requestTitle"
                label="Item Amount"
                value={showItem?.amount}
                // value={requestData.requestTitle}
                // onChange={handleChange}
              />
            </div>
            <div className="w-100 m-t-10 flex">
              <TextInput
                className="w-100 m-b-10"
                name="requestTitle"
                label="Beneficiary"
                disabled
                value={getMdaName(workflowPerItem?.mdaId)}
                // value={requestData.requestTitle}
                // onChange={handleChange}
              />
              <DatePicker
                className="w-100  m-b-10"
                name="paymentDate"
                label="Payment Date"
                value={
                  paymentAdviceInformation.expectedPaymentDate &&
                  moment(paymentAdviceInformation.expectedPaymentDate)
                }
                onChange={(d, dateString) => {
                  setPaymentAdviceInformation({
                    ...paymentAdviceInformation,
                    expectedPaymentDate: moment(d).toISOString()
                  });
                }}
              />
            </div>
            {/* <div className="w-100 m-t-10 flex">
            <TextInput
              className="w-100  m-b-10"
              name="bankName"
              label="Enter Bank Name"
              onChange={(e) =>
                setPaymentAdviceInformation({
                  ...paymentAdviceInformation,
                  bank: e.target.value
                })
              }
              value={paymentAdviceInformation.bank}

              // value={requestData.requestTitle}
              // onChange={handleChange}
            />
            <TextInput
              className="w-100  m-b-10"
              name="accountNo"
              onChange={(e) =>
                setPaymentAdviceInformation({
                  ...paymentAdviceInformation,
                  accountNo: e.target.value
                })
              }
              value={paymentAdviceInformation.accountNo}
              label="Enter Account Number"
              // value={requestData.requestTitle}
              // onChange={handleChange}
            />
          </div> */}
            <div className="w-100 m-t-10 flex">
              <TextareaInput
                className="w-100  m-b-10"
                name="comment"
                disabled
                label="Requestor Comment"
                value={
                  paymentAdviceInformation.requestorsComment ||
                  paymentAdviceInformation.agComment
                }
                onChange={(e) =>
                  setPaymentAdviceInformation({
                    ...paymentAdviceInformation,
                    agComment: e.target.value
                  })
                }
                // value={requestData.requestTitle}
                // onChange={handleChange}
              />
              <TextareaInput
                className="w-100  m-b-10"
                name="comment"
                label="Commissioner's Comment"
                value={paymentAdviceInformation.commisionersComment}
                onChange={(e) =>
                  setPaymentAdviceInformation({
                    ...paymentAdviceInformation,
                    commisionersComment: e.target.value
                  })
                }
                // value={requestData.requestTitle}
                // onChange={handleChange}
              />
            </div>
            <div className="w-100 m-t-10 flex">
              <Button
                loading={requestLoading}
                onClick={() => postPaymentAdvice()}
                style={{paddingBottom: "35px"}}
                className="btn btn-large w-100"
              >
                Submit Advise
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Mda;
