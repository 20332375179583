import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { post, patch } from '../../utility/fetch';

import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
import notification from '../../utility/notification';
import { BiLoader } from 'react-icons/bi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SequenceForm = ({ closeModal, details }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [sequenceNo, setSequenceNo] = useState(0);
  const [formType, setFormType] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');


  useEffect(() => {
    if (details?.id && details?.id) {
      setFormType('edit');
      setName(details.name);
      setDescription(details.description);
      setSequenceNo(details.sequenceNo);
    } else {
      setFormType('create');
      resetForm();
    }
  }, [details]);

  const resetForm = () => {
    setName('');
    setDescription('');
    setSequenceNo('');
    setError('');
  };

  const submitForm = async () => {
    setError('');
    if (name != '' && description != '' && sequenceNo) {
      setIsSubmitting(true);
      const payload = {
        name,
        description,
        sequenceNo,
        treasuryCategorySetupId: details?.id || 9,
        id: details.id
      };

      if (details && details.id) {
        payload.treasuryCategorySetupId = details.id || 9;
      }

      const res =
        formType === 'create'
          ? await post('/Setup/Sequence', payload)
          : await patch('/Setup/Sequence', payload);
      setIsSubmitting(false);
      if (res && res.status === 'success') {
        const { data } = res;
        if (data.code === 1) {
          notification({
            title: `Successful Request ${
              formType === 'create' ? 'Creation' : 'Update'
            }`,
            message: data.message,
            type: 'success'
          });
          resetForm();
          window.location.reload();
        } else {
          notification({
            title: `Request ${
              formType === 'create' ? 'Creation' : 'Update'
            } Error`,
            message: data.message,
            type: 'error'
          });
        }
      } else {
        notification({
          title: 'Network Error',
          message: `Something went wrong while ${
            formType === 'create' ? 'creating' : 'updating'
          } an Request. Please, try again later.`,
          type: 'error'
        });
      }
      closeModal();
    } else {
      setError('Enter all required fields');
    }
  };

  useEffect(() => {
    setError('');
  }, [name, description, sequenceNo]);

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          <TextInput
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-100 m-b-10"
            label="Sequence Name"
            required
          />
          <TextInput
            name="sequenceNo"
            value={sequenceNo ? sequenceNo : ""}
            commafy
            pattern="[0-9]*"
            inputmode="numeric"
            onChange={(e) => setSequenceNo(e.target.value)}
            className="w-100 m-b-10"
            label="Sequence Number"
            required
          />
          <TextareaInput
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-100 m-b-10"
            rows={4}
            label="Description"
            required
          />
          {error != '' && <p style={{ color: '#ff6242' }}>{error}</p>}
          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isSubmitting}
          >
            {`${formType === 'create' ? 'Create' : 'Update'} Sequence`}
            {isSubmitting && 'Loading...'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SequenceForm;
