import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Input, Button } from 'antd';
import { Pagination } from 'antd';
import { get, post } from '../../../utility/fetch';
import useMdaData from '../../../utility/useMda';
import useFilteredData from '../../../hooks/useFilteredData';
import CommentModal from '../../layouts/CommentModal';
import SearchInput from '../../inputs/SearchInput';
import PageCards from '../../layouts/PageCards';
import FilterBox from '../../layouts/FilterBox';
import notification from '../../../utility/notification';
import SelectInput from '../../inputs/SelectInput';
import moment from 'moment';

const { TextArea } = Input;
const ColumnHeaders = [
  'Memo/Payment Title',
  'Approved Date',
  'Amount',
  'Owner',
  'Supporting Document',
  'Action'
];

const memoHeaders = [
  'Item Name',
  'Owner',
  'Description',
  'Amount',
  'Supporting Document',
  'Action'
];

let onboardedStaffMemo;

const FilterBadge = ({ isActive, badgeCount, text, onClick }) => {
  return (
    <div className={`m-l-10 pointer  ${isActive && 'filter_badge_active'}`}>
      <Badge color="red" count={badgeCount}>
        <div
          onClick={() => onClick()}
          className={`flex-h-center filter_badge_text flex-v-center`}
        >
          {text}
        </div>
      </Badge>
    </div>
  );
};

const Mdas = ({ history }) => {
  const [title, setTitle] = useState('');
  const [currentView, setCurrentView] = useState('memo');
  const [activeFilter, setActiveFilter] = useState('Requested');
  const [limit, setLimit] = useState(20);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [current, setCurrent] = useState(1);
  const [fields, setFields] = useState({ title: '', details: '' });
  const [memoItems, setMemoItems] = useState([]);
  const [requestState, setRequestState] = React.useState({
    comment: '',
    learningRequestId: '',
    approvalStatus: 1
  });

  let mdas = useMdaData();
  mdas = useFilteredData(mdas, 'name');

  useEffect(() => console.log('items change', memoItems), [memoItems]);

  const [paymentItemData, setPaymentItems] = useState([]);
  const [paymentItemFixed, setPaymentItemsFixed] = useState([]);

  const exportData = useFilteredData(paymentItemData);

  const paginationChangeHandler = (current, pageSize) => {
    setLimit(pageSize);
    setCurrent(current);
  };

  const getpaymentItemData = async () => {
    try {
      const { status, data } = await get(
        '/GetTreasuryPaymentFiltered',
        {
          PageSize: limit,
          PageNumber: current,
          TreasurySequenceStateId: 3
        },
        true
      );
      if (status === 'success') {
        setPaymentItems(data?.items);
        setPaymentItemsFixed(data?.items);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    getpaymentItemData();
  };
  useEffect(() => {
    performPageMetaActions();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getpaymentItemData();
  }, [current, limit]);

  const onInputChange = async (value) => {
    try {
      if (value === 'All') {
        return setPaymentItems(paymentItemFixed);
      }
      const mda = mdas.filter((m) => {
        return m.id === value;
      })[0];

      console.log('mda', mda);

      const mdaFilteredData = paymentItemFixed.filter(
        (d) => d?.treasuryRequestObject?.mdaId === mda.id
      );
      setPaymentItems(mdaFilteredData);
    } catch (error) {
      console.log(error);
    }
  };
  const onSearch = (e) => {
    const { value } = e.target;
    const currData = [...paymentItemFixed];
    if (value === '') {
      setPaymentItems(currData);
      return;
    }
    const result = currData.filter((item) =>
      Object.values(item).some((itemValue) =>
        itemValue?.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setPaymentItems(result);
  };

  const submitMemo = async () => {
    try {
      const { status, data } = await post(
        '/CreateTreasuryPaymentPool',
        {
          ...fields,
          paymentItems: memoItems.map((item) => item.id)
        },
        true
      );
      setFields({});
      setMemoItems([]);
      notification({
        title: 'Success',
        message: 'Memo created successfully',
        type: 'success'
      });
    } catch (error) {
      console.log(error);
    }
  };

  const renderList = () => {
    return (
      <div className="col-9 flex flex-col">
        <div className="w-100 m-t-10 flex  flex-v-center flex-h-end">
          <SelectInput
            onChange={onInputChange}
            placeholder="Filter Items By"
            options={['All', ...mdas]}
          />
          <SearchInput
            className="m-l-10 h-35"
            onChange={onSearch}
            placeholder="Search transaction, invoice"
          />
        </div>
        <div className="table-view">
          <div className="table-header">
            <div className="table-row bg-grey">
              {ColumnHeaders.map((header) => (
                <div className="th ">
                  <p className="san-sherif">{header}</p>
                </div>
              ))}
            </div>
          </div>
          <tbody className="table-body">
            {paymentItemData.map((item) => (
              <tr role="presentation">
                <td className="td p-r td-bordered">
                  <span style={{ cursor: 'pointer' }} className="underline">
                    {item.name}
                  </span>
                </td>
                <td className="td p-r td-bordered">
                  {item.treasuryRequestObject
                    ? moment(item?.treasuryRequestObject?.lastUpdated).format(
                        'MMM Do YY'
                      )
                    : '-'}
                </td>
                <td className="td p-r td-bordered">
                  {' '}
                  {item.amount
                    ? (item.amount * item.quantity)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : '-'}
                </td>
                <td className="td p-r td-bordered">
                  {item?.treasuryRequestObject?.mda || '-'}
                </td>
                <td className="td p-r td-bordered ">
                  {item?.uploadResponse?.[0] && (
                    <a
                      style={{
                        color: 'black',
                        textDecoration: 'underline',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                      target="_blank"
                      href={item?.uploadResponse?.[0]?.path}
                      alt="Supporting Document"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1"
                        stroke="currentColor"
                        style={{ width: '25px', height: '25px' }}
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
                        />
                      </svg>
                      <div style={{ marginTop: '10px', marginLeft: '5px' }}>
                        {item?.uploadResponse?.[0]?.name || '-'}
                      </div>
                    </a>
                  )}
                </td>
                <td className="td p-r td-bordered">
                  <Button
                    style={{
                      backgroundColor: '#7FFFD4',
                      fontWeight: 'bold'
                    }}
                    onClick={() => {
                      setMemoItems((d) => [...d, item]);
                      setCurrentView('memo');
                    }}
                  >
                    <span>Add To Memo</span>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
          <div style={{ marginTop: '20px' }}>
            <Pagination
              showSizeChanger
              pageSize={limit}
              onShowSizeChange={paginationChangeHandler}
              current={current}
              total={330}
              // showTotal={true}
              onChange={paginationChangeHandler}
              // pageSizeOptions={pageSizeOptions}
              responsive
            />
          </div>
        </div>
      </div>
    );
  };

  const renderMemo = () => {
    return (
      <div className="col-10 flex flex-col">
        <div className="w-100 m-t-20 p-x-10 p-y-10 bg_green  space-between flex   flex-v-center flex-h-end">
          <div className="bold-text">Payment Approval</div>
        </div>
        <div
          style={{ background: 'white', height: '250px' }}
          className="w-100 flex-col p-l-10 p-r-10"
        >
          <TextArea
            placeholder="Subject"
            style={{ border: '0px', borderBottom: '1px solid #808080' }}
            className="w-100 m-t-20"
            value={fields.title}
            onChange={(e) =>
              setFields((d) => ({ ...d, title: e.target.value }))
            }
          />
          <TextArea
            placeholder="Details"
            maxLength={1000}
            value={fields.details}
            style={{
              border: '0px',
              height: '200px',
              borderBottom: '1px solid  #808080'
            }}
            onChange={(e) =>
              setFields((d) => ({ ...d, details: e.target.value }))
            }
            className="w-100 m-t-20"
          />
        </div>
        <div
          style={{ marginTop: '1000px !important' }}
          className="w-100 m-t-20 flex flex-col"
        >
          <div
            style={{ background: '#E0E0E0	', fontWeight: 'bolder' }}
            className="w-100 m-t-20 p-x-10 p-y-10 bg_green  space-between flex   flex-v-center flex-h-end"
          >
            <div className="bold-text">Add Payment Items</div>
          </div>

          <div className="table-view">
            <div style={{ background: '#808080' }} className="table-header">
              <div className="table-row bg-grey">
                {memoHeaders.map((header) => (
                  <div className="th ">
                    <p className="san-sherif">{header}</p>
                  </div>
                ))}
              </div>
            </div>
            <tbody className="table-body">
              {memoItems.map((item) => (
                <tr role="presentation">
                  <td className="td p-r td-bordered">
                    <span style={{ cursor: 'pointer' }} className="underline">
                      {item.name}
                    </span>
                  </td>
                  <td className="td p-r td-bordered">
                    {item?.treasuryRequestObject?.mda || '-'}
                  </td>
                  <td className="td p-r td-bordered">
                    {item.description || '-'}
                  </td>
                  <td className="td p-r td-bordered">
                    {item.amount
                      ? (item.amount * item.quantity)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : '-'}
                  </td>
                  <td className="td p-r td-bordered ">
                    {item?.uploadResponse?.[0] && (
                      <a
                        style={{
                          color: 'black',
                          textDecoration: 'underline',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                        target="_blank"
                        href={item?.uploadResponse?.[0]?.path}
                        alt="Supporting Document"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1"
                          stroke="currentColor"
                          style={{ width: '25px', height: '25px' }}
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
                          />
                        </svg>
                        <div style={{ marginTop: '10px', marginLeft: '5px' }}>
                          {item?.uploadResponse?.[0]?.name || '-'}
                        </div>
                      </a>
                    )}
                  </td>
                  <td className="td p-r td-bordered">
                    <Button
                      onClick={() =>
                        setMemoItems((d) => {
                          const index = d.findIndex((i) => i.id === item.id);
                          d.splice(index, 1);
                          return [...d];
                        })
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        style={{ width: '20px', height: '20px' }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </Button>
                    <Button
                      style={{ marginLeft: '10px' }}
                      onClick={() => setShowCommentModal(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        style={{ width: '15px', height: '20px' }}
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                        />
                      </svg>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </div>
        </div>
        <div
          style={{
            background: '#D3D3D3',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px'
          }}
          className="w-100  p-l-10 p-y-20 p-r-10"
        >
          <div>
            {memoItems.length} of {memoItems.length}
          </div>
          <div
            style={{
              fontWeight: 'bold'
            }}
          >
            {memoItems
              .reduce((a, b) => a + b.amount * b.quantity, 0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </div>
        </div>
        <div
          style={{
            background: 'white',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center'
          }}
          className="w-100  p-l-10 p-y-10 p-r-10"
        >
          <Button onClick={() => submitMemo()} className="m-l-10">
            Submit
          </Button>
          <Button
            onClick={() => {
              setFields({});
              setMemoItems([]);
            }}
            className="m-l-10 m-r-10"
          >
            Clear Entry
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {showCommentModal && (
        <CommentModal
          state={requestState}
          setState={setRequestState}
          btnText={'Submit Comment'}
          action={() => {
            setTimeout(() => {
              notification({
                title: 'Success',
                message: 'Comment Submitted',
                type: 'success'
              });
              setShowCommentModal(false);
            }, 2000);

            // setShowCommentModal();
          }}
          closeModal={() => setShowCommentModal(false)}
        />
      )}
      <div className="w-100">
        <div className="w-100 flex space-between flex-v-center">{title}</div>

        <div className="w-100 flex">
          <div className="flex-col col-3 m-r-10 m-t-20">
            <div className="flex ">
              <PageCards
                image={
                  <Button
                    onClick={() =>
                      setCurrentView((v) => (v === 'memo' ? 'list' : 'memo'))
                    }
                    style={{
                      borderRadius: '100px',
                      marginTop: '2px',
                      background: currentView === 'memo' ? '#E0E0E0	' : 'yellow'
                    }}
                  >
                    {currentView === 'memo' ? 'Show List' : 'Show Memo'}
                  </Button>
                }
                cardTitle="Items"
                count={paymentItemData.length}
                // onClick={() => }
                isActive={false}
              />
            </div>
            <FilterBox
              className={'filter_box_border_b'}
              title={'Budget Item'}
              type="select"
              options={['Option One']}
              placeholder="enter search text"
            />
          </div>
          {currentView === 'memo' ? renderMemo() : renderList()}
        </div>
      </div>
    </div>
  );
};

export default Mdas;
