import React, { useEffect, useState } from 'react';
import { get } from '../utility/fetch';

const useWorkflowData = () => {
  const [workflowData, setWorkflowData] = useState([]);
  const getSequenceData = async () => {
    try {
      const { status, data } = await get(
        '/GetTreasuryRequestWorkflowFiltered',
        {},
        true
      );
      if (status === 'success') {
        setWorkflowData(data.items);
      }

      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!workflowData.length) {
      getSequenceData();
    }
  }, []);

  return workflowData;
};

export default useWorkflowData;
