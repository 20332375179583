import React, { useEffect, useState } from 'react';
import { get, post, put } from '../../utility/fetch';
import { columnsToSearch } from '../../utility/filterColumns'
import searchByColumns from '../../utility/searchByColumns'
import useFilteredData from '../../hooks/useFilteredData';
import useMdaData from '../../utility/useMda';
import xls from "../../assets/svg/xls.svg"

import PageCards from '../layouts/PageCards';
import moment from 'moment';
import { GrStatusGoodSmall } from 'react-icons/gr';
import { Pagination, Spin, Tooltip } from 'antd';
import DocumentModal from '../layouts/DocumentModal';
import Greeting from '../layouts/Greeting';
import Cookies from 'js-cookie';
import SearchInput from '../inputs/SearchInput';

import BankBalanceModal from '../layouts/BankBalanceModal';
import notification from '../../utility/notification';
import { MdEdit } from 'react-icons/md';
import PrintModal from '../layouts/PrintModal';



const ReqTableHeaders = [
  'PV Initiator',
  'Initiator MDA',
  "Voucher Type",
  'Date of Creation',
  'Name of Vendor/Organization',
  'Amount',
  'PV Number',
  'Payment Voucher',
  'Schedule Form',
  'Uploaded Document',
  'Payment Cheque',
  'Status',
  'Approvals and Comments',

]



let moduleUsageMemo;
const ArchiveReport = ({ history }) => {
  const [title, setTitle] = useState('');
  const [moduleUsage, setModuleUsage] = useState([]);
  const [managementRequestData, setManagementRequest] = useState([]);
  const [pendingRequests, setPendingRequests] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [current, setCurrent] = useState(1);
  const [modalData, setModalData] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [managementRequestDataFixed, setManagementFixedRequest] = useState([]);
  const [showPictureModal, setShowPictureModal] = React.useState(false);
  const [documentType, setDocumentType] = useState("")
  const [activeMemo, setActiveMemo] = useState('pending');
  const [filteredRequest, setFilteredRequest] = useState([]);
  const [showPrintModal, setShowPrintModal] = useState(false)

  const [liability, setLiability] = useState(null)
  const [otherLiability, setOtherLiability] = useState({
    capital: null,
    recurrent: null,
    salary: null,
    overhead: null
  })
  const [bankBalance, setBankBalance] = useState("")
  const [interBankBalance, setInterBankBlance] = useState("")
  const [showBankBalanceModal, setShowBankBalanceModal] = useState(false)
  const [printData, setPrintData] = useState([]);
  const [paginationLength, setPaginationLength] = useState(0)
  const [searchParam, setSearchParam] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  // const [cardCounts, setCardCounts] = useState({
  //   approved: null,
  //   pending: null,
  //   declined: null,
  //   saved: null,
  //   capital: null,
  //   recurrent: null,
  //   govAll: null,
  //   govPending: null,
  // });
  const [cardCounts, setCardCounts] = useState({
    approved: 0,
    pending: 0,
    declined: 0,
    saved: 0,
    total: 0,
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);


  // let mdas = useMdaData();
  // mdas = useFilteredData(mdas, 'name');

  function sortByLastUpdated(array) {
    return array.sort((a, b) => {
      const dateA = new Date(a.lastUpdated);
      const dateB = new Date(b.lastUpdated);
      return dateB - dateA;
    });
  }

  const filterPV = (status) => {
    if (status == 'Pending') {
      const request = pendingRequests?.filter((r) => { return r?.voucher?.statusDetails == "Pending" });
      setFilteredRequest(request)
    }
    else if (status == 'Paid') {
      const request = pendingRequests?.filter((r) => { return r?.voucher?.statusDetails == "ApprovalCompleted" });
      setFilteredRequest(request)
    }
    else if (status == 'Rejected') {
      const request = pendingRequests?.filter((r) => { return r?.voucher?.statusDetails == "Rejected" });
      setFilteredRequest(request)
    }
    else {
      const request = pendingRequests?.filter((r) => { return r?.voucher?.statusDetails == "Unasigned" });
      setFilteredRequest(request)
    }
  }


  const handleFilter = async (status) => {
    setFilterStatus(status);
    let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${status}`);
    if (res?.status === "success") {
      setPaginationLength(res?.data?.totalItemCount);
      setPendingRequests(res.data.items);
      setFilteredRequest(res.data.items);
    }
  }

  const getTreasuryStats = async () => {
    let res = await get(`/Treasury/Stats`);
    if (res?.status === "success") {
      setCardCounts({
        approved: res?.data?.paid,
        pending: res?.data?.pending,
        declined: res?.data?.declined,
        saved: res?.data?.saved,
        total: res?.data?.total,
      })
    }
    console.log(res?.data)
  }

  const getPendingRequests = async (current = 1, pageSize = 10) => {
    setIsPending(true);
    try {

      let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);
      setPaginationLength(res?.data?.totalItemCount)
      if (res.status === 'success') {
        const filteredElements = res?.data?.items?.filter((item) => ((item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

        // Filter the array to get the elements that do not pass the filter condition
        const nonFilteredElements = res?.data?.items?.filter((item) => (!(item?.voucher?.journeyStateId === 12 && item?.voucher?.isApproved === false)));

        setPrintData(filteredElements);

        // Concatenate the filtered elements at the beginning of the array
        const newArr = filteredElements.concat(nonFilteredElements);
        setPendingRequests(newArr)
        setFilteredRequest(newArr);
        // setCardCounts({
        //   approved: res.data?.items?.filter((r) => r.voucher.statusDetails == 'ApprovalCompleted'),
        //   pending: res.data?.items?.filter((r) => r.voucher.statusDetails == 'Pending'),
        //   declined: res.data?.items?.filter((r) => r.voucher.statusDetails == 'Rejected'),
        //   capital: res.data?.items?.filter((r) => r?.voucher?.voucherType == 'Capital Voucher' && r?.voucher?.statusDetails == 'Pending'),
        //   recurrent: res.data?.items?.filter((r) => (r.voucher.voucherType === 'Salary Voucher' || r.voucher.voucherType === 'Recurrent Voucher' || r.voucher.voucherType === 'Overhead Voucher') && r?.voucher?.statusDetails == 'Pending'),
        //   saved: res.data?.items?.filter((r) => r.voucher.statusDetails == 'Unasigned'),
        //   govAll: res.data?.items?.filter((r) => (r.voucher.voucherType === 'Salary Voucher' || r.voucher.voucherType === 'Recurrent Voucher' || r.voucher.voucherType === 'Overhead Voucher' || r.voucher.voucherType === 'Capital Voucher') && r.voucher.statusDetails === 'Pending'),
        //   govPending: res.data?.items?.filter((r) => r.voucher.statusDetails == 'Pending' && (r.voucher.voucherType === 'Salary Voucher' || r.voucher.voucherType === 'Recurrent Voucher' || r.voucher.voucherType === 'Overhead Voucher' || r.voucher.voucherType === 'Capital Voucher'))
        // })
        setIsPending(false);
      }
    } catch (error) {
      console.log(error);
      setIsPending(false);
    }
  };

  const togglePrintModal = () => {
    setShowPrintModal((prev) => !prev)
  }


  const submitBankBalance = async (id) => {
    if (interBankBalance === "" || interBankBalance === null || !/^[0-9,.]+$/.test(interBankBalance?.toString())) {
      notification({
        type: 'error',
        message: 'Please Enter Valid Amount'
      });
      return;
    }

    let sanitizedValue = String(interBankBalance).replace(/[^0-9.]/g, '');

    let payload;

    try {
      let res;

      console.log(sanitizedValue)
      console.log("POST")
      res = await post(`/Ledger/balance`, { balance: Number(sanitizedValue) });

      if (res.status === 'success') {
        getBankBalance();
        setShowBankBalanceModal(false);
        notification({
          type: 'success',
          message: res.message
        });
      }
    } catch (error) {
      notification({
        type: 'error',
        message: error?.message
      });
    }

  };



  const getBankBalance = async () => {
    try {
      const res = await get(`/Ledger/balance`);

      const currentBalance = res?.data?.pop();

      if (res.status === 'success') {
        setBankBalance(currentBalance)
      }
    } catch (error) {
      notification({
        type: 'error',
        message: error?.message
      });
    }

  };

  const getLiabilities = async (id) => {
    let res;
    try {
      if (!id) {
        res = await get(`/Treasury/Liability`);

        const liabilities = res?.data;
        if (res.status === 'success') {
          setLiability(liabilities)
        }
      }
      else {
        res = await get(`/Treasury/Liability`, { voucherypeId: id });

        if (res.status === 'success') {
          if (id === 1) {
            setOtherLiability((prev) => ({
              ...prev,
              capital: res?.data?.totalPending
            }))
          }
          else if (id === 2) {
            setOtherLiability((prev) => ({
              ...prev,
              salary: res?.data?.totalPending
            }))
          }
          else if (id === 5) {
            setOtherLiability((prev) => ({
              ...prev,
              recurrent: res?.data?.totalPending
            }))
          }
          else if (id === 6) {
            setOtherLiability((prev) => ({
              ...prev,
              overhead: res?.data?.totalPending
            }))
          }

        }
      }

    } catch (error) {
      console.log(error);
    }

  };


  const querySearch = async () => {
    let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);
    if (res?.status === "success") {
      setPaginationLength(res?.data?.totalItemCount)
      setPendingRequests(res.data.items)
      setFilteredRequest(res.data.items)
    }
    console.log(res);
  }


  const handleSearch = (e) => {
    setSearchParam(e.target.value);
    // querySearch();
  }


  useEffect(() => {
    fetchRequestData();
    getPendingRequests();
    getTreasuryStats();
    getBankBalance()
    getLiabilities()
    getLiabilities(1)
    getLiabilities(2)
    getLiabilities(5)
    getLiabilities(6)
    localStorage.removeItem("requisitionNumber")
    localStorage.removeItem("approvedAmount")
    localStorage.removeItem("totalAmount")
    localStorage.removeItem("expenseType")
  }, []);

  const closeModal = () => {
    setShowPictureModal(false);
    setShowBankBalanceModal(false)
  };

  const fetchRequestData = async () => {
    // setIsLoading(true);
    // try {
    //   const { status, data } = await get(
    //     '/GetTreasuryRequestFiltered',

    //     true
    //   );
    //   if (status === 'success') {
    //     setManagementRequest(data);
    //     setManagementFixedRequest(data);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
    // setIsLoading(true);
  };
  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Treasury Archived Report';
    setTitle('Treasury Archived Report');
  };
  useEffect(() => {
    performPageMetaActions();
  }, []);

  // const onInputChange = async (value) => {
  //   try {
  //     if (value === 'All') return setModuleUsage(moduleUsageMemo);
  //     const mda = mdas.filter((m) => {
  //       return m.name.toLowerCase() === value.toLowerCase();
  //     })[0];
  //     const { status, data } = await get(`/ModuleUsage/${mda.id}`, {}, true);
  //     setModuleUsage(data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const getDeptName = (name) => {
    if (name == 'DFA') {
      return 'DFA'
    }
    if (name == "Internal Audit") {
      return 'Internal Auditor'
    }
    if (name == "FinalAccount") {
      return 'Final Account'
    }
    if (name == "AG") {
      return 'Accountant General'
    }
    if (name == "ExpenseControl") {
      return 'Expenditure Control'
    }
    if (name == "MgtAccount") {
      return 'Management Account'
    }
    if (name == "TreasuryCashAccount") {
      return 'Treasury Cash Account'
    }
    if (name == "PS") {
      return 'PS'
    }
    if (name == "Inspectorate") {
      return 'Inspectorate'
    }
    if (name == "Governor") {
      return 'Governor'
    }
  }

  const numberWithCommas = (x) => {
    if (String(x).includes(',')) {
      return String(x).replace(/,/g, '')?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    else {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  function searchByColumns(query, columns) {
    return pendingRequests?.filter(item =>
      columns.some(column => {
        const value = item?.voucher[column];
        return value && value.toString().toLowerCase().includes(query.toLowerCase());
      })
    );
  }

  const handleBankBalanceModal = (balance) => {
    setShowBankBalanceModal(true)
  }

  const showDocumentModal = (type, data) => {
    setModalData(data);
    setDocumentType(type);
    setShowPictureModal(true);
  }


  const paginationChangeHandler = (current, pageSize) => {
    setLimit(pageSize);
    setPageNumber(current);
    setPageSize(pageSize);
  };

  useEffect(() => {
    if (searchParam === "") {
      getPendingRequests();
    }
    else if (searchParam !== "") {
      querySearch();
    }
    else if (filterStatus !== "") {
      handleFilter(filterStatus);
    }

  }, [pageSize, pageNumber, searchParam])

  const filterLogs = (activeMemo) => {
    setActiveMemo(activeMemo);
    if (activeMemo === 0) {
      setManagementFixedRequest(managementRequestData);
    } else {
      const result = managementRequestData.filter(
        (m) => m.treasuryRequestStatus === activeMemo
      );
      setManagementFixedRequest(result);
    }
  };

  function sumAmounts(obj) {
    let sum = parseFloat(obj?.amount) || 0;

    if (obj.subVouchers && obj.subVouchers.length > 0) {
      // Recursively calculate the sum for each subvoucher
      obj.subVouchers.forEach(subVoucher => {
        sum += sumAmounts(subVoucher);
      });
    }

    return sum;
  }

  function calculateTotalSum(dataArray) {
    return dataArray.reduce((acc, obj) => acc + sumAmounts(obj?.voucher), 0);
  }

  function calculateTotalLiabilitySum(dataArray) {
    return dataArray.reduce((acc, obj) => acc + obj?.voucher?.amountApproved, 0);
  }



  return (
    <div className="w-100">
      {/* <Greeting /> */}
      <div className="flex space-between w-100 m-b-20">
        <PageCards
          cardTitle="Total PV Received"
          count={cardCounts.total ? cardCounts?.total : <Spin />}
          // onClick={() => filterLogs(0)}
          onClick={() => handleFilter("")}
          isActive={activeMemo === 0}
        />
        <PageCards
          cardTitle="Total PV Pending"
          count={
            cardCounts.pending !== null ? cardCounts.pending : <Spin />}
          // onClick={() => filterLogs(2)}
          onClick={() => handleFilter('Pending')}
          isActive={activeMemo === 2}
        />
        <PageCards
          cardTitle="Total PV Paid"
          count={
            cardCounts.approved !== null ? cardCounts?.approved : <Spin />
          }
          // onClick={() => filterLogs(3)}
          onClick={() => handleFilter('ApprovalCompleted')}
          isActive={activeMemo === 3}
        />

        <PageCards
          cardTitle="Total PV Declined"
          count={cardCounts.declined !== null ? cardCounts?.declined : <Spin />}
          // onClick={() => filterLogs(4)}
          onClick={() => handleFilter('Rejected')}
          isActive={activeMemo === 4}
        />
        <PageCards
          cardTitle="Total PV Saved"
          count={
            cardCounts.saved !== null ? cardCounts?.saved : <Spin />}
          // onClick={() => filterLogs(1)}
          onClick={() => handleFilter('Saved')}
          isActive={activeMemo === 1}
        />
      </div>
      {
        Cookies.get("access") === "AG" && <div className="flex space-between w-100 m-b-20">
          <PageCards
            cardTitle="Total Liabilities"
            count={liability ? `₦${numberWithCommas(Number(liability?.liability).toFixed(2))}` : <Spin />}
            // onClick={() => filterLogs(4)}
            onClick={() => filterPV('Paid')}
            isActive={activeMemo === 4}

          />
          <PageCards
            cardTitle="Pending PV Liabilities"
            count={
              liability ? `₦${numberWithCommas(Number(liability?.totalPending).toFixed(2))}` : <Spin />}
            // onClick={() => filterLogs(1)}
            onClick={() => filterPV('Pending')}
            isActive={activeMemo === 1}
          />
          <PageCards
            cardTitle=" Pending PV - Capital Liabilities"
            count={otherLiability?.capital ? `₦${numberWithCommas(Number(otherLiability?.capital).toFixed(2))}` : <Spin />}
            // onClick={() => filterLogs(4)}
            onClick={() => filterPV('Paid')}
            isActive={activeMemo === 4}
          />
          <PageCards
            cardTitle=" Pending PV - Recurrent Liabilities"
            count={
              otherLiability?.salary != null ? `₦${numberWithCommas(Number(otherLiability?.recurrent + otherLiability?.salary + otherLiability?.overhead).toFixed(2))}` : <Spin />}
            // onClick={() => filterLogs(1)}
            onClick={() => filterPV('Pending')}
            isActive={activeMemo === 1}
          />
        </div>
      }


      {
        Cookies.get("access") === "AG" &&
        <div>
          <div className='flex'>
            <h1 className='m-r-10 '>BANK BALANCE: {bankBalance ? `₦${numberWithCommas(String(bankBalance?.initialBalance - bankBalance?.expense))} ` : <Spin />} </h1>
            {/* <Tooltip title="Edit Balance" color='green'>
              <MdEdit className='btn-round btn-border p-10 cursor' color='green' size={40} onClick={() => handleBankBalanceModal(bankBalance)} />
            </Tooltip> */}
          </div>
          <button className='btn btn-large' onClick={handleBankBalanceModal}>Enter Current Bank Balance</button>
        </div>
      }

      <div className="w-100 m-t-20">
        {/* <table className="table-view m-t-10">
          <thead className="table-header">
            <tr className="table-row">
              <th
                className="th"
                style={{ textAlign: 'left', fontWeight: 'bolder' }}
              >
                <p>Transaction History</p>
              </th>
              <th className="th"></th>
              <th className="th actions">
                <p>Action</p>
              </th>
            </tr>
          </thead>
          <tbody className="table-body">
            {managementRequestDataFixed &&
              sortByLastUpdated(managementRequestDataFixed).map((item) => (
                <ListItem key={item.id} details={item} />
              ))}
          </tbody>
        </table> */}

        {/* {totalElement > limit && (
          <div style={{ marginTop: "20px" }}>
            <Pagination
              showSizeChanger
              pageSize={limit}
              onShowSizeChange={paginationChangeHandler}
              current={current}
              total={totalElement}
              // showTotal={true}
              onChange={paginationChangeHandler}
              // pageSizeOptions={pageSizeOptions}
              responsive
            />
          </div>
        )}
        {isLoading && <div className="w-100 center-text p-20">Loading...</div>}
        {errorMsg && <div className="w-100 center-text p-20">{errorMsg}</div>} */}
      </div>



      <div className='flex w-100 flex-h-end'>
        <SearchInput
          onChange={(e) => { handleSearch(e) }}
          className="w-30"
        />
      </div>
      {Cookies.get("access") === "AG" && <button onClick={() => setShowPrintModal((prev) => !showPrintModal)} className='btn w-25'><img className='m-r-10' src={xls} alt="" />DOWNLOAD</button>}
      {(Cookies.get("access") === "DFA" || Cookies.get("access") === "AG" || Cookies.get("access") === "ExpenseControl") && <div>
        <div className="overflow-table m-t-40">
          <table className="table-view">
            <thead className="">
              <tr className="tr-bordered">
                {ReqTableHeaders.map((th, index) => (
                  <th key={index} className="th th-bordered">
                    <p>{th}</p>
                  </th>
                ))}
              </tr>
            </thead>
            {isPending ? <div className='w-100 p-40 flex flex-h-center'><div align='center'><Spin /></div></div> :
              <tbody className="table-body">
                {
                  filteredRequest?.map((item) => (
                    <tr role="presentation">
                      <td className="td p-r td-bordered">
                        {item?.voucher?.createdByName}
                      </td>
                      <td className="td p-r td-bordered">
                        {item?.voucher?.mda}
                      </td>
                      <td className="td p-r td-bordered">
                        {item?.voucher?.voucherType}
                      </td>
                      <td className="td p-r td-bordered">
                        {new Date(item?.voucher?.dateCreated).toUTCString()}
                      </td>
                      <td className="td p-r td-bordered">
                        {item?.voucher?.drTo}
                      </td>
                      <td className="td p-r td-bordered">
                        {`₦${numberWithCommas(Number(parseFloat(item?.voucher?.amount) + item?.voucher?.subVouchers?.reduce(
                          (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                        )).toFixed(2))}`}
                      </td>
                      <td className="td p-r td-bordered">
                        {item?.voucher?.pvNo}
                      </td>
                      <td className="td p-r td-bordered">
                        <a onClick={() => { showDocumentModal("voucher", item) }}>View Payment Voucher</a>
                      </td>
                      <td className="td p-r td-bordered">
                        <a onClick={() => { showDocumentModal("schedule", item) }}>View Schedule Form</a>
                      </td>
                      <td className="td p-r td-bordered">
                        <a onClick={() => { showDocumentModal("memo", item) }}>View Approved Memo</a>
                      </td>
                      <td className="td p-r td-bordered">
                        <a onClick={() => { showDocumentModal("paymentCheque", item) }}>View Payment Cheque</a>
                      </td>
                      <td className="td p-r td-bordered" width={"17%"}>
                        <div >
                          <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${item?.voucher?.statusDetails === 'Pending' ? 'bg-amber' : item?.voucher?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : item?.voucher?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
                          <div className='float-right'>{item?.voucher?.statusDetails === 'Pending' ? `Pending With ${getDeptName(item?.voucher?.pendingOffice)}` : item?.voucher?.statusDetails === 'ApprovalCompleted' ? 'Paid' : item?.voucher?.statusDetails === 'Rejected' ? 'Declined' : item?.voucher?.statusDetails}</div>
                        </div>
                      </td>
                      <td className="td p-r td-bordered">
                        <a onClick={() => { showDocumentModal("comment", item) }}>View Approvals and Comments</a>
                      </td>

                    </tr>
                  ))
                }
              </tbody>
            }
          </table>

        </div>

        <div style={{ marginTop: '20px' }}>
          <Pagination
            showSizeChanger
            pageSize={pageSize}
            onShowSizeChange={paginationChangeHandler}
            current={pageNumber}
            total={paginationLength}
            // showTotal={true}
            onChange={paginationChangeHandler}
            // pageSizeOptions={pageSizeOptions}
            responsive
          />
        </div>

        {
          showPictureModal && <DocumentModal closeModal={closeModal} paymentVoucher={modalData.voucher} schedule={modalData.schedule} type={documentType} />
        }
        {
          showBankBalanceModal && <BankBalanceModal action={submitBankBalance} interBankBalance={interBankBalance} setBankBalance={setInterBankBlance} bankBalance={bankBalance} closeModal={closeModal} />
        }

        {
          showPrintModal && <PrintModal count={paginationLength} data={printData} closeModal={togglePrintModal} />
        }
      </div >}


    </div >
  );
};


export default ArchiveReport;
