export const sequenceColumns = [
  {
    title: (
      <div>
        <strong className="family m-r-10">Name</strong>
      </div>
    ),
    dataIndex: 'name'
  },
  {
    title: (
      <div>
        <strong className="family m-r-10">Description</strong>
      </div>
    ),
    dataIndex: 'description'
  },
  {
    title: (
      <div>
        {' '}
        <strong className="family m-r-10">Sequence Number</strong>
      </div>
    ),
    dataIndex: 'sequenceNo'
  }
];

export const sequenceCategoryColumns = [
  {
    title: (
      <div>
        <strong className="family m-r-10">Description</strong>
      </div>
    ),
    dataIndex: 'description'
  },
  {
    title: (
      <div>
        {' '}
        <strong className="family m-r-10">No Of Sequence</strong>
      </div>
    ),
    dataIndex: 'numberOfSequence'
  }
];

export const accessLevelColumns = [
  {
    title: (
      <div>
        <strong className="family m-r-10">Access Level</strong>
      </div>
    ),
    dataIndex: 'accessLevel'
  },
  {
    title: (
      <div>
        {' '}
        <strong className="family m-r-10">Admin</strong>
      </div>
    ),
    render: (item) => <p>{item.isAdmin ? 'True' : 'False'}</p>
  }
];

export const moduleAccessColumns = [
  {
    title: (
      <div>
        <strong className="family m-r-10">Treasury User</strong>
      </div>
    ),
    dataIndex: 'user'
  },
  {
    title: (
      <div>
        <strong className="family m-r-10">User Role</strong>
      </div>
    ),
    dataIndex: 'accessRole'
  },
  // {
  //   title: (
  //     <div>
  //       {' '}
  //       <strong className="family m-r-10">Admin</strong>
  //     </div>
  //   ),
  //   render: (text, record) => <p>{record.isAdmin ? 'Yes' : 'No'}</p>
  // }
];

export const managingMDAColumns = [
  {
    title: (
      <div>
        <strong className="family m-r-10">Treasury User</strong>
      </div>
    ),
    dataIndex: 'user'
  },
  {
    title: (
      <div>
        <strong className="family m-r-10">User Role</strong>
      </div>
    ),
    dataIndex: 'accessRole'
  },
  {
    title: (
      <div>
        <strong className="family m-r-10">Managing MDAs</strong>
      </div>
    ),
    render: (text, record) => <div>
        {record?.mdas.map((record)=> <p key={record.id}>{`- ${record.name}`}</p>)}
    </div> 
  },
  // {
  //   title: (
  //     <div>
  //       {' '}
  //       <strong className="family m-r-10">Admin</strong>
  //     </div>
  //   ),
  //   render: (text, record) => <p>{record.isAdmin ? 'Yes' : 'No'}</p>
  // }
];
