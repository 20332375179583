import VoucherPreview from './VoucherPreview';
import SchedulePreview from './SchedulePreview';
import { useEffect } from 'react';
const FinalPage = ({
    paymentVoucher,
    schedule,
    setPaymentVoucher
}) => {


    const stripCommas = (val) => {
        let num = String(val).replace(/,/g, '')
        return parseFloat(num);
    }


    return (
        <div>
            <VoucherPreview paymentVoucher={paymentVoucher} schedule={schedule} />
            {
                paymentVoucher.subVouchers?.map((_, index) => (
                    <VoucherPreview pay={paymentVoucher} paymentVoucher={paymentVoucher.subVouchers[index]} schedule={schedule} />

                ))
            }

            <SchedulePreview paymentVoucher={paymentVoucher} schedule={schedule} />
            {paymentVoucher?.approvalMemoUrl?.length > 0 && paymentVoucher?.approvalMemoUrl?.map((url) => (
                <div className=" m-t-40 m-b-20 flex flex-h-center"><a className='p-20 btn-border' href={url} target="_blank" rel="noopener noreferrer">View Uploaded Memo</a></div>
            ))}
        </div>
    )
}
export default FinalPage;