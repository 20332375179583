import { utils, writeFile } from 'xlsx';
import { formatTableHeaderToCamelCase } from './general';

function exportWS(data, fileName, sheetName = 'Sheet 1') {
  var myFile = `${fileName}.xlsx`;
  var myWorkSheet = utils.json_to_sheet(data);
  var myWorkBook = utils.book_new();
  utils.book_append_sheet(myWorkBook, myWorkSheet, sheetName);
  writeFile(myWorkBook, myFile);
}

export const exportTableData = (data, ColumnHeaders, filterBy) => {
  const formatData = data.map((column) => {
    const data = {};
    ColumnHeaders.map(
      (header) => (data[header] = column[formatTableHeaderToCamelCase(header)])
    );
    return data;
  });
  new exportWS(formatData, 'ESG_Module_Usage');
};

export default exportWS;
