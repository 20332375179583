import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { post,get, put } from '../../utility/fetch';

import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
import notification from '../../utility/notification';
import { BiLoader } from 'react-icons/bi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectInput from '../inputs/SelectInput';
import SelectInput2 from '../inputs/SelectInput2';

const ModuleAccessForm = ({ closeModal, details, mdas, fetchModuleAccess }) => {
  const [accessLevelId, setAccessLevelId] = useState('');
  const [userId, setUserId] = useState('');
  const [userMda, setUserMda] = useState('');
  const [users, setUsers] = useState('');
  const [formType, setFormType] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accessLevel, setAccessLevel] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (details?.id && details?.id) {
      setFormType('edit');
      setAccessLevelId(details.accessLevelId);
      setUserId(details.userId);
    } else {
      setFormType('create');
    }
    fetchAccessLevel();
  }, [details]);

  const fetchAccessLevel = async () => {
    try {
      const { status, data } = await get(`/AccessRoles`, {}, true);
      if (status === 'success') {
        const result = data?.result?.item1?.map((dt) => ({
          name: dt.description,
          id: dt.id
        }));
        setAccessLevel(result);
      }
    } catch (error) {
    }
  };


  const resetForm = () => {
    setAccessLevelId('');
    setUserId('');
    setError('');
  };

  const submitForm = async () => {
    setError('');
    if (userId != '' && accessLevelId != '') {
      setIsSubmitting(true);
      const payload = {
        accessRoleId: Number(accessLevelId),
        userId
      };

      if (details && details.id) {
        payload.id = details.id;
        payload.userId = details.userId;
        payload.accessRoleId = Number(accessLevelId);

      }

      const res =
        formType === 'create'
          ? await post('/AssignAccessToUser', payload)
          : await put('/ModuleAccess', payload);
      setIsSubmitting(false);

      if (res && res.status === 'success') {
        if (res.code === 1) {
          notification({
            title: `Successful Request ${
              formType === 'create' ? 'Creation' : 'Update'
            }`,
            message: res.message,
            type: 'success'
          });
          fetchModuleAccess();
          resetForm();
          
        } else {
          notification({
            title: `Request ${
              formType === 'create' ? 'Creation' : 'Update'
            } Error`,
            message: res.message,
            type: 'error'
          });
        }
      } else {
        notification({
          title: 'Network Error',
          message: `Something went wrong while ${
            formType === 'create' ? 'creating' : 'updating'
          } an Request. Please, try again later.`,
          type: 'error'
        });
      }
      closeModal();
    } else {
      setError('Enter all required fields');
    }
  };

  const getEmployeesFromMda = async (id) => {
    setUserMda(id);
    try {
      const { status, data } = await get(`/Staff/${id}`, {}, true);
      if (status === 'success') {
        const result = data.map((d) => ({
          name: `${d.title ?? ''} ${d.firstName} ${d.lastName}`,
          id: d.id
        }));
        setUsers(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setError('');
  }, [userId, accessLevelId]);

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content1">
          <div style={{ marginBottom: '20px' }}>
            <SelectInput2
              className="w-100 m-b-20"
              value={accessLevelId}
              onChange={(e) => {
                setAccessLevelId(e.target.value);
              }}
              label="Access Role"
              options={accessLevel || []}
              required
            />

           {formType === "create" && <SelectInput2
              className="w-100 m-b-20"
              value={userMda}
              onChange={(e) => getEmployeesFromMda(e.target.value)}
              label="Mdas"
              options={mdas || []}
              required
            />
            }

            {formType === "create" && <SelectInput2
              className="w-100 m-b-20"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              label="User"
              options={users || []}
              required
            />}
          </div>
          {error != '' && <p style={{ color: '#ff6242' }}>{error}</p>}
          <button
            type="button"
            className="btn btn-large w-100"
            onClick={submitForm}
            disabled={isSubmitting}
          >
            {`${formType === 'create' ? 'Create' : 'Update'} Module Access`}
            {isSubmitting && 'Loading...'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModuleAccessForm;
