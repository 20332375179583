import React, { useEffect, useState, useContext } from 'react';
import { get } from '../../utility/fetch';
import useMdaData from '../../utility/useMda';
import { exportTableData } from '../../utility/exportToExcel';
import useFilteredData from '../../hooks/useFilteredData';
import SelectInput from '../inputs/SelectInput';
import BudgetOverviewDetails from '../layouts/BudgetOverviewDetails';
import BudgetDetailsSummary from '../layouts/BudgetDetailsSummary';
import ExpenseExpended from '../layouts/ExpenseExpended';
import {
  formatTableHeaderToCamelCase,
  formatTableNameFromCamelCase
} from '../../utility/general';
import { UserInfoContext } from '../../contexts/index';

const ColumnHeaders = ['Mda', 'Number of Onboarded Staff'];

let onboardedStaffMemo;

const BudgetOverview = ({ history }) => {
  const [title, setTitle] = useState('');
  const { currentUser } = useContext(UserInfoContext);
  const [details, setDetails] = useState([{ title: 'Budget Overview' }]);
  let mdas = useMdaData();
  mdas = useFilteredData(mdas, 'name');
  const [onBoardingStaffData, setonBoardingStaffData] = useState([]);
  const [budgetDetails, setBudgetDetails] = useState([]);
  const [budgetDetailsSummary, setBudgetDetailsSummary] = useState([]);
  const [expenseExpended, setExpenseExpended] = useState([]);
  const exportData = useFilteredData(onBoardingStaffData);

  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Onboarded Staff';
    setTitle('Onboarded Staff');
  };

  const fetchBudgetDetails = async () => {
    try {
      const { status, data } = await get(
        `/GetBudgetDetails/21`,
        { PageSize: 20, PageNumber: 0 },
        true
      );
      if (status === 'success') {
        setBudgetDetails(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBudgetDetailsSummary = async () => {
    try {
      const { status, data } = await get(
        `/BudgetDetailsSummary/21`,
        { PageSize: 20, PageNumber: 0 },
        true
      );
      if (status === 'success') {
        setBudgetDetailsSummary(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchExpenseExpended = async () => {
    try {
      const { status, data } = await get(
        `/ExpenseExpended`,
        { PageSize: 20, PageNumber: 0 },
        true
      );
      if (status === 'success') {
        setExpenseExpended(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBudgetDetails();
    fetchExpenseExpended();
    fetchBudgetDetailsSummary();
    performPageMetaActions();
  }, []);

  const onInputChange = async (value) => {
    try {
      if (value === 'All') return setonBoardingStaffData(onboardedStaffMemo);
      const mda = mdas.filter((m) => {
        return m.name.toLowerCase() === value.toLowerCase();
      })[0];

      const { status, data } = await get(`/OnboardedStaff/${mda.id}`, {}, true);
      if (status === 'success') {
        setonBoardingStaffData([data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-100">
      <div className="w-100 m-t-20">
        <table className="table-view m-t-10">
          <tbody className="table-body">
            <ListItem title="Budget Details" details={budgetDetails} />
          </tbody>
        </table>
      </div>

      <div className="w-100 m-t-10">
        <table className="table-view m-t-10">
          <tbody className="table-body">
            <ListItem1
              title="Budget Details Summary"
              details={budgetDetailsSummary}
            />
          </tbody>
        </table>
      </div>

      <div className="w-100 m-t-10">
        <table className="table-view m-t-10">
          <tbody className="table-body">
            <ListItem2 title="Expense Expended" details={expenseExpended} />
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ListItem = ({ title, details = {} }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <tr
        className={`${showDetails && 'active'} table-row pointer`}
        onClick={() => setShowDetails(!showDetails)}
        role="presentation"
      >
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          {title}
        </td>
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          {details.organisingBody}
        </td>
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          {details.creationDate}
        </td>
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          <div className={`status status-${details.status}`}>
            {`${details.status !== 3 ? '' : 'Currently with '}`}
            {details.status !== 3 ? '' : <b>{details.currentlyWith} - </b>}
            <b>{details.approvalStatus}</b>
          </div>
        </td>
        <td className="td p-r actions" style={{ padding: '20px 10px' }}>
          {details.lastTreated}
        </td>
      </tr>
      {showDetails && (
        <tr className="">
          <td colSpan={5}>
            <BudgetOverviewDetails
              owner
              cardDetails={details}
              style={{ transition: 'all 0.6s ease-in' }}
            />
          </td>
        </tr>
      )}
    </>
  );
};

const ListItem1 = ({ title, details = {} }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <tr
        className={`${showDetails && 'active'} table-row pointer`}
        onClick={() => setShowDetails(!showDetails)}
        role="presentation"
      >
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          {title}
        </td>
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          {details.organisingBody}
        </td>
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          {details.creationDate}
        </td>
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          <div className={`status status-${details.status}`}>
            {`${details.status !== 3 ? '' : 'Currently with '}`}
            {details.status !== 3 ? '' : <b>{details.currentlyWith} - </b>}
            <b>{details.approvalStatus}</b>
          </div>
        </td>
        <td className="td p-r actions" style={{ padding: '20px 10px' }}>
          {details.lastTreated}
        </td>
      </tr>
      {showDetails && (
        <tr className="">
          <td colSpan={5}>
            <BudgetDetailsSummary
              owner
              cardDetails={details}
              style={{ transition: 'all 0.6s ease-in' }}
            />
          </td>
        </tr>
      )}
    </>
  );
};

const ListItem2 = ({ title, details = {} }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <tr
        className={`${showDetails && 'active'} table-row pointer`}
        onClick={() => setShowDetails(!showDetails)}
        role="presentation"
      >
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          {title}
        </td>
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          {details.organisingBody}
        </td>
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          {details.creationDate}
        </td>
        <td className="td p-r" style={{ padding: '20px 10px' }}>
          <div className={`status status-${details.status}`}>
            {`${details.status !== 3 ? '' : 'Currently with '}`}
            {details.status !== 3 ? '' : <b>{details.currentlyWith} - </b>}
            <b>{details.approvalStatus}</b>
          </div>
        </td>
        <td className="td p-r actions" style={{ padding: '20px 10px' }}>
          {details.lastTreated}
        </td>
      </tr>
      {showDetails && (
        <tr className="">
          <td colSpan={5}>
            <ExpenseExpended
              owner
              cardDetails={details}
              style={{ transition: 'all 0.6s ease-in' }}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default BudgetOverview;
