import * as React from 'react';
import { MdCardTravel } from 'react-icons/md';
import Table from 'antd/es/table';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import { del } from '../../utility/fetch';
import notification from '../../utility/notification';

const SettingsCard = ({
  headerPadding,
  header,
  icon,
  titleColor,
  name,
  value,
  onChange,
  onKeyPress,
  placeholder,
  createSequence,
  data,
  column,
  title,
  xAxis = 800,
  yAxis = 200,
  details,
  setDetails,
  onSearch,
  fetchModuleAccess
}) => {
  const [searchValue, setSearchValue] = React.useState("")
  const [searchResult, setSearchResult] = React.useState(data);

  React.useEffect(() => {
    setSearchResult(data)
  }, [data])

  React.useEffect(() => {
    handleSearch()
  }, [searchValue])

  const handleDelete = async(record) => {
    const res = await del(`/ModuleAccess/${record?.userId}`);
    if (res.status === 'success') {
      notification({
        type: 'success',
        message: res?.message
      });
      fetchModuleAccess();
    }
    else {
      notification({
        type: 'error',
        message: res.message
      });
    }
  };

  const handleEdit =(record)=>{
    setDetails(record);
    createSequence();
  }

  const menu = (record) => (
    <Menu>
      <Menu.Item>
        <a
          rel="noopener noreferrer"
          href="#"
          onClick={() => handleEdit(record)}
        >
          Edit
        </a>
      </Menu.Item>
     {title === "Create Module Access" && <Menu.Item>
        <a
          rel="noopener noreferrer"
          href="#"
          onClick={() => handleDelete(record)}
        >
          Delete
        </a>
      </Menu.Item>}
    </Menu>
  );

  const generateTableColumn = () => {
    const columnsToRender = [];

    const actions = [
      {
        title: <strong className="family">Action</strong>,
        dataIndex: 'action',
        key: 'action',
        width: '20%',
        render: (text, record) => (
          <Space direction="vertical">
            <Space wrap>
              <Dropdown
                onClick={(event) => event.stopPropagation()}
                overlay={menu(record)}
                trigger={['click']}
                placement="bottom"
              >
                <Button style={{ backgroundColor: '#1C811C', color: 'white' }}>
                  Select
                </Button>
              </Dropdown>
            </Space>
          </Space>
        )
      }
    ];

    columnsToRender.push(...[...column, ...actions]);

    return columnsToRender;
  };

  const handleSearch = () => {
    if(!searchValue || !searchValue?.length) setSearchResult(data)
    
    const result = data.filter((item) => {
      const values = Object.values(item);
      for (let i = 0; i < values.length; i++) {
        if (values[i] && values[i].toString().toLowerCase().includes(searchValue.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
    setSearchResult(result);
  };

  return (
    <div className="flex flex-v-center m-t-20">
      <div
        className="w-100"
        style={{
          margin: '0',
          padding: '0',
          boxShadow: '0px 2px 6px #0000000A'
        }}
      >
        <div
          className=" w-100 flex space-between m-b-10 bg-white"
          style={{ padding: headerPadding || '10px' }}
        >
          <h3 className="my-auto">{header}</h3>
          <div>{icon || <MdCardTravel fontSize="30px" />}</div>
        </div>
        <div className="w-100 flex flex-direction-column">
          <div
            className="border-top-green-4 border-bottom-gray-1 w-100 flex row wrap space-between"
            style={{
              padding: '10px',
              paddingRight: '5px',
              margin: 0,
              backgroundColor: titleColor || 'transparent'
            }}
          >
            <div className="flex">
              <div className={`search-input`}>
                <input
                  type="search"
                  name={name}
                  value={value}
                  onChange={e => {
                    setSearchValue(e.target.value)
                    onChange(e)
                  }}
                  onKeyPress={onKeyPress}
                  placeholder={placeholder || 'Search...'}
                />
              </div>
              {/* <button onClick={handleSearch} style={{ cursor: 'pointer' }}>
                Search
              </button> */}
            </div>
            <button onClick={createSequence} className="btn">
              {title}
            </button>
          </div>
          <Table
            bordered
            dataSource={searchResult}
            columns={generateTableColumn()}
            rowClassName="editable-row"
            pagination={true}
            // scroll={{ x: xAxis, y: yAxis }}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsCard;
