import React, { useEffect, useState } from 'react';
import { get } from '../utility/fetch';

const useSequenceData = () => {
  const [sequenceData, setSequenceData] = useState([]);
  const getSequenceData = async () => {
    try {
      const { status, data } = await get('/Setup/Sequence', {}, true);
      if (status === 'success') {
        setSequenceData(data);
      }

      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!sequenceData.length) {
      getSequenceData();
    }
  }, []);

  return sequenceData;
};

export default useSequenceData;
