import { getMonth, numberWithCommas } from "../../utility/general";
import { IoMdClose } from "react-icons/io";

const CashBudgetPreview = ({ data, closeModal, review }) => {
    console.log(data)
    return (
        <div className="overlay ">
            {/* <IoMdClose className="close-btnp-abs back-red right-0 top-0 bg-red p-5 pointer" onClick={() => closeModal()} /> */}
            <div style={{ width: '75%', margin: '0 auto', backgroundColor: "white", height: "auto" }}>
                <div
                    className=" flex-h-center modal-content-large w-100"
                    style={{
                        boxShadow: '0px 2px 6px #0000000A',
                        // backgroundColor: 'white'
                    }}
                >
                    {/* <div className="w-100"> */}
                    {/* <div className="form-bg-shadow bg-white p-40 w-75 p-r"> */}
                    <p>The Summarized Cash Budget for the month of {getMonth(data[0]?.month)} {data[0]?.year} is as analyzed below:</p>
                    {
                        data?.map((item, index) => (
                            <div className="flex m-t-20">
                                <h3 className="flex-basis-5 ">{index + 1}</h3>
                                <h3 className="flex-basis-20 ">{item?.economicCode}-</h3>
                                <h3 className="flex-basis-40 ">{item?.economicCodeDescription}</h3>
                                <h3 className="flex-basis-25 ">N{item?.amount}</h3>
                            </div>
                        ))
                    }
                    <div className="flex m-t-20 m-b-20">
                        <h2 className="flex-basis-65 right-text p-r-60">TOTAL:</h2>
                        <h2 className="underline">{numberWithCommas(Number(parseFloat(data?.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue?.amount), 0))).toFixed(2))}</h2>
                    </div>

                    {review &&  <p>Above is submitted for your consideration and approval, please.</p> }
                    <div className="p-abs pointer back-red right-0 top-0 bg-red p-5">
                        <IoMdClose onClick={() => closeModal()} size={25} color="white" />
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                </div>
            </div>
        </div>

    )
}

export default CashBudgetPreview;