const SchedulePreview = ({
    schedule,
    paymentVoucher
}) => {
    const numberWithCommas = (x) => {

        let sanitizedValue = String(x).replace(/[^0-9.]/g, '');

        // Ensure there are only two decimal places
        if (sanitizedValue.includes('.')) {
            const parts = sanitizedValue.split('.');
            if (parts[1].length > 2) {
                parts[1] = parts[1].substring(0, 2);
                sanitizedValue = parts.join('.');
            }
        }

        if (x === '') {
            return
        }
        if (String(x).includes(',')) {
            return String(sanitizedValue)?.replace(/,/g, '')?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        else {
            return sanitizedValue?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

        }
    }


    const stripCommas = (val) => {
        return parseFloat(String(val).replace(/,/g, ''))
    }


    return (
        <div className="page bg-white m-t-10">
            <h2 className="m-b-20">SCHEDULE NO. {schedule?.scheduleNO || "-"}</h2>
            <p className="m-b-20">ADMINISTRATIVE CODE <span className='w-45 filter_box_border_b'><span className='m-x-40 '>{schedule?.administrativeCode || schedule?.head || paymentVoucher?.head}</span> <span className='m-r-40'></span></span></p>
            <p className="m-b-20 flex"> <p>THE TREASURY CASH OFFICER AT THE TREASURY CASH OFFICE </p> <p className='w-25r filter_box_border_b center-text'> B/C</p></p>
            <p className="m-b-20">IS AUTHORIZED TO MAKE THE FOLLOWING PAYMENTS CHARGEABLE TO THE ABOVE HEAD OF EXPENDITURE </p>
            <p className="m-b-20">GPB 86(76)177/50.000OL5</p>
            <table className='w-100'>
                <tr>
                    <th >Date</th>
                    <th >PV No.</th>
                    <th >Economic Code No.</th>
                    <th >Name of Vendor/Organization</th>
                    <th width="10%">Treasury Cash Office</th>
                    <th >Amount</th>
                    <th >For use by the treasury Cash Office</th>
                </tr>
                <tr>
                    <td className=' center-text'>{new Date(paymentVoucher?.date || paymentVoucher?.dateCreated).toLocaleDateString()}</td>
                    <td className=' center-text'>{paymentVoucher?.pvNo}</td>
                    <td className=' center-text'>{schedule?.economicCode || paymentVoucher?.subhead || paymentVoucher?.economicCodeNo || schedule?.subhead || schedule?.economicCodeNo}</td>
                    <td className='w-25r'>
                        <p className='m-b-40 center-text '>{schedule?.nameOfPayee?.toUpperCase()}</p>
                        <p className='m-b-40 center-text'>1 pv</p>
                        <p className='m-b-40 center-text'>({paymentVoucher?.amountInWords})</p>
                    </td>
                    <td className=' center-text'>B/C</td>
                    <td className=' center-text'>{`₦${numberWithCommas(paymentVoucher?.amount || paymentVoucher?.amountRequested)}`}</td>
                    <td width="10%"></td>
                </tr>
                {paymentVoucher?.subVouchers?.length > 0 && paymentVoucher?.subVouchers?.map((voucher) => (
                    <tr>
                        <td className=' center-text'>{new Date(voucher?.date || voucher?.dateCreated).toLocaleDateString()}</td>
                        <td className=' center-text'>{voucher?.pvNo}</td>
                        <td className=' center-text'>{voucher?.economicCode || voucher?.economicCodeNo || voucher?.subhead}</td>
                        <td className='w-25r'>
                            <p className='m-b-40 center-text '>{voucher?.nameOfPayee?.toUpperCase() || voucher?.drTo?.toUpperCase()}</p>
                            <p className='m-b-40 center-text'>1 pv</p>
                            <p className='m-b-40 center-text'>({voucher?.amountInWords})</p>
                        </td>
                        <td className=' center-text'>B/C</td>
                        <td className=' center-text'>{(voucher?.amount === 0 || !voucher?.amountRequested) ? `₦${numberWithCommas(voucher?.amount)}` : `₦${numberWithCommas(voucher?.amountRequested)}`}</td>
                        <td width="10%"></td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={5}>TOTAL ₦</td>
                    {
                        (paymentVoucher?.amount === 0 || !paymentVoucher?.amount) ?
                            <td width="10%" className="center-text">{paymentVoucher?.subVouchers?.length > 0 ? `₦${numberWithCommas(Number(parseFloat(paymentVoucher?.amountRequested) + paymentVoucher?.subVouchers?.reduce(
                                (accumulator, currentValue) => accumulator + stripCommas(currentValue?.amountRequested), 0,
                            )).toFixed(2))}` : `₦${numberWithCommas(schedule?.amount)}`}
                            </td>
                            :
                            <td width="10%" className="center-text">{paymentVoucher?.subVouchers?.length > 0 ? `₦${numberWithCommas(Number(parseFloat(paymentVoucher?.amount) + paymentVoucher?.subVouchers?.reduce(
                                (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                            )).toFixed(2))}` : `₦${numberWithCommas(schedule?.amount)}`}
                            </td>
                    }
                </tr>
            </table>
            <div className='flex space-around m-t-40'>
                <div>
                    <img className="signature_img" src={schedule?.signingOfficer} alt="signing Officer's signature" />
                    <div className='filter_box_border_b w-200'></div>
                    <p className='center-text'>Signing Officer</p>
                </div>
                <div>
                    <img className="signature_img " src={schedule?.counterSigningOfficerSignature} alt="counter-signing officer's signature" />
                    <div className='filter_box_border_b w-200 '></div>
                    <p className='center-text'>Countersigning Officer</p>
                </div>
            </div>
        </div>
    )
}
export default SchedulePreview;