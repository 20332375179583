import React, { useEffect, useState } from 'react';
import useMdaData from '../../utility/useMda';
import TextareaInput from '../inputs/TextareaInput';
import TextInput from '../inputs/TextInput';
import notification from "../../utility/notification";
import Select from "react-select";

// import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Cookies from 'js-cookie';
import SelectApprovers from './SelectApprovers';
import { GrStatusGoodSmall } from 'react-icons/gr';
import { get } from '../../utility/fetch';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useRef } from 'react';
import { month, months } from '../../utility/general';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


const VoucherForm = ({
    employeeName,
    recipientMda,
    setemployeeName,
    setrecipientMda,
    setPaymentVoucher,
    paymentVoucher,
    setApproverId,
    approvers,
    setApprovers,
    clearApprovers,
    formLabel,
    step,
    selectValue
}) => {

    const [mdaList, setMdaList] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [counterSigningOfficers, setCounterSigningOfficers] = useState([]);
    const [overheadList, setOverheadList] = useState([])
    const [internalSelected, setInternalSelected] = useState("");
    const [counterSigningOfficerName, setCounterSigningOfficerName] = useState()
    const [monthName, setMonthName] = useState("");


    const stripCommas = (val) => {
        let num = String(val).replace(/,/g, '')
        return parseFloat(num);
    }

    const getPaidOverhead = async () => {
        let res = await get("/Voucher/Form/Paid/5")
        setOverheadList((prev) => {
            return [
                ...prev,
                res?.data
            ]
        })
    }

    const getPaidRecurrent = async () => {
        let res = await get("/Voucher/Form/Paid/6")
        setOverheadList((prev) => {
            return [
                ...prev,
                res?.data
            ]
        })
    }

    const handleMonthName = (value) => {
        setPaymentVoucher((prev) => (
            {
                ...prev,
                month: value.value,
                monthOfPayment: value.value
            }
        ))

        setMonthName(value)
    }

    const getPaidCapital = async () => {
        let res = await get("/Voucher/Form/Paid/1")
        setOverheadList((prev) => {
            return [
                ...prev,
                res?.data
            ]
        })
    }

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const updateSubvoucherFields = (name, value) => {
        let updatedSubVouchers = paymentVoucher?.subVouchers?.map((subvoucher) => ({
            ...subvoucher,
            [name]: value,

        }));
        setPaymentVoucher({
            ...paymentVoucher,
            [name]: value,
            subVouchers: updatedSubVouchers
        });
    }

    const fillAutoFields = () => {
        let updatedSubVoucher = {
            purpose: "",
            departmentNo: paymentVoucher?.departmentNo,
            station: "B/C",
            month: new Date().toLocaleString('default', { month: 'long' }),
            pvNo: "",
            administrativeCode: paymentVoucher?.head,
            economicCode: paymentVoucher?.subhead,
            economicCodeDescription: "",
            drTo: "",
            date: new Date().toISOString(),
            description: "",
            rate: 0,
            amountRequested: 0,
            total: 0,
            dayOfPayment: new Date().getDate(),
            monthOfPayment: new Date().toLocaleString('default', { month: 'long' }),
            yearOfPayment: new Date().getFullYear(),
            amountInWords: "",
            expenditureSignature: paymentVoucher?.expenditureSignature,
            receiverSignature: "",
            witness: paymentVoucher?.witness,
            comment: "",
            approvalMemoUrl: "",
            totalAmountApproved: paymentVoucher?.amountApproved,
            programCode: paymentVoucher?.programCode,
            poNo: paymentVoucher?.poNo
        }

        // Change the second element of the hobbies array to "playing".
        setPaymentVoucher((prevState) => ({
            ...prevState,
            subVouchers: prevState.subVouchers.map((subVoucher, index) => {
                if (index === (step - 1)) {
                    return updatedSubVoucher;
                }
                return subVoucher;
            }),
        }));

        // Create a copy of the state object.
        const newVoucher = { ...paymentVoucher };

        // Change the keys of the copy.
        newVoucher.amount = newVoucher.amountRequested;
        newVoucher.amountApproved = newVoucher.totalAmountApproved;

        delete newVoucher.amount;
        delete newVoucher.amountApproved;


        // Set the state to the copy.
        setPaymentVoucher(newVoucher);

    }




    const handleChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'amountRequested' || name === 'totalAmountApproved') && value !== '') {
            let sanitizedValue = String(value).replace(/[^0-9.]/g, '');

            setPaymentVoucher((prev) => ({ ...prev, [name]: sanitizedValue }));
        }
        else {
            setPaymentVoucher((prev) => ({ ...prev, [name]: value }));
        }

        if (name === 'administrativeCode' || name === 'totalAmountApproved') {
            updateSubvoucherFields(name, value);
        }
    };

    // Create a ref to the input element
    const inputRef = useRef(null);

    // Function to programmatically trigger onChange
    const triggerChange = () => {
        // Check if the input element exists
        if (inputRef.current) {
            // Create a new event
            const event = new Event('change', {
                bubbles: true, // Allow the event to bubble up the DOM tree
                cancelable: true, // Allow the event to be cancelable
            });

            // Trigger the event on the input element
            inputRef.current.dispatchEvent(event);
        }
    };


    React.useEffect(() => {
        // triggerChange();
        getApproversMDAs();
        // getCounterSigningOfficer();
        fillAutoFields();
        getPaidOverhead();
        getPaidRecurrent();
        getPaidCapital();



    }, [])


    const handleExpenditureOfficerId = async (e) => {
        const res = await get(`/staffById/${e.value}`)

        let updatedSubVouchers = paymentVoucher?.subVouchers?.map((subvoucher) => ({
            ...subvoucher,
            expenditureSignature: res?.data?.signature,

        }));
        setPaymentVoucher({
            ...paymentVoucher,
            expenditureSignature: res?.data?.signature,
            subVouchers: updatedSubVouchers
        });

        // setPaymentVoucher({
        //     ...paymentVoucher,
        //     expenditureSignature: res?.data?.signature,
        // })
    };

    const handleSubExpenditureOfficerId = (subVoucherIndex) => async (e) => {
        const res = await get(`/staffById/${e.value}`)
        let updatedVoucher = { ...paymentVoucher }
        updatedVoucher.subVouchers[subVoucherIndex]["expenditureSignature"] = res?.data?.signature;
        setPaymentVoucher(updatedVoucher)

    };


    const handleReceiverId = async (e) => {
        const res = await get(`/staffById/${e.value}`)
        setPaymentVoucher({
            ...paymentVoucher,
            receiverSignature: res.data.signature,
        })
    };

    const handleInternalSelected = (value) => {
        setPaymentVoucher({
            ...paymentVoucher,
            referenceNumber: value?.value,
        })
    }


    const numberWithCommas = (x) => {
        if (x === '') {
            return
        }
        if (String(x).includes(',')) {
            return String(x)?.replace(/,/g, '')?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        }
        else {
            return x?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

        }
    }


    const getEmployeesFromMda = async (id) => {
        const res = await get(`/Staff/${id}`)
        if (res.status === 'success') {
            // this.setState({
            const roleList = res?.data.map((item) => ({
                name: `${item.name}`,
                value: item.id,
            }));
            setEmployees(
                roleList.filter((item) => {
                    return !item.name.includes(Cookies.get("fullname"));
                })
            );
        }
    };

    const handleSubVoucherChange = (subVoucherIndex, field) => (event) => {
        let newValue = event.target.value;

        updateSubVoucherField(subVoucherIndex, field, newValue);
    };

    const updateSubVoucherField = (subVoucherIndex, field, value) => {
        const updatedVoucher = { ...paymentVoucher };
        updatedVoucher.subVouchers[subVoucherIndex][field] = value;
        setPaymentVoucher(updatedVoucher);
    };
    const handleCounterSigningOfficerId = async (e) => {
        const res = await get(`/staffById/${e.value}`)

        let updatedSubVouchers = paymentVoucher?.subVouchers?.map((subvoucher) => ({
            ...subvoucher,
            counterSigningOfficer: res?.data.id,
            rankOfSignatory: res?.data?.role,
        }));


        setPaymentVoucher({
            ...paymentVoucher,
            counterSigningOfficer: res?.data.id,
            rankOfSignatory: res?.data?.role,
            subVouchers: updatedSubVouchers
        })
    };

    const getCounterSigningOfficer = async (e) => {
        const res = await get(`/ModuleUsers/DFAs/${e}`)
        setCounterSigningOfficers(res?.data)
    };



    const getApproversMDAs = async () => {
        const res = await get(`/mdas`)
        if (res.status === 'success') {
            // this.setState({
            const roleList = res?.data.map((item) => ({
                name: `${item.name}`,
                value: item.id,
            }));
            setMdaList(roleList);

        };
    };

    const handleTextAreaChange = (e, editor) => {

        if (step === 0) {
            setPaymentVoucher((prev) => ({ ...prev, description: editor?.getData() }));
        }
        else {
            handleSubVoucherChanged(step - 1, 'description', editor?.getData())
        }
    }

    const handleSubVoucherChanged = (subVoucherIndex, field, value) => {
        const newValue = value;
        updateSubVoucherField(subVoucherIndex, field, newValue);
    };

    const handleApproverId = (e) => {
        setApprovers(e);
        setPaymentVoucher({
            ...paymentVoucher,
            drTo: e.label,
            witness: e.label,
            userId: e.value
        })

    };


    React.useEffect(() => {
        setApproverId(
            {
                approverID: approvers.value,
            }
        );
    }, [approvers]);


    return (
        <div className='inline-block w-90'>
            {/* <div className="w-100 flex space-between flex-v-center"></div> */}
            <div className="flex flex-h-center">
                <div className="col-9 pd-r-0">
                    <div
                        style={{
                            boxShadow: '0px 2px 6px #0000000A'
                            // backgroundColor: 'white'
                        }}
                    >
                        <div>
                            <div className="w-100 m-t-20 p-x-10 p-y-10 bg_green  space-between flex   flex-v-center flex-h-end">
                                <div className="bold-text">PAYMENT VOUCHER</div>
                            </div>

                            <div
                                style={{
                                    boxShadow: '0px 2px 6px #0000000A',
                                    backgroundColor: 'white',
                                    padding: '20px'
                                }}
                            >

                                {
                                    paymentVoucher?.typeId === 3
                                    &&
                                    <div className='m-b-10 w-100 form-group '>
                                        <label>Select Paid Overhead Voucher:</label>
                                        <Select
                                            placeholder='Select Paid Overhead Voucher'
                                            color='rgba(120,120,120,0.8)'
                                            className='m-b-10 w-w00'
                                            onChange={(value) => {
                                                setInternalSelected(value);
                                                handleInternalSelected(value);
                                                // setApprovers(value);
                                            }}
                                            value={internalSelected}
                                            options={overheadList?.flat()?.map((m) => ({
                                                label: `${m.purpose} -  ${m?.referenceNumber}`,
                                                value: m.referenceNumber,
                                            }))}
                                        />
                                    </div>
                                }

                                {
                                    step === 0 && <TextInput
                                        className="w-100 m-b-10"
                                        name="requisitionNumber"
                                        label="Reference Number"
                                        value={paymentVoucher?.referenceNumber}
                                        disabled
                                    // onChange={step === 0 ? handleChange : handleSubVoucherChange(step - 1, 'administrativeCode')}
                                    />
                                }

                                <TextInput
                                    className="w-100 m-b-10"
                                    name="administrativeCode"
                                    label="Administrative Code"
                                    value={step === 0 ? paymentVoucher?.administrativeCode || paymentVoucher?.head : paymentVoucher.subVouchers[step - 1]?.administrativeCode || paymentVoucher.subVouchers[step - 1]?.head}
                                    onChange={step === 0 ? handleChange : handleSubVoucherChange(step - 1, 'administrativeCode')}
                                />

                                {<TextInput
                                    className="w-100 m-b-10"
                                    label="Economic Code"
                                    name="economicCode"
                                    value={step === 0 ? paymentVoucher?.economicCode || paymentVoucher?.subhead : paymentVoucher.subVouchers[step - 1]?.economicCode || paymentVoucher.subVouchers[step - 1]?.subhead}
                                    onChange={step === 0 ? handleChange : handleSubVoucherChange(step - 1, 'economicCode')}
                                />}

                                {/* {<TextInput
                                    className="w-100 m-b-10"
                                    label="PO Number"
                                    name="poNo"
                                    value={step === 0 ? paymentVoucher?.poNo : paymentVoucher.subVouchers[step - 1]?.poNo}
                                    onChange={step === 0 ? handleChange : handleSubVoucherChange(step - 1, 'poNo')}
                                />} */}

                                <TextInput
                                    className="w-100 m-b-10"
                                    label="Department Number"
                                    name="departmentNo"
                                    value={step === 0 ? paymentVoucher?.departmentNo : paymentVoucher.subVouchers[step - 1]?.departmentNo}
                                    onChange={step === 0 ? handleChange : handleSubVoucherChange(step - 1, 'departmentNo')}
                                />

                                {selectValue === 1 && <TextInput
                                    className="w-100 m-b-10"
                                    label="Program Code"
                                    name="programCode"
                                    value={step === 0 ? paymentVoucher?.programCode : paymentVoucher.subVouchers[step - 1]?.programCode}
                                    onChange={step === 0 ? handleChange : handleSubVoucherChange(step - 1, 'programCode')}
                                />}

                                {paymentVoucher?.typeId === 3 && <div className='m-b-10 w-100 form-group'>
                                    <label>Month</label>
                                    <Select
                                        placeholder="Month"
                                        color='rgba(120,120,120,0.8)'
                                        className='m-b-10 w-w00'
                                        onChange={(value) => {
                                            handleMonthName(value);
                                        }}
                                        value={monthName}
                                        options={month?.map((m) => ({
                                            label: m,
                                            value: m,
                                        }))}
                                    />
                                </div>

                                }

                                {/* <TextInput
                                className="w-100 m-b-10"
                                label="PV No"
                                name="pvNo"
                                value={step === 0 ? paymentVoucher?.pvNo : paymentVoucher.subVouchers[step - 1]?.pvNo}
                                onChange={step === 0 ? handleChange : handleSubVoucherChange(step - 1, 'pvNo')}
                            /> */}

                                {step === 0 && <div className='m-b-10 w-100 form-group '>
                                    <label>Countersigning Officer's MDA</label>
                                    <Select
                                        placeholder="Countersigning Officer's MDA..."
                                        color='rgba(120,120,120,0.8)'
                                        className='m-b-10 w-w00'
                                        onChange={(value) => {
                                            setCounterSigningOfficerName(value);
                                            getCounterSigningOfficer(value?.value);
                                            // setApprovers(value);
                                        }}
                                        value={counterSigningOfficerName}
                                        options={mdaList.map((m) => ({
                                            label: m.name,
                                            value: m.value,
                                        }))}
                                    />

                                </div>}

                                {step === 0 && <div className='m-b-10 w-100 form-group '>
                                    <label>Countersigning Officer</label>
                                    <Select
                                        placeholder='Select Countersigning Officer'
                                        color='rgba(120,120,120,0.8)'
                                        className='m-b-10 w-w00'
                                        onChange={(value) => {

                                            setemployeeName(value);
                                            handleCounterSigningOfficerId(value);
                                            setApprovers(value);
                                        }}
                                        value={employeeName || approvers}
                                        options={counterSigningOfficers?.map((m) => ({
                                            label: m.user,
                                            value: m.userId,
                                        }))}
                                    />
                                </div>}


                                <div className='m-b-10 w-100 form-group '>
                                    <label>Memo's MDA</label>
                                    <Select
                                        label="Select Memo's MDA"
                                        color='rgba(120,120,120,0.8)'
                                        className='m-b-10 w-w00'
                                        onChange={(value) => {
                                            setrecipientMda(value);
                                            setPaymentVoucher({
                                                ...paymentVoucher,
                                                mdaId: value.value,

                                            })
                                            getEmployeesFromMda(value.value);
                                        }}
                                        value={recipientMda}
                                        options={mdaList.map((m) => ({
                                            label: m.name,
                                            value: m.value,
                                        }))}
                                    />
                                </div>

                                <TextInput
                                    className="w-100 m-b-10"
                                    label="Debit To"
                                    name="drTo"
                                    onChange={step === 0 ? handleChange : handleSubVoucherChange(step - 1, 'drTo')}
                                    value={step === 0 ? paymentVoucher?.drTo : paymentVoucher.subVouchers[step - 1]?.drTo}
                                />

                                <TextInput
                                    className="w-100 m-b-10"
                                    label="Voucher Purpose"
                                    name="purpose"
                                    value={step === 0 ? paymentVoucher?.purpose : paymentVoucher.subVouchers[step - 1]?.purpose}
                                    onChange={step === 0 ? handleChange : handleSubVoucherChange(step - 1, 'purpose')}
                                />

                                <TextInput
                                    className="w-100 m-b-10"
                                    label="Amount Approved in Memo"
                                    name="totalAmountApproved"
                                    value={step === 0 ? numberWithCommas(paymentVoucher?.amountApproved) || numberWithCommas(paymentVoucher?.totalAmountApproved) : numberWithCommas(paymentVoucher.subVouchers[step - 1]?.amountApproved) || numberWithCommas(paymentVoucher.subVouchers[step - 1]?.totalAmountApproved)}
                                    onChange={step === 0 ? handleChange : handleSubVoucherChange(step - 1, 'totalAmountApproved')}
                                    disabled={(step === 0 && (localStorage.getItem("expenseType")?.toLowerCase() === "overhead" || localStorage.getItem("expenseType")?.toLowerCase() === "personnel" || localStorage.getItem("expenseType")?.toLowerCase() === "capital")) ? true : false}
                                />

                                <TextInput
                                    className="w-100 m-b-10"
                                    label={formLabel}
                                    name="amountRequested"
                                    value={step === 0 ? numberWithCommas(paymentVoucher?.amountRequested) || numberWithCommas(paymentVoucher?.amount) : numberWithCommas(paymentVoucher.subVouchers[step - 1]?.amountRequested)}
                                    onChange={step === 0 ? handleChange : handleSubVoucherChange(step - 1, 'amountRequested')}
                                // disabled={(step === 0 && (localStorage.getItem("expenseType")?.toLowerCase() === "overhead" || localStorage.getItem("expenseType")?.toLowerCase() === "personnel" || localStorage.getItem("expenseType")?.toLowerCase() === "capital")) ? true : false}

                                />

                                <TextInput
                                    className="w-100 m-b-10"
                                    label="Amount in words"
                                    name="amountInWords"
                                    onChange={step === 0 ? handleChange : handleSubVoucherChange(step - 1, 'amountInWords')}
                                    value={step === 0 ? paymentVoucher?.amountInWords : paymentVoucher.subVouchers[step - 1]?.amountInWords}
                                />

                                <div className=' flex m-b-10 space-between w-100 form-group '>
                                    <div className='flex-basis-100'>
                                        <SelectApprovers
                                            mdaLabel="Expenditure Officer's MDA"
                                            employeeLabel="Expenditure Officer"
                                            paymentVoucher={paymentVoucher}
                                            setPaymentVoucher={setPaymentVoucher}
                                            handleApproverId={step === 0 ? handleExpenditureOfficerId : handleSubExpenditureOfficerId(step - 1)}
                                        />
                                    </div>
                                </div>

                                <p>Details*</p>
                                <CKEditor
                                    id='editor'
                                    editor={ClassicEditor}
                                    // config = {
                                    //   toolbar: [ 'bold', 'italic', '|', 'undo', 'redo' ]
                                    // }
                                    config={{
                                        // removePlugins: [ 'Paragraph' ],
                                        removePlugins: ['Link', 'CKFinder', 'Image', 'Media', 'EasyImage', 'MediaEmbed'],
                                        toolbar: ['bold', 'italic', '|', 'undo', 'redo'],
                                        // toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'TableSelection', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo'],
                                        // toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'tables', 'numberedList', 'blockQuote' ],
                                        heading: {
                                            options: [
                                                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                                            ]
                                        }

                                    }}
                                    data={step === 0 ? paymentVoucher?.description : paymentVoucher?.subVouchers[step - 1]?.description}
                                    // onInit={onInit}

                                    onChange={(event, editor) => handleTextAreaChange(event, editor)}
                                />

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default VoucherForm;