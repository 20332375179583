import React, { useEffect, useState } from 'react';
import { get } from '../../utility/fetch';
import useMdaData from '../../utility/useMda';
import { exportTableData } from '../../utility/exportToExcel';
import useFilteredData from '../../hooks/useFilteredData';
import SelectInput from '../inputs/SelectInput';
import { formatTableHeaderToCamelCase } from '../../utility/general';

const ColumnHeaders = [
  'MDA Name',
  'Approved',
  'Awaiting Approval',
  'Declined',
  'Saved'
];

const managerTypes = [
  'General Request',
  'Facility Manager',
  'Grievance Manager',
  'Leave Manager',
  'Mail Manager',
  'Memo Manager',
  'Training Manager',
  'Asset Manager'
];

let requestDataMemo;

const Mdas = ({ history }) => {
  const [title, setTitle] = useState('');
  const [managerType, setManagerType] = useState('General');
  let mdas = useMdaData();
  mdas = useFilteredData(mdas, 'name');
  const [requestData, setRequestData] = useState([]);
  const exportData = useFilteredData(requestData);

  const formatSelectedHeaders = (type) => {
    const headerAlias = {
      GeneralRequest: 'General'
    };
    if (headerAlias[type]) return headerAlias[type];
    return type;
  };

  const getRequestData = async () => {
    try {
      const type = managerType.split(' ').join('');
      const { status, data } = await get(
        `/Requests/${formatSelectedHeaders(type)}`,
        {},
        true
      );
      if (status === 'success') {
        requestDataMemo = data;
        setRequestData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const performPageMetaActions = () => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Requests';
    setTitle('Requests');
  };

  useEffect(() => {
    performPageMetaActions();
    getRequestData();
  }, []);

  useEffect(() => {
    getRequestData();
  }, [managerType]);

  const onInputChange = async (value) => {
    try {
      if (value === 'All') return setRequestData(requestDataMemo);
      const mda = mdas.filter((m) => {
        return m.name.toLowerCase() === value.toLowerCase();
      })[0];
      const type = managerType.split(' ').join('');
      const { status, data } = await get(
        `/Requests/${type}/${mda.id}`,
        {},
        true
      );
      if (status === 'success') {
        setRequestData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onManagerTypeChange = async (value) => {
    setManagerType(value);
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">{title}</div>
      <div className="w-100 m-t-10 flex space-between flex-v-center">
        <SelectInput
          onChange={onInputChange}
          label="Select MDA"
          options={['All', ...mdas]}
        />
        <SelectInput
          value={managerType}
          onChange={onManagerTypeChange}
          label="Select Module"
          options={['All', ...managerTypes]}
        />
        <button
          type="button"
          className="btn btn-large w-10"
          onClick={() => exportTableData(exportData, ColumnHeaders)}
        >
          Export To Excel
        </button>
      </div>
      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            {ColumnHeaders.map((header) => (
              <div className="th ">
                <p className="san-sherif">{header}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="table-body">
          {exportData.map((item) => (
            <div className="table-row" key={item.id}>
              {formatTableHeaderToCamelCase(ColumnHeaders).map((header) => (
                <div className="td left-text">{item[header]}</div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mdas;
