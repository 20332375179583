import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageCards = ({ isActive, cardTitle, image, count, onClick }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [small, setSmall] = useState(421);
  const [smallMid, setSmallMid] = useState(640);
  const [mid, setMid] = useState(1007);
  const [showBar, setshowBar] = useState(false);
  const [size, setSize] = useState(600);

  useEffect(() => {
    setSize(window.innerWidth);
  }, []);

  // #25d366

  return (
    <div
      className="border w-100 pointer "
      style={{ backgroundColor: 'transparent', margin: '0 2.5px' }}
      onClick={onClick}
    >
      <div style={{ borderBottom: '3px solid green' }}>
        <div
          className="page-6-card"
          style={{
            height: '100px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: isActive ? 'lightgreen' : '#fff'
          }}
        >
          <div
            className="flex-between "
            style={{
              padding: '10px 15px',
              marginBottom: '3px',
              fontWeight: 'bold'
            }}
          >
            <p>{cardTitle}</p>
            {image}
          </div>
          <h4
            style={{
              padding: '0 15px',
              fontFamily: 'system-ui',
              fontWeight: 'bold',
              fontSize: '25px'
            }}
          >
            <p>{count ? count : '0'}</p>
          </h4>
        </div>
      </div>
    </div>
  );
};
export default PageCards;
