import React from 'react';

const Modal = ({ children, visible, className, onClose }) =>
  visible && (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        visibility: visible ? 'visible' : 'hidden'
      }}
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          onClose();
        }
      }}
    >
      <div
        className={className}
        style={{
          backgroundColor: '#fff',
          padding: '20px',
          width: 'auto',
          maxWidth: '80%'
        }}
      >
        {children}
      </div>
    </div>
  );
Modal.defaultProps = {
  visible: true,
  className: ''
};

export default Modal;
