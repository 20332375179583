/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SelectInput = (props) => {
  const options =
    props.options &&
    props.options.map((option) =>
      typeof option === 'object' ? (
        <Option value={option.id} key={option.id}>
          {option.name}
        </Option>
      ) : (
        <Option value={option} key={option}>
          {option}
        </Option>
      )
    );
  return (
    <div>
      <div>
        <label htmlFor={props.name}>{props.label}</label>
      </div>

      <Select
        name={props.name}
        id={props.name}
        value={props.value}
        onChange={props.onChange}
        defaultValue={props?.defaultValue}
        onBlur={props.onBlur}
        disabled={props.disabled}
        showSearch={props.onSearch}
        style={{ width: '100%' }}
        // onSearch={onSearch}
        placeholder={props.placeholder || 'search'}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options}
      </Select>
    </div>
  );
};

export default SelectInput;
