import React, { useEffect, useState } from 'react';
import { del, get, post, put } from '../../utility/fetch';
import { stripCommas, getDeptName, deleteAlert } from '../../utility/general';
import useMdaData from '../../utility/useMda';
import { columnsToSearch } from '../../utility/filterColumns'
import searchByColumns from '../../utility/searchByColumns'
import SelectInput from '../inputs/SelectInput';
import notification from '../../utility/notification';
import { Pagination, Spin, Tooltip } from 'antd';
import { uploadDocument } from '../../utility/otherApis';
import { Step, Stepper } from 'react-form-stepper';
import VoucherForm from '../layouts/VoucherForm';
import FinalPage from '../layouts/FinalPage';
import ScheduleForm from '../layouts/ScheduleForm';
import DocumentModal from '../layouts/DocumentModal';
import AddDocuments from '../layouts/AddDocuments';
import { AiOutlineDelete, AiOutlineSend } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import SearchInput from '../inputs/SearchInput';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import UploadButton from '../layouts/UploadButton';
import SearchDocument from '../layouts/SearchDocument';
import { useLocation } from 'react-router-dom';
import { GrStatusGoodSmall } from 'react-icons/gr';
import {v4 as uuidv4} from 'uuid'


const TreasuryRequest = ({ history }) => {
  const [employeeName, setemployeeName] = React.useState([]);
  const [scheduleEmployeeName, setScheduleEmployeeName] = useState([]);
  const [recipientMda, setrecipientMda] = React.useState([]);
  const [approverId, setApproverId] = React.useState(0);
  const [approvers, setApprovers] = React.useState([]);
  const [modalData, setModalData] = useState({});
  const [step, setStep] = useState(0);
  const steps = [
    { label: 'Payment Voucher' },
    { label: 'Schedule Form' },
    { label: 'Add Supporting Document' },
    { label: 'Verify and Submit' },
  ];
  let mdas = useMdaData();

  const [showPictureModal, setShowPictureModal] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [voucherNum, setVoucherNum] = useState(0)
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);

  const [itemData, setItemData] = useState({
    name: '',
    description: '',
    amount: '',
    quantity: ''
  });

  const [pendingRequests, setPendingRequests] = useState([])
  const [filteredRequests, setFilteredRequests] = useState([])
  const [filteredCorruptedRequests, setFilteredCorruptedRequests] = useState([])
  const [documentData, setDocumentData] = useState({
    name: '',
    description: '',
    files: []
  });

  const [url, setUrl] = useState(0);
  const [status, setStatus] = useState("")
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10)

  const [mFilesArray, setMFilesArray] = useState([])
  const [voucherTypes, setVoucherTypes] = useState(null)
  const [selectValue, setSelectValue] = useState(1)
  const [documentType, setDocumentType] = useState("")
  const [upperStep, setUpperStep] = useState(0)
  const [corruptUploads, setCorruptUploads] = useState([])
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [paginationLength, setPaginationLength] = useState(0);
  const [paymentVoucher, setPaymentVoucher] = useState(
    {
      referenceNumber: localStorage.getItem('requisitionNumber'),
      expenseId: Number(localStorage?.getItem('expenseId')),
      departmentNo: "",
      station: "B/C",
      month: new Date().toLocaleString('default', { month: 'long' }),
      pvNo: "",
      administrativeCode: "",
      economicCode: "",
      drTo: "",
      date: new Date().toISOString(),
      description: "",
      rate: 0,
      amountRequested: Number(localStorage.getItem('totalAmount')) || 0,
      mdaId: 0,
      total: 0,
      totalAmountApproved: Number(localStorage.getItem('approvedAmount')) || 0,
      dayOfPayment: new Date().getDate(),
      rankOfSignatory: "",
      userId: approvers[0]?.value,
      monthOfPayment: new Date().toLocaleString('default', { month: 'long' }),
      yearOfPayment: new Date().getFullYear(),
      amountInWords: "",
      expenditureSignature: "",
      receiverSignature: "",
      witness: approvers[0]?.label,
      comment: "",
      typeId: 3,
      counterSigningOfficer: 0,
      approvalMemoUrl: [],
      poNo: 0,
      subVouchers: []
    }
  )
  const [schedule, setSchedule] = useState(
    {
      station: "B/C",
      pvNo: "",
      administrativeCode: "",
      economicCode: "",
      date: new Date().toISOString(),
      description: "",
      rate: 0,
      amount: "",
      total: 0,
      dayOfPayment: new Date().getDate(),
      userId: approvers[0]?.value,
      monthOfPayment: new Date().toLocaleString('default', { month: 'long' }),
      yearOfPayment: new Date().getFullYear(),
      amountInWords: "",
      comment: "",
      scheduleNO: 0,
      serialNo: "",
      economicCodeNo: "",
      nameOfPayee: "",
      poNo: 0,
      treasuryCashOffice: "",
      signingOfficer: "",
      counterSigningOfficerSignature: ""
    }
  )


  const clearVoucherAndScheduleFields = () => {
    setScheduleEmployeeName(null);
    setPaymentVoucher({
      departmentNo: "",
      station: "B/C",
      month: new Date().toLocaleString('default', { month: 'long' }),
      pvNo: "",
      administrativeCode: "",
      economicCode: "",
      drTo: "",
      date: new Date().toISOString(),
      description: "",
      rate: 0,
      amountRequested: "",
      mdaId: 0,
      total: 0,
      totalAmountApproved: "",
      dayOfPayment: new Date().getDate(),
      rankOfSignatory: "",
      userId: approvers[0]?.value,
      monthOfPayment: new Date().toLocaleString('default', { month: 'long' }),
      yearOfPayment: new Date().getFullYear(),
      amountInWords: "",
      expenditureSignature: "",
      receiverSignature: "",
      witness: approvers[0]?.label,
      comment: "",
      typeId: 3,
      counterSigningOfficer: 0,
      approvalMemoUrl: [],
      poNo: 0,
      subVouchers: []
    });

    setSchedule({
      station: "B/C",
      pvNo: "",
      administrativeCode: "",
      economicCode: "",
      date: new Date().toISOString(),
      description: "",
      rate: 0,
      amount: "",
      total: 0,
      dayOfPayment: new Date().getDate(),
      userId: approvers[0]?.value,
      monthOfPayment: new Date().toLocaleString('default', { month: 'long' }),
      yearOfPayment: new Date().getFullYear(),
      amountInWords: "",
      comment: "",
      scheduleNO: 0,
      serialNo: "",
      economicCodeNo: "",
      nameOfPayee: "",
      poNo: 0,
      treasuryCashOffice: "",
      signingOfficer: "",
      counterSigningOfficerSignature: ""
    })
  }

  useEffect(() => {
    handleOverheadTypes()
    if (localStorage.getItem("expenseType")?.toLowerCase() === "overhead") {
      notification({
        type: "info",
        message: "REMINDER: Please select voucher type",
      })
    }
  }, [])


  const handleOverheadTypes = () => {
    if (localStorage.getItem("expenseType")?.toLowerCase() === "overhead") {
      setVoucherTypes([
        {
          description: "Overhead Voucher",
          id: 6
        },
        {
          description: "Recurrent Voucher",
          id: 5
        }
      ])
    }
    else if (localStorage.getItem("expenseType")?.toLowerCase() === ""
      || localStorage.getItem("expenseType")?.toLowerCase() === null
      || localStorage.getItem("expenseType")?.toLocaleLowerCase() === undefined) {
      setVoucherTypes([
        {
          description: "Internal Voucher",
          id: 3
        },
        {
          description: "Salary Voucher",
          id: 2
        }
      ])
    }
  }

  const openPicture = (link) => {
    setUrl(link);
    setShowPictureModal(true);
  };

  const handleSelect = (value) => {
   
    setSelectValue(value);
    setVoucherType(value);
    setPaymentVoucher({
      ...paymentVoucher,
      typeId: value
    })
  }


  const paginationChangeHandler = (current, pageSize) => {
    setLimit(pageSize);
    setPageNumber(current);
    setPageSize(pageSize);
  };


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);




  const setVoucherTypeOption = (expenseType) => {
    if (expenseType?.toLowerCase() === "capital") {
      return {
        label: "Capital Voucher",
        value: 1
      }
    }
    else if (expenseType?.toLowerCase() === "personnel") {
      return {
        label: "Salary Voucher",
        value: 2
      }

    }
    else if (expenseType?.toLowerCase() === "" || expenseType?.toLowerCase() === null || expenseType?.toLowerCase() === undefined) {
      return {
        label: "Internal Voucher",
        value: 3
      }
    }
    // else if (expenseType?.toLowerCase() === "overhead") {
    //   return {
    //     label: "Overhead Voucher",
    //     value: 6
    //   }

    // }

  }


  const [voucherType, setVoucherType] = useState(setVoucherTypeOption(localStorage.getItem("expenseType") || ""));



  const showDocumentModal = (type, data) => {
    setModalData(data);
    if (type === "schedule") {
      setDocumentType("schedule")
    }
    else if (type === "voucher") {
      setDocumentType("voucher")
    }
    else if (type === "memo") {
      setDocumentType("memo")
    }
    else if (type === "paymentCheque") {
      setDocumentType("paymentCheque")
    }
    else if (type === "comment") {
      setDocumentType("comment")
    }
    setShowPictureModal(true);
  }


  const getVoucherTypes = async () => {

    try {
      const res = await get('/Voucher/Type')
      if (res.status === 'success') {
        if (localStorage.getItem("expenseType")?.toLowerCase() === "overhead") {
          setVoucherTypes([
            {
              description: "Overhead Voucher",
              id: 6
            },
            {
              description: "Recurrent Voucher",
              id: 5
            }
          ])
        }
        else if (
          localStorage.getItem("expenseType")?.toLowerCase() === ""
          || localStorage.getItem("expenseType")?.toLowerCase() === null
          || localStorage.getItem("expenseType")?.toLowerCase() === undefined) {
          setVoucherTypes([
            {
              description: "Internal Voucher",
              id: 3
            },
            {
              description: "Salary Voucher",
              id: 2
            }
          ])
        }
        else {
          setVoucherTypes(res?.data);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }



  const submitPaymentVoucher = async () => {
    try {
      const res = await get(`/Voucher/form`);
      if (res.status === 'success') {
      }
    } catch (error) {
      console.log(error);
    }
  }

  const clearApprovers = () => {
    setemployeeName([]);
    setrecipientMda([]);
  };

  const addVoucher = () => {
    const paymentV = paymentVoucher;
    paymentV.subVouchers?.push(
      {
      
        departmentNo: paymentVoucher?.departmentNo,
        station: "B/C",
        month: new Date().toLocaleString('default', { month: 'long' }),
        pvNo: "",
        administrativeCode: paymentVoucher?.administrativeCode,
        economicCode: paymentVoucher?.economicCode,
        drTo: "",
        date: new Date().toISOString(),
        description: "",
        rate: 0,
        amountRequested: "",
        mdaId: 0,
        total: 0,
        totalAmountApproved: paymentVoucher?.totalAmountApproved,
        dayOfPayment: new Date().getDate(),
        rankOfSignatory: "",
        monthOfPayment: new Date().toLocaleString('default', { month: 'long' }),
        yearOfPayment: new Date().getFullYear(),
        amountInWords: "",
        expenditureSignature: paymentVoucher?.expenditureSignature,
        receiverSignature: "",
        witness: approvers[0]?.label,
        comment: "",
        poNo: 0,
      }
    )
    setPaymentVoucher(paymentV);
    setVoucherNum(voucherNum + 1)
  }


  const submitFromSaved = async (data) => {
    setIsSubmitting(false);

    const payload = {
      voucherId: data?.voucher?.id,
      scheduleId: data?.schedule?.id
    }

    try {



      const res = await put('/Treasury/Submit', payload);
      setIsSubmitting(false);

      if (res && res.status === 'success') {
        if (res.code === 1) {
          notification({
            title: `Successful Request Submission`,
            message: res.message,
            type: 'success'
          });
          getPendingRequests();
        } else {
          notification({
            title: `Request Submission Error`,
            message: res.message,
            type: 'error'
          });
        }
      } else {
        notification({
          title: 'Network Error',
          message: `Something went wrong while creating an Request. Please, try again later.`,
          type: 'error'
        });
      }
    } catch (error) {
      notification({
        title: 'Network Error',
        message: `Something went wrong while creating an Request. Please, try again later.`,
        type: 'error'
      });
      setIsSubmitting(false);
    }
    setIsSubmitting(false);


  }

  const editSaved = (data) => {
    console.log("1st update")
    setStatus("edit");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    console.log("2nd update")
    if (data?.voucher?.typeId === 5 || data?.voucher?.typeId === 6) {
      localStorage.setItem("expenseType", "overhead");
      handleOverheadTypes("overhead");

    }
    console.log("3rd update")
    const updatedSubVouchers = data?.voucher?.subVouchers?.map((subVoucher) => {
      
      const { amount, head, subhead, amountApproved, ...rest } = subVoucher; // Destructure the old key and get the rest of the object
      
      const amountRequested = subVoucher.amount
      const administrativeCode = subVoucher.head
      const economicCode = subVoucher.subhead
      const totalAmountApproved = data?.voucher?.amountApproved
    

      return {
        ...rest, // Spread the rest of the object
        ["amountRequested"]: amountRequested,
        ["administrativeCode"]: administrativeCode,
        ["economicCode"]: economicCode,
        ["totalAmountApproved"]: totalAmountApproved,
      };
    })
    console.log("4th update")
    
    const updatedVoucher = {
      referenceNumber: data?.voucher?.referenceNumber,
      expenseId: data?.voucher?.expenseId,
      purpose: data?.voucher?.purpose,
      departmentNo: data?.voucher?.departmentNo,
      station: "B/C",
      month: data?.voucher?.month,
      pvNo: data?.voucher?.pvNo,
      administrativeCode: data?.voucher?.head,
      economicCode: data?.voucher?.subhead,
      economicCodeDescription: data?.voucher?.subheadDescription,
      drTo: data?.voucher?.drTo,
      date: data?.voucher?.date,
      description: data?.voucher?.description,
      rate: data?.voucher?.rate,
      amountRequested: data?.voucher?.amount,
      total: data?.voucher?.total,
      rankOfSignatory: data?.voucher?.rankOfSignatory,
      dayOfPayment: data?.voucher?.dayOfPayment,
      mdaId: data?.voucher?.mdaId,
      userId: data?.voucher?.userId,
      monthOfPayment: data?.voucher?.monthOfPayment,
      yearOfPayment: data?.voucher?.yearOfPayment,
      amountInWords: data?.voucher?.amountInWords,
      expenditureSignature: data?.voucher?.expenditureSignature,
      receiverSignature: data?.voucher?.receiverSignature,
      witness: data?.voucher?.witness,
      comment: data?.voucher?.comment,
      totalAmountApproved: data?.voucher?.amountApproved,
      programCode: data?.voucher?.programCode,
      poNo: data?.voucher?.poNo,
      typeId: data?.voucher?.typeId,
      counterSigningOfficer: data?.voucher?.counterSigningOfficer || data?.schedule?.counterSigningOfficer,
      subVouchers: updatedSubVouchers,
      id: data?.voucher?.id,
      approvalMemoUrl: data?.voucher?.approvalMemoUrls?.map((url) => (url?.path)) || []
    }
    console.log("5th update")
    let type = voucherTypes?.filter((item) => (item?.id === data?.voucher?.typeId)).map((item) => ({
      id: item?.id,
      name: item?.description
    }))
    setVoucherType(type[0]?.id);
    console.log("6th update")
    setVoucherNum(data?.voucher?.subVouchers.length);
    setPaymentVoucher(
      updatedVoucher
    )
    setSchedule(
      data.schedule
    )
    console.log("last update")
  }

  const deleteSaved = async (data) => {

    setIsSubmitting(true);
    const payload = {
      voucherId: data?.voucher?.id,
      scheduleId: data?.schedule?.id
    }

    try {

      const res = await del('/Treasury/Form', payload);

      setIsSubmitting(false);

      if (res && res.status === 'success') {
        if (res.code === 1) {
          notification({
            title: `Successful Request Deletion`,
            message: res.message,
            type: 'success'
          });
          getPendingRequests();
          clearVoucherAndScheduleFields();
          clearApprovers();
          setStep(0);
          setStatus("");
        } else {
          notification({
            title: `Request Creation Error`,
            message: res.message,
            type: 'error'
          });
          setStatus("");
        }
      } else {
        notification({
          title: 'Network Error',
          message: `Something went wrong while creating an Request. Please, try again later.`,
          type: 'error'
        });
      }
    } catch (error) {
      notification({
        title: 'Network Error',
        message: `Something went wrong while creating an Request. Please, try again later.`,
        type: 'error'
      });
      setIsSubmitting(false);
    }
    setIsSubmitting(false)

  }

  const closeModal = () => {
    setShowPictureModal(false);
  };



  const submitForm = async (unclear) => {
    setIsUpdating(true);
    setIsSubmitting(true);
    const payload = {
      voucher: paymentVoucher,
      schedule: schedule
    }

 

    try {
      let res;
      let tempData = {
        voucher: {
          ...paymentVoucher,
          amountRequested: stripCommas(paymentVoucher?.amountRequested),
          totalAmountApproved: stripCommas(paymentVoucher?.totalAmountApproved),
          typeId: voucherType?.value || paymentVoucher.typeId,
          subVouchers: paymentVoucher?.subVouchers.map((item) => (
            {
              ...item,
              amountRequested: stripCommas(item.amountRequested),
              totalAmountApproved: stripCommas(item.totalAmountApproved)
            }
          ))
        },
        schedule: {
          ...schedule,
          economicCode: Number(schedule?.economicCode)
        }
      }

     

      if (status === 'edit') {
        res = await put('/Treasury/Form', tempData);
      }
      else {
        res = await post('/Treasury/Form', tempData);
      }

      setIsSubmitting(false);

      if (res && res.status === 'success') {
        if (res.code === 1) {
          notification({
            title: `Successful Request Creation`,
            message: res.message,
            type: 'success'
          });
          getPendingRequests();
          if (unclear) {
            setStatus("edit");
            editSaved(res?.data);
            setIsUpdating(false);
          }
          if (!unclear) {
            clearVoucherAndScheduleFields();
            clearApprovers();
            setStep(0);
            setStatus("");
            setVoucherNum(0);
            localStorage.removeItem("requisitionNumber")
            localStorage.removeItem("approvedAmount")
            localStorage.removeItem("totalAmount")
            localStorage.removeItem("expenseType")
            localStorage.removeItem("expenseId")
            setVoucherType(3);
            getVoucherTypes();
            setIsUpdating(false); 
          }

        } else {
          notification({
            title: `Request Creation Error`,
            message: res.message,
            type: 'error'
          });
          setIsUpdating(false); 
          setStatus("");
        }
      } else {
        notification({
          title: 'Network Error',
          message: res?.message,
          type: 'error'
        });
        setIsUpdating(false);
      }
    } catch (error) {
      notification({
        title: 'Network Error',
        message: `Something went wrong while creating an Request. Please, try again later.`,
        type: 'error'
      });
      setIsUpdating(false);
      setIsSubmitting(false);
    }
    setIsSubmitting(false);
    setIsUpdating(false);
    // refreshPage();
  };

  const handleSetVoucherType = () => {
    setVoucherType(setVoucherTypeOption(localStorage.getItem("expenseType")))
  }


  useEffect(() => {
    handleSetVoucherType()
    if (queryParams.get('salary') === "true") {
      setVoucherType(setVoucherTypeOption("personnel"))
    }
  }, [])

  const getPendingRequests = async () => {
    setLoading(true)
    try {
      let res = await get(`/Treasury/Form/Saved`);
      if (res.status === 'success') {
        // setIsPending(false);
        setPendingRequests(res?.data);
        setFilteredRequests(res?.data);
        setLoading(false)
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  const getPendingCorruptedRequests = async () => {
    try {
      const res = await get(`/Treasury/Paginated/CorruptUpload?PageNumber=1&PageSize=50`);
      if (res.status === 'success') {
        // setPendingRequests(res?.data)
        setFilteredCorruptedRequests(res?.data?.items);
        // setTotal(res?.data?.length)
        // setFilteredRequests(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitCorrupt = async (item) => {
    // let payload = corruptUploads.map((pay)=>(
    //   {
    //     ...pay,
    //     id:item?.voucher?.id
    //   }
    // ))

    let payload = {
      ...corruptUploads,
      voucherId: item?.voucher?.id
    }

    let res = await post("/Voucher/CorruptUpload", payload)
    if (res.status === "success") {
      notification({
        title: 'success',
        message: res.message,
        type: 'success'
      });
    }

    setCorruptUploads({});
    // getPendingCorruptedRequests();
    getPendingRequests();


  }

  const querySearch = async () => {
    let res = await get(`/GetTreasuryFormFiltered?PageNumber=${pageNumber}&PageSize=${pageSize}&query=${searchParam}`);
    if (res?.status === "success") {
      setPaginationLength(res?.data?.totalItemCount)
      setPendingRequests(res?.data?.items)
      setFilteredRequests(res?.data?.items)
    }
  }


  const handleSearch = (e) => {
    setSearchParam(e.target.value);
    // querySearch();
  }



  useEffect(() => {
    getPendingRequests();
    getVoucherTypes();
    // getPendingCorruptedRequests();
    // handlePageClick();
  }, [])


  // useEffect(() => {
  //   handlePageClick();
  // }, [pendingRequests])



  const handleFilesUpload = async (file) => {
    setIsUploadingDocument(true);
    if (!file) return;
    const { type, size } = file;

    const supportedTypes = [
      'jpeg',
      'png',
      'gif',
      'pdf',
      'vnd.openxmlformats-officedocument.wordprocessingml.document',
      'doc',
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];
    const fileType = type.slice(type.indexOf('/') + 1);

    if (!supportedTypes.includes(fileType)) {
      notification({
        title: 'Invalid File Type',
        message:
          'Invalid file format. Supported file types are pdf, docx, doc, xlsx, jpeg, png and gif',
        type: 'error'
      });
      return;
    }
    if (size / 1024 > 500000) {
      notification({
        title: 'File Too Large',
        message: 'The file size must not be more than 500MB',
        type: 'error'
      });
      return;
    }

    setIsSubmitting(true);

    notification({
      title: 'Uploading Documents',
      message: 'Currently uploading the attached document...',
      type: 'info'
    });
    const body = new FormData();
    body.append('files', file);

    const response = await uploadDocument(body);
    setIsSubmitting(false);
    setIsUploadingDocument(false);
    if (response && response.status === 200) {
      if (response.data.code === 1) {
        setUrl(response.data.doclink);
        setDocumentData((prev) => ({
          ...prev,
          url: response.data.doclink,
          files: [response.data.doclink]
        }));
        notification({
          title: 'Successful Document Upload',
          message:
            'You have successfully uploaded the documents for this request',
          type: 'success'
        });
      } else {
        notification({
          title: 'Document Upload Failed',
          message: response.data.message,
          type: 'error'
        });
      }
    } else {
      notification({
        title: 'Network Error',
        message:
          'Something went wrong while uploading the documents for this request. Please, try again later.',
        type: 'error'
      });
    }
  };


  const tableHeaders = [
    'PV Initiator',
    'Initiator MDA',
    "Voucher Type",
    'Date of Creation',
    'Name of Vendor/Organization',
    'Amount',
    'PV Number',
    'Reference Number',
    'Payment Voucher',
    'Schedule Form',
    'Uploaded Document',
    'Payment Cheque',
    'Status',
    "View Approvals and Comments",
    "Action"
  ]





  const [docName, setDocName] = useState("")

  const recImagg = (value) => {
   


    // setCorruptUploads(
    //   [...corruptUploads, {
    //    name: value.docTitle,
    //    path: value.docPath,
    //    description: value.docTitle,
    //   }]
    // )

    setCorruptUploads(
      {
        name: value.docTitle,
        path: value.docPath,
        description: value.docTitle,
      }
    )

    setMFilesArray([...mFilesArray, value]);
    setDocName(value?.docTitle);

  };

  const handleDocAdd = (value) => {
    setDocName(value?.docTitle);

    // paymentVoucher?.approvalMemoUrl &&
    //   setPaymentVoucher({
    //     ...paymentVoucher,
    //     approvalMemoUrl: [...paymentVoucher.approvalMemoUrl, value.docPath]
    //   })

    setCorruptUploads(
      {
        name: value.docTitle,
        path: value.docPath,
        description: value.docTitle,
      }
    )
  };
  // const del = () => {
  //   setMFilesArray([]);
  //   setCorruptUploads({})
  // };



  const isLastStep = () => {
    return steps.length - 1 === step;
  };

  const isFirstStep = () => {
    return !step;
  };

  const validateVoucher = (voucher) => {
    const { station, amountRequested, pvNo, mdaId, totalAmountApproved, amountInWords, administrativeCode, departmentNo, economicCode, drTo, amount, userId, expenditureSignature, witness } = voucher;


    if (station === "" || station === null) {
      return 'Please Enter Station';
    }

    if (administrativeCode === "" || administrativeCode === null) {
      return 'Please Enter Administrative Code';
    }
    if (departmentNo === "" || departmentNo === null) {
      return 'Please Enter Department No';
    }
    // if (economicCode === "" || economicCode === null) {
    //   return 'Please Enter Economic Code';
    // }
    if (drTo === "" || drTo === null) {
      return 'Please Enter Dr To';
    }
    if (amountRequested === "" || amountRequested === null || !/^[0-9,.]+$/.test(amountRequested?.toString())) {
      return 'Please Enter Valid Amount';
    }
    // if (mdaId === "" || mdaId === 0 || mdaId === null) {
    //   return 'Please Select the MDA of the Attached Memo'
    // }
    // if (totalAmountApproved === 0 || totalAmountApproved === null || !/^[0-9,.]+$/.test(totalAmountApproved?.toString())) {
    //   return 'Please Enter the Total Amount Approved in the Memo'
    // }

    if (amountInWords === "" || totalAmountApproved === null) {
      return 'Please Enter the Amount Approved in Words.'
    }

    if (userId === "" || userId === null) {
      return 'Please Enter User';
    }
    // if (expenditureSignature === "" || expenditureSignature === null) {
    //   return 'Please Enter Expenditure Signature';
    // }
    return "";
  }
  const validateSchedule = () => {
    const { scheduleNO, serialNo, economicCode, nameOfPayee, treasuryCashOffice, signingOfficer, counterSigningOfficer } = schedule;
    if (scheduleNO === "" || scheduleNO === null) {
      return 'Please Enter Schedule Number';
    }

    if (economicCode === 0 || economicCode === null) {
      return 'Please Enter economicCode';
    }

    // if (signingOfficer === "" || signingOfficer === null) {
    //   return 'Please Enter signingOfficer';
    // }
    // if (counterSigningOfficer === "" || counterSigningOfficer === null) {
    //   return 'Please Enter Counter Signing Officer Signature';
    // }
    return "";
  }

  const numberWithCommas = (x) => {

    if (String(x).includes(',')) {
      return String(x)?.replace(/,/g, '')?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    else {
      return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    }

  }

  const removeForm = (item, voucherIndex) => {
    console.log(voucherIndex);
    console.log(item?.id);
    let tempVoucher = paymentVoucher?.subVouchers?.filter((voucher, index) => {
      console.log(index !== voucherIndex.idk)
      console.log(index)
      console.log(voucherIndex)
      return item?.id !== voucher?.id || index !== voucherIndex 
    })

    // const newItems = [...paymentVoucher.subVouchers]; // Create a copy of the array

    // console.log(newItems)

    // newItems.splice(voucherIndex, 1); // Remove the item at the specified index

    // console.log(newItems);

    setPaymentVoucher({
      ...paymentVoucher,
      subVouchers: tempVoucher
    });


    setVoucherNum(voucherNum - 1);
   
  }


  useEffect(() => {
    if (searchParam === "") {
      getPendingRequests();
    }
    else {
      querySearch();
    }
  }, [pageSize, pageNumber, searchParam])


  const nextStep = () => {
    let error = "";
    if (step === 0) {
      error = validateVoucher(paymentVoucher);
      if (paymentVoucher?.subVouchers?.length > 0) {
        paymentVoucher.subVouchers.every((subVoucher) => {
          error = validateVoucher(subVoucher);
          if (error !== "") {
            return false;
          }
          return true;
        })
      }
    }
    else if (step === 1) {
      error = validateSchedule();
    }
    if (error != "") {
      notification({
        title: `Validation Error`,
        message: error,
        type: 'error'
      });
    }

    if (error === "") {
      steps.length - 1 !== step && setStep((s) => s + 1);
      steps.length - 1 !== step && setUpperStep((s) => s + 1)
    }
  };

  const prevStep = () => {
    step && setStep((s) => s - 1);
    step && setUpperStep((s) => s - 1);
  };

  // const filterAndPaginateData = (searchText) => {
  //   const filteredData = searchByColumns(pendingRequests, searchText, columnsToSearch)

  //   // Calculate pagination parameters
  //   const startIndex = (current - 1) * pageSize;
  //   const paginatedData = filteredData.slice(startIndex, startIndex + pageSize);

  //   return { paginatedData, totalItems: filteredData.length };
  // }

  // const handleSearch = (e) => {

  //   // Update table data and total items
  //   const { paginatedData, totalItems } = filterAndPaginateData(e.target.value);
  //   setFilteredRequests(paginatedData);
  //   setTotal(totalItems);

  //   // Reset to the first page when performing a new search
  //   setCurrent(1);

  // }

  return (
    <div className="w-100" >
      <Stepper
        steps={steps}
        activeStep={step}
        activeBgColor='#'
        styleConfig={{
          completedBgColor: '#1C811C',
          activeBgColor: '#77FF77',
          activeTextColor: 'gray',
        }}
      />

      <div className='w-100 m-b-10'>
        <SelectInput
          onChange={(e) => handleSelect(e)}
          disabled={step > 0}
          value={voucherType}
          placeholder="Select Payment Voucher Type..."
          options={voucherTypes?.map((type) => (
            {
              name: type?.description,
              id: type?.id
            }
          ))

          } />
      </div>

      {
        step === 0 ?
          <div className={status === "edit" && "edit-border"}>
            <VoucherForm
              approvers={approvers}
              employeeName={employeeName}
              recipientMda={recipientMda}
              approverId={approverId}
              setApprovers={setApprovers}
              setemployeeName={setemployeeName}
              setApproverId={setApproverId}
              setrecipientMda={setrecipientMda}
              paymentVoucher={paymentVoucher}
              setPaymentVoucher={setPaymentVoucher}
              clearApprovers={clearApprovers}
              formLabel="Amount to be paid"
              selectValue={selectValue}
              step={step}
            />
            {
              paymentVoucher?.subVouchers?.map((item, index) => {
                return <div key={item?.id || index} className='w-100 mx-auto'>
                  <VoucherForm
                    approvers={approvers}
                    employeeName={employeeName}
                    recipientMda={recipientMda}
                    approverId={approverId}
                    setApprovers={setApprovers}
                    setemployeeName={setemployeeName}
                    setApproverId={setApproverId}
                    setrecipientMda={setrecipientMda}
                    paymentVoucher={paymentVoucher}
                    setPaymentVoucher={setPaymentVoucher}
                    clearApprovers={clearApprovers}
                    selectValue={selectValue}
                    formLabel="Amount to be paid"
                    step={index + 1}
                  />
                  <IoMdClose size={30} className="close-bt pointer" onClick={() => { removeForm(item, index) }} />
                </div>
              }
              )
            }
          </div>
          : step === 1
            ?
            <ScheduleForm
              schedule={schedule}
              setSchedule={setSchedule}
              paymentVoucher={paymentVoucher}
              scheduleEmployeeName={scheduleEmployeeName}
              setScheduleEmployeeName={setScheduleEmployeeName}

            />
            : step === 2
              ?
              <AddDocuments mFilesArray={mFilesArray} setMFilesArray={setMFilesArray} setPaymentVoucher={setPaymentVoucher} paymentVoucher={paymentVoucher} />

              : <FinalPage paymentVoucher={paymentVoucher} schedule={schedule} setPaymentVoucher={setPaymentVoucher} />

      }

      <div className='flex flex-v-center flex-h-center m-b-20  pd-10'>
        {step === 0 && <div className='flex flex-h-center  m-r-40'><button disabled={isUpdating} className='btn' onClick={addVoucher}>Add Voucher</button></div>}
        {step ? (
      
          <button  className='btn m-r-10 w-25' onClick={prevStep} disabled={isFirstStep() || isUpdating}>
            Prev
          </button>
        ) : null}

        {step < 3 &&
          <button
          disabled={isUpdating}

            className='btn w-25 m-r-40'
            onClick={() => { submitForm("Unclear"); }}
          // disabled={paymentVoucher.amount > paymentVoucher.amountApproved || paymentVoucher.amountRequested > paymentVoucher.totalAmountApproved }
          >
            {(isUpdating === true ? "Updating..." : (status === "edit") ? 'Update' : 'Save')}
          </button>
        }

        {
          <button
          disabled={isUpdating}
            className='btn w-25'
            // disabled={paymentVoucher.amount > paymentVoucher.amountApproved || paymentVoucher.amountRequested > paymentVoucher.totalAmountApproved }
            onClick={() => {
              step < 3 ? nextStep() : submitForm();
            }}>

            {isLastStep() ? (status === "edit" ? 'Update' : 'Save') : 'Next'}
          </button>
        }
      </div>
      <div className='flex w-100 flex-h-end m-b-20'>
        <SearchInput
          onChange={(e) => { handleSearch(e) }}
          className="w-30"
        />
      </div>
      <div className="overflow-table">
        <table className="table-view" >
          <thead className="">
            <tr className="tr-bordered">
              {tableHeaders.map((th, index) => (
                <th key={index} className="th th-bordered">
                  <p>{th}</p>
                </th>
              ))}
            </tr>
          </thead>
          {loading ? <Spin /> : <tbody className="table-body">
            {filteredRequests?.map((item) => (
              <tr role="presentation">
                <td className="td p-r td-bordered">
                  {item?.voucher?.createdByName}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.mda}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.voucherType}
                </td>
                <td className="td p-r td-bordered">
                  {new Date(item?.voucher?.dateCreated).toUTCString()}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.drTo}
                </td>
                <td className="td p-r td-bordered">
                  {`₦${numberWithCommas(Number(parseFloat(item?.voucher?.amount) + item?.voucher?.subVouchers?.reduce(
                    (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                  )).toFixed(2))}`}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.pvNo}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.referenceNumber}
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("voucher", item) }}>View Payment Voucher</a>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("schedule", item) }}>View Schedule Form</a>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("memo", item) }}>View Approved Memo</a>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("paymentCheque", item) }}>View Payment Cheque</a>
                </td>

                <td className="td p-r td-bordered" width={"17%"}>
                  <div className='flex space-between'>
                  <GrStatusGoodSmall size={15} className={`m-r-10 float-left ${item?.voucher?.statusDetails === 'Pending' ? 'bg-amber' : item?.voucher?.statusDetails === 'ApprovalCompleted' ? 'bg-green' : item?.voucher?.statusDetails === 'Rejected' ? 'bg-red' : 'bg-gray'}`} />
                    <div>
                      {
                        item?.voucher?.statusDetails === 'Pending' ? `Pending With ${getDeptName(item?.voucher?.journeyStateId)}`
                          :
                          item?.voucher?.statusDetails === 'ApprovalCompleted' ? 'Paid'
                            :
                            item?.voucher?.statusDetails === 'Rejected' ? 'Declined'
                              :
                              item?.voucher?.statusDetails
                      }
                    </div>
                  </div>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("comment", item) }}>View Approvals and Comments</a>
                </td>
                <td>
                  {
                    item?.voucher?.statusDetails === 'Pending' ? `Pending With ${getDeptName(item?.voucher?.journeyStateId)}`
                      :
                      item?.voucher?.statusDetails === 'ApprovalCompleted' ? 'Paid'
                        :
                        item?.voucher?.statusDetails === 'Rejected' ? 'Declined'
                          :
                          <div className='flex p-5'>
                            <Tooltip title="Submit"><AiOutlineSend style={{ marginRight: "16px" }} size={20} color="green" className="pointer" onClick={() => { submitFromSaved(item) }} /></Tooltip>
                            <Tooltip title="Edit"><MdEdit style={{ marginRight: "16px" }} size={20} color="gray" className="pointer" onClick={() => { editSaved(item) }} /></Tooltip>
                            <Tooltip title="Delete"><AiOutlineDelete style={{ marginRight: "16px" }} size={20} color="red" className="pointer" onClick={() => deleteAlert(deleteSaved, item, "Are You Sure You Want To Delete This Voucher?")} /></Tooltip>
                          </div>
                  }

                </td>

              </tr>

            ))}
          </tbody>}
        </table>
      </div>

      <div style={{ marginTop: '20px' }}>
        <Pagination
          showSizeChanger
          pageSize={pageSize}
          onShowSizeChange={paginationChangeHandler}
          current={pageNumber}
          total={paginationLength}
          // showTotal={true}
          onChange={paginationChangeHandler}
          // pageSizeOptions={pageSizeOptions}
          responsive
        />
      </div>







      <div className="overflow-table m-t-40">
        {/* <h2>
          UPLOADS
        </h2> */}
        {/* <table className="table-view" >
          <thead className="">
            <tr className="tr-bordered">
              {tableHeaders.map((th, index) => (
                <th key={index} className="th th-bordered">
                  <p>{th}</p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-body">
            {filteredCorruptedRequests?.map((item) => (
              <tr role="presentation">
                <td className="td p-r td-bordered">
                  {item?.voucher?.createdByName}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.mda}
                </td>
                <td className="td p-r td-bordered">
                  {new Date(item?.voucher?.dateCreated).toUTCString()}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.drTo}
                </td>
                <td className="td p-r td-bordered">
                  {`₦${numberWithCommas(Number(parseFloat(item?.voucher?.amount) + item?.voucher?.subVouchers?.reduce(
                    (accumulator, currentValue) => accumulator + currentValue?.amount, 0,
                  )).toFixed(2))}`}
                </td>
                <td className="td p-r td-bordered">
                  {item?.voucher?.pvNo}
                </td>


                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("voucher", item) }}>View Payment Voucher</a>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("schedule", item) }}>View Schedule Form</a>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("memo", item) }}>View Approved Memo</a>
                </td>
                <td className="td p-r td-bordered">
                  <a onClick={() => { showDocumentModal("paymentCheque", item) }}>View Payment Cheque</a>
                </td>

                <td className="td p-r td-bordered" width={"17%"}>
                  <div className='flex'>
                    <div className="flex flex-direction-column flex-h-center" >
                      <div className='' style={{ margin: '10px', minWidth: '100%' }} >
                        <UploadButton width='100%' sendImagg={recImagg} />
                      </div>
                      <div style={{ width: '100%', margin: '10px' }}>
                        <SearchDocument handleDocAdd={handleDocAdd} />
                      </div>
                      <div className='m-t-20 w-100'>
                        <button onClick={() => submitCorrupt(item)} className='btn w-100'>
                          Upload
                        </button>

                      </div>
                      {

                        (corruptUploads) &&
                        (
                          <div
                            style={{
                              padding: '2px',
                              marginBottom: '1px',
                              marginTop: '2px',
                              width: '100%',
                            }}
                            className='flex-between mFiles-hover flex-align'>
                            <div className='flex-align'>
                              <AiOutlinePaperClip />
                              <a target="_blank" href={corruptUploads?.path} style={{ marginLeft: 'px', marginBottom: '0px' }}>
                                {corruptUploads?.name}
                              </a>
                              <div
                                className='pointer'
                                style={{ display: 'inline', marginLeft: '4px', marginBottom: '0px' }}
                                onClick={() => {
                                  del();
                                }}>
                                <MdDelete style={{ fontSize: '16px' }} />
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </td>

              </tr>

            ))}
          </tbody>
        </table> */}
      </div>

      {
        showPictureModal && <DocumentModal closeModal={closeModal} paymentVoucher={modalData.voucher} schedule={modalData.schedule} type={documentType} />
      }

    </div>
  );
};

export default TreasuryRequest;