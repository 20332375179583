import React from 'react';
import SearchInput from '../inputs/SearchInput';
import SelectInput from '../inputs/SelectInput';
import { Radio, Input } from 'antd';

const { Search } = Input;

const FilterBox = ({
  title,
  onChange,
  className,
  value,
  options,
  type,
  placeholder,
  onSearch,
  disabled
}) => {
  const renderSearchInput = () => {
    return (
      <Search
        className="w-100 "
        style={{ background: 'white !important' }}
        value={value}
        placeholder={placeholder}
        onSearch={onSearch}
        disabled={disabled}
      />
    );
  };
  const renderSelectInput = () => {
    return (
      <SelectInput
        className="w-100 "
        options={options}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    );
  };
  const renderRadio = () => {
    return (
      <Radio.Group className="w-100 " onChange={onChange} value={value}>
        {Array.isArray(options) &&
          options.map((option) =>
            typeof option !== 'object' ? (
              <Radio value={option}>{option}</Radio>
            ) : (
              <Radio value={option.value}>{option.title}</Radio>
            )
          )}
      </Radio.Group>
    );
  };

  const renderFilterInput = () => {
    const inputRender = {
      search: renderSearchInput(),
      select: renderSelectInput(),
      radio: renderRadio()
    };
    return inputRender[type?.toLowerCase()];
  };
  return (
    <div
      style={{ background: 'white' }}
      className={`w-100 item-shadow p-10 flex flex-col ${className}`}
    >
      <span
        className="m-b-20"
        style={{ fontSize: '14px', fontWeight: 'bolder' }}
      >
        {title}
      </span>
      <div className="w-100 ">{renderFilterInput()}</div>
    </div>
  );
};

export default FilterBox;
