import { useState, useEffect } from 'react';
import { get } from '../utility/fetch';

let STAFF_MEMO = [];

const useStaff = () => {
  const [staff, setStaff] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (STAFF_MEMO.length) {
      setStaff(STAFF_MEMO);
      setLoading(false);
    } else {
      get('/Staff')
        .then((data) => {
          STAFF_MEMO = data;
          setStaff(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  return { staff, loading };
};

export default useStaff;
